<template>
  <div id="task" style="width:400px;height:300px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'taskSubmissionCharts',
  data () {
    return {
      /*等级*/
      taskNum: [],
      countData: [],
      level11: 0,
      level12: 0,
      level13: 0,
      level14: 0,
      level15: 0,
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      termId: 0,
      taskScoreData: []
    }
  },
  mounted () {
    this.termId = this.$route.query.termId
    this.loadTaskAverageScore()
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变keys的方法*/
    /*加载任务平均成绩*/
    loadTaskAverageScore () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top//taskSubmission/showAverageByTermIdGroupByStudentId',
        params: {
          termId: this.termId,
          setStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.taskScoreData = countData
            for (let i = 0; i < countData.length; i++) {
              this.taskNum.push(countData[i].average)
            }
            const cout = this.taskNum.sort()
            for (let i = 0; i < cout.length; i++) {
              if (cout[i] >= 0 && cout[i] < 2) {
                this.level1 += 1
              } else if (cout[i] >= 1 && cout[i] < 2) {
                this.level2 += 1
              } else if (cout[i] >= 2 && cout[i] < 3) {
                this.level3 += 1
              } else if (cout[i] >= 3 && cout[i] < 4) {
                this.level4 += 1
              } else if (cout[i] >= 4 && cout[i] < 5) {
                this.level5 += 1
              } else if (cout[i] >= 5 && cout[i] < 6) {
                this.level6 += 1
              } else if (cout[i] >= 6 && cout[i] < 7) {
                this.level7 += 1
              } else if (cout[i] >= 7 && cout[i] < 8) {
                this.level8 += 1
              } else if (cout[i] >= 8 && cout[i] < 9) {
                this.level9 += 1
              } else if (cout[i] >= 9 && cout[i] <= 10) {
                this.level10 += 1
              }
            }
            this.countData = [this.level1, this.level2, this.level3, this.level4, this.level5, this.level6, this.level7, this.level8, this.level9, this.level10]
            this.countData1 = [this.level1 + this.level2, this.level3 + this.level4, this.level5 + this.level6, this.level7 + this.level8, this.level9 + this.level10]
            //console.log(this.countData)
            this.loadTaskCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadTaskCharts () {
      const task = this.$echarts.init(document.getElementById('task'))
      let option = {
        title: {
          text: '学生任务表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: '{a} <br/>{b} : {c}'
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          },
          {
            type: 'category',
            position: 'bottom',
            show: false,
            data: [2, 4, 6, 8, 10]
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: this.countData,
            color: '#7FFFAA',
            xAxisIndex: 0//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          },
          {
            color: '#EE0000',
            name: '',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            xAxisIndex: 1, //使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
            data: this.countData1,
          }
          //鼠标悬停时
        ]
      }
      task.on('click', params => {
        this.$router.push({
          path: '/taskmanage',
          name: 'TaskManage',
          query: {
            termId: this.termId
          }
        })
      })
      task.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        task.resize()
      })
    },
  }
}
</script>

<style scoped>

</style>
