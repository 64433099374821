import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login'
import Register from '@/views/Register'
import RegisterStepOne from '@/components/RegisterSteps/RegisterStepOne'
import RegisterStepTwo from '@/components/RegisterSteps/RegisterStepTwo'
import RegisterStepThree from '@/components/RegisterSteps/RegisterStepThree'
import RegisterStepFour from '@/components/RegisterSteps/RegisterStepFour'
import CourseMessage from '@/views/course/CourseMessage'
import ActivityRanking from '../views/ActivityRanking'
import TeacherClass from '../views/term/TeacherClass'
import TeacherManual from '../views/teacher/TeacherManual'
import TeacherCourseCenter from '../views/course/TeacherCourseCenter'
import TeacherCourse from '../views/course/TeacherCourse'
import BasicInfo from '../views/course/BasicInfo'
import StudentManage from '../views/term/student/StudentManage'
import SituationAnalysis from '../views/term/student/SituationAnalysis'
import AddReSource from '../views/term/AddReSource.vue'
import TeacherInfo from '../views/teacher/TeacherInfo.vue'
import CreateQuestion from '../views/CreateQuestion.vue'
import CourseStudy from '@/components/CourseStudy'
import CourseList from '../views/course/CourseList.vue'
import AddCourse from '../views/course/AddCourse.vue'
import CommentManage from '../views/term/comment/CommentManage.vue'
import TaskManage from '../views/term/task/TaskManage.vue'
import TermManage from '../views/term/TermManage.vue'
import StudentSectionManage from '../views/term/student/StudentSectionManage.vue'
import ShowStudentChapter from '../views/term/student/ShowStudentChapter'
import ChapterData from '../views/term/ChapterData.vue'
import RecordManage from '../views/term/student/RecordManage.vue'
import ExamManage from '../views/term/exam/ExamManage.vue'
import ShowExamGroupStudent from '../views/term/exam/ShowExamGroupStudent.vue'
import StudentScore from '../views/term/student/StudentScore.vue'
import TaskByStudent from '../views/term/task/TaskByStudent.vue'
import TaskAverage from '../views/term/task/TaskAverage.vue'
import TaskSubmissionDetail from '../views/term/task/TaskSubmissionDetail.vue'
import StudentAbility from '../views/term/student/StudentAbility.vue'
import TaskMutualDetail from '../views/term/task/TaskMutualDetail.vue'
import ShowPaper from '../views/term/paper/ShowPaper.vue'
import ExamPaper from '../views/term/paper/ExamPaper.vue'
import CertificateRule from '../views/term/exam/CertificateRule.vue'
import CertificateScore from '../views/term/certificate/CertificateScore.vue'
import CertificateCard from '../views/term/certificate/CertificateCard.vue'
import TaskScore from '../views/term/task/TaskScore.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/chapterData',
    name: 'ChapterData',
    component: ChapterData
  },
  {
    path: '/taskScore',
    name: 'TaskScore',
    component: TaskScore
  },
  {
    path: '/showPaper',
    name: 'ShowPaper',
    component: ShowPaper
  },
  {
    path: '/certificateScore',
    name: 'CertificateScore',
    component: CertificateScore
  },
  {
    path: '/certificateCard',
    name: 'CertificateCard',
    component: CertificateCard
  },
  {
    path: '/certificateRule',
    name: 'CertificateRule',
    component: CertificateRule
  },
  {
    path: '/examPaper',
    name: 'ExamPaper',
    component: ExamPaper
  },
  {
    path: '/taskSubmissionDetail',
    name: 'TaskSubmissionDetail',
    component: TaskSubmissionDetail
  },
  {
    path: '/taskMutualDetail',
    name: 'TaskMutualDetail',
    component: TaskMutualDetail
  },
  {
    path: '/studentAbility',
    name: 'StudentAbility',
    component: StudentAbility
  },
  {
    path: '/taskStudent',
    name: 'TaskByStudent',
    component: TaskByStudent
  },
  {
    path: '/taskAverage',
    name: 'TaskAverage',
    component: TaskAverage
  },
  {
    path: '/studentScore',
    name: 'StudentScore',
    component: StudentScore
  },
  {
    path: '/recordManage',
    name: 'RecordManage',
    component: RecordManage
  },
  {
    path: '/showExamStudent',
    name: 'ShowExamGroupStudent',
    component: ShowExamGroupStudent
  },
  {
    path: '/examManage',
    name: 'ExamManage',
    component: ExamManage
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sectionmanage',
    name: 'StudentSectionManage',
    component: StudentSectionManage
  },
  {
    path: '/showStudentChapter',
    name: 'ShowStudentChapter',
    component: ShowStudentChapter
  },
  {
    path: '/termmanage',
    name: 'TermManage',
    component: TermManage
  },
  {
    path: '/coursemessage',
    name: 'CourseMessage',
    component: CourseMessage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/activityranking',
    name: 'ActivityRanking',
    component: ActivityRanking
  },
  {
    path: '/commentmanage',
    name: 'CommentManage',
    component: CommentManage
  },
  {
    path: '/taskmanage',
    name: 'TaskManage',
    component: TaskManage
  },
  {
    path: '/teacherclass',
    name: 'TeacherClass',
    component: TeacherClass
  },
  {
    path: '/createquestion',
    name: 'CreateQuestion',
    component: CreateQuestion
  },
  {
    path: '/coursestudy',
    name: 'CourseStudy',
    component: CourseStudy
  },
  {
    path: '/addcourse',
    name: 'AddCourse',
    component: AddCourse
  },
  {
    path: '/courselist',
    name: 'CourseList',
    component: CourseList
  },
  {
    path: '/teachercourse',
    name: 'TeacherCourse',
    component: TeacherCourse
  },
  {
    path: '/studentmanage',
    name: 'StudentManage',
    component: StudentManage
  },
  {
    path: '/teachermanual',
    name: 'TeacherManual',
    component: TeacherManual
  },
  {
    path: '/basicinfo',
    name: 'BasicInfo',
    component: BasicInfo
  },
  {
    path: '/teachercoursecenter',
    name: 'TeacherCourseCenter',
    component: TeacherCourseCenter
  },
  {
    path: '/addresource',
    name: 'AddReSource',
    component: AddReSource
  },
  {
    path: '/situationanalysis',
    name: 'SituationAnalysis',
    component: SituationAnalysis
  },
  {
    path: '/teacherinfo',
    name: 'TeacherInfo',
    component: TeacherInfo
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    children: [
      {
        path: '/step1',
        component: RegisterStepOne
      },
      {
        path: '/step2',
        component: RegisterStepTwo
      },
      {
        path: '/step3',
        component: RegisterStepThree
      },
      {
        path: '/step4',
        component: RegisterStepFour
      },
      {
        path: '',
        redirect: '/step1'
      }
    ]
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  NProgress.start()  //开始Progress
  next()
})

router.afterEach(() => {
  NProgress.done()  //结束Progress
})
export default router
