<template>
  <div @click="goCourse(course.id)">
    <el-card shadow="hover" style="width: 95%;height: 300px" >
      <img v-if="course.courseInfo!==null"
           :src="course.courseInfo.imageUrl"
           alt="">
      <img v-else src="../assets/img/356.jpg"
           alt="">
      <template v-if="course.status==='private'">
        <el-tag size="mini" type="warning">私有课程</el-tag>
      </template>
      <template v-if="course.status==='public'">
        <el-tag size="mini" type="success">公开课程</el-tag>
      </template>
      <span style="font-weight: bold;margin-left: 10px">{{ course.name }}</span>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'CourseCenter',
  props: {   //将父组件的值传到子组件中
    course: Object//object表示对象类型
  },
  data () {
    return {}
  },
  methods: {
    goCourse (courseId) {
      this.$router.push({ path: '/coursemessage?courseId=' + courseId })
    },
  }
}
</script>

<style scoped>

</style>
