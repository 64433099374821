<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-card shadow="always" style="border-radius: 30px;margin-top: 30px">
        <el-row style="margin-left: 30px;margin-top: 30px">
          <i class="el-icon-user-solid" style="font-weight: bold;font-size: 30px">{{ this.student.name }}</i>
        </el-row>
        <el-col :span="8" :offset="4">
          <div id="score" style="width:360px;height:280px;"></div>
        </el-col>
        <el-col :span="8">
          <div id="grade" style="width:360px;height:280px;"></div>
        </el-col>
      </el-card>
      <el-card shadow="always" style="border-radius: 30px;width: 100%;margin-top: 10px">
        <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Examination Information</span>
        <el-table
          :data="scoreData"
          style="width: 100%;margin-left: 200px">
          <el-table-column
            prop="id"
            label="考试编号"
            width="120">
          </el-table-column>
          <el-table-column
            prop="name"
            label="考试名称"
            width="120">
          </el-table-column>
          <el-table-column
            prop="score.result"
            label="分数"
            width="100">
          </el-table-column>
          <el-table-column property="score.result" label="考试状态" width="100">
            <template slot-scope="scope">
              <template v-if="scope.row.result>=60">
                <el-tag type="success">Success</el-tag>
              </template>
              <template v-if="scope.row.result<60">
                <el-tag type="warning">Fail</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="start"
            label="开始时间"
            width="160">
          </el-table-column>
          <el-table-column
            prop="finalGetTime"
            label="交卷时间"
            width="160">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="small" round @click="viewStudentScore(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'showStudentChapter',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.studentId = this.$route.query.studentId
    this.loadStudentData()
    this.loadStudentExam()  /*加载学生完成课程的数据*/
  },
  data () {
    return {
      /*分页*/
      /*等级*/
      S: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      scoreData: [],
      scoreNum: [],
      student: {},
      termId: 0,
      studentId: 0
    }
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCourseData()
    },
    /*查看学生的考试成绩*/
    viewStudentScore (id) {
      this.$router.push({
        path: '/showExamStudent',
        name: 'ShowExamGroupStudent',
        query: {
          termId: this.termId,
          examId: id
        }
      })
    },
    /*加载学生课程信息*/
    loadStudentExam () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showExamsWithScoresByTermIdAndStudentId',
        params: {
          termId: this.termId,
          studentId: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.scoreData = countData
            for (let i = 0; i < countData.length; i++) {
              this.scoreNum.push(countData[i].score.result)
              if (countData[i].score.result <= 59 && countData[i].score.result >= 0) {
                this.D += 1
              } else if (countData[i].score.result >= 60 && countData[i].score.result <= 70) {
                this.C += 1
              } else if (countData[i].score.result < 85 && countData[i].score.result >= 71) {
                this.B += 1
              } else if (countData[i].score.result <= 99 && countData[i].score.result >= 85) {
                this.A += 1
              } else if (countData[i].score.result === 100) {
                this.S += 1
              }
            }
            this.loadScore()
            this.loadGrade()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载考试信息*/
    loadScore () {
      const score = this.$echarts.init(document.getElementById('score'))
      let option = {
        title: {
          text: '学生考试成绩散点图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        xAxis: {},
        yAxis: {},
        series: [
          {
            symbolSize: 20,
            data: this.scoreNum,
            type: 'scatter',
            markLine: {
              data: [{
                type: 'average',
                name: 'Avg'
              }]
            }
          }
        ]
      }
      score.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        score.resize()
      })
    },
    loadGrade () {
      // 基于准备好的dom，初始化echarts实例
      const grade = this.$echarts.init(document.getElementById('grade'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生考试完成图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}门'
        },
        legend: {
          bottom: 5,
          left: 'center',
          data: ['不及格', '及格', '良好', '优秀', '满分']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.D,
                name: '不及格'
              },
              {
                value: this.C,
                name: '及格'
              },
              {
                value: this.B,
                name: '良好'
              },
              {
                value: this.A,
                name: '优秀'
              },
              {
                value: this.S,
                name: '满分'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      grade.setOption(option)
      window.addEventListener('resize', function () {
        grade.resize()
      })
    },
    loadStudentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentById',
        params: {
          id: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.student = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

</style>


