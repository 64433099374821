<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-container>
        <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
          <el-table
            :data="sectionData">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column prop="studentId" label="学生编号" width="180">
            </el-table-column>
            <el-table-column prop="student.name" label="学生姓名" width="180">
            </el-table-column>
            <el-table-column label="状态" width="180" property="status">
              <template slot-scope="scope">
                <template v-if="scope.row.status==='joined'">
                  <el-tag type="waring">已加入</el-tag>
                </template>
                <template v-if="scope.row.status==='pass'">
                  <el-tag type="success">已通过</el-tag>
                </template>
                <template v-if="scope.row.status==='fail'">
                  <el-tag type="danger">挂科</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button @click="showSelection(scope.$index, scope.row)" size="small" round>查看</el-button>
                <el-button @click="viewStudentReliability(scope.$index, scope.row)" size="small">学生信度</el-button>
                <el-button @click="deleteSelection(scope.$index, scope.row)" size="small" type="danger" round>退选
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top:20px">
            <el-col :span="12" :offset="6">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadStudentSelectData"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </el-col>
          </el-row>
        </el-card>
        <!--    确认是否删除任务  -->
        <template v-if="isAddOrUp===3">
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%">
            <span style="color: rgba(255,48,48,0.5);font-weight: bold;font-size: 28px">确实要退课吗?</span>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deselectCourse">确 定</el-button>
          </span>
          </el-dialog>
        </template>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'StudentSectionManage',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.loadStudentSelectData()
  },
  data () {
    return {
      isAddOrUp: 0,  //0表示添加，1表示更新  2表示编辑评分   3 表示是否确认删除任务
      dialogVisible: false,
      /*分页*/
      pageSizes: [10, 20, 30, 40, 50,60,70,80],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      termId: 0,
      sectionData: []
    }
  },
  methods: {
    //跳转学生的信度页面
    viewStudentReliability (index, rows) {
      this.$router.push({
        path: '/studentAbility',
        name: 'StudentAbility',
        query: {
          termId: this.termId,
          studentId: rows.studentId
        }
      })
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCourseData()
    },
    /*加载学生选课信息*/
    loadStudentSelectData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/selection/showSelectionsByTermId',
        params: {
          termId: this.termId,
          currentPage: this.currentPage,
          pageSize: 10,
          setStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.sectionData = countData
            this.total=res.data.data.totalCount
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //查看学生的课程数据
    showSelection (index, rowObject) {
      this.$router.push({
        path: '/recordManage',
        name: 'RecordManage',
        query: {
          termId: this.termId,
          studentId: rowObject.studentId
        }
      })
    },
    /*删除学生信息*/
    deleteSelection (index, rowObject) {
      this.dialogVisible = true
      this.isAddOrUp = 3
    },
    deselectCourse () {

    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.main-table {
  width: 80%;
}
</style>
