<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-card shadow="always" style="width: 80%;margin: 20px auto;">
        <div style="margin-left: 45px">
          <el-row style="margin: 10px 0">
            <span>课程：</span>
            <el-tag>数据结构</el-tag>
            <el-tag type="success">编译原理</el-tag>
            <el-tag type="info">大数据</el-tag>
            <el-tag type="warning">高等数学</el-tag>
            <el-tag type="danger">英语</el-tag>
          </el-row>
          <el-row>
            <span>课堂：</span>
            <el-tag>互联网大数据</el-tag>
            <el-tag type="success">计算机大数据</el-tag>
            <el-tag type="info">物联网大数据</el-tag>
            <el-tag type="warning">信科大数据</el-tag>
            <el-tag type="danger">智能大数据</el-tag>
          </el-row>
        </div>
      </el-card>
      <el-card shadow="always" style="width: 80%;margin: 20px auto">
        <el-row>
          <span style="font-size: 28px;">学生名单</span>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="3">
            <el-input placeholder="学生学号" prefix-icon="el-icon-search" v-model="tableData.sid">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button @click="queryStudentBySid" type="primary" style="margin-left: 10px">
              查询
            </el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-select v-model="options3.value3" placeholder="班级" clearable @click="queryStudentByClassName">
              <el-option v-for="item in options3" :key="item.value3" :label="item.label" :value="item.value3">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button type="primary" @click="addStu" round>按学生添加</el-button>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button type="primary" @click="addCls" round>按班级添加</el-button>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button type="primary" @click="addAll" round>直接导入</el-button>
          </el-col>
        </el-row>
        <div style="margin-top: 20px">
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="120">
            </el-table-column>
            <el-table-column prop="name" label="学生姓名" width="120">
            </el-table-column>
            <el-table-column prop="gender" label="性别" width="80">
            </el-table-column>
            <el-table-column prop="school" label="学校" width="180">
            </el-table-column>
            <el-table-column prop="className" label="专业班级" width="120">
            </el-table-column>
            <el-table-column prop="sid" label="学号" width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           @click.native.prevent="deleteRow(scope.$index, tableData)"
                           size="small">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--   分页     -->
          <el-row style="margin-top:50px">
            <el-col :span="12" :offset="6">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadStudentData"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
              </el-pagination>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!--编辑按学生添加信息的对话框界面-->
      <template v-if="stuOrCls==1">
        <el-dialog title="按学号添加学生" :visible.sync="dialogVisible" width="79%" style="font-weight: bold">
          <el-row>
            <el-col :span="6">
              <el-input placeholder="学生学号" prefix-icon="el-icon-search" v-model="searchContent">
              </el-input>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>学院：</span>
              <el-select v-model="options1.value1" placeholder="学院" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>年级：</span>
              <el-select v-model="options2.value2" placeholder="年级" clearable>
                <el-option v-for="item in options2" :key="item.value2" :label="item.label" :value="item.value2">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>专业：</span>
              <el-select v-model="options3.value3" placeholder="专业" clearable>
                <el-option v-for="item in options3" :key="item.value3" :label="item.label" :value="item.value3">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin: 30px">
            <el-col :span="4"></el-col>
            <el-col :span="16">
              <el-table border :data="tableData" style="width: 100%;">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column prop="sid" label="学号" width="180">
                </el-table-column>
                <el-table-column prop="name" label="学生姓名" width="120">
                </el-table-column>
                <el-table-column prop="gender" label="性别" width="80">
                </el-table-column>
                <el-table-column prop="school" label="学校" width="180">
                </el-table-column>
                <el-table-column prop="className" label="专业班级" width="120">
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400" style="margin: 20px 150px">
              </el-pagination>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--按班级添加信息的对话框界面-->
      <template v-if="stuOrCls==2">
        <el-dialog title="按班级添加学生" :visible.sync="dialogVisible" width="81%" style="font-weight: bold">
          <el-row>
            <el-col :span="6">
              <el-input placeholder="班级" prefix-icon="el-icon-search" v-model="searchContent">
              </el-input>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>学院：</span>
              <el-select v-model="options1.value1" placeholder="学院" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>年级：</span>
              <el-select v-model="options2.value2" placeholder="年级" clearable>
                <el-option v-for="item in options2" :key="item.value2" :label="item.label" :value="item.value2">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1" style="display: flex">
              <span>专业：</span>
              <el-select v-model="options3.value3" placeholder="专业" clearable>
                <el-option v-for="item in options3" :key="item.value3" :label="item.label" :value="item.value3">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row style="margin: 30px 100px">
            <el-col :span="4"></el-col>
            <el-col :span="16">
              <el-table border :data="tableData" style="width: 100%">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column prop="class" label="班级名称" width="120">
                </el-table-column>
                <el-table-column prop="count" label="班级人数" width="120">
                </el-table-column>
                <el-table-column prop="institute" label="学院" width="180">
                </el-table-column>
                <el-table-column prop="major" label="专业" width="120">
                </el-table-column>
                <el-table-column prop="grade" label="年级" width="80">
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400" style="margin: 20px 150px">
              </el-pagination>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--编辑按班级添加信息的对话框界面-->
      <template v-if="stuOrCls==3">
        <el-dialog title="编辑学生信息" :visible="dialogVisible" width="50%" :destroy-on-close="true" :show-close="false"
                   style="font-weight: bold">
          <el-form :model="tableData">
            <el-row>
              <el-col :span="10">
                <el-form-item label="ID" :label-width="formLabelWidth">
                  <el-input v-model="tableData.id" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                  <el-input v-model="tableData.name" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="性别" :label-width="formLabelWidth">
                  <el-input v-model="tableData.gender" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="学校" :label-width="formLabelWidth">
                  <el-input v-model="tableData.school" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="专业班级" :label-width="formLabelWidth">
                  <el-input v-model="tableData.className" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="学号" :label-width="formLabelWidth">
                  <el-input v-model="tableData.sid" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateStudents()">确 定</el-button>
          </div>
        </el-dialog>
      </template>
      <!--表示批量添加学生（Excel添加）-->
      <template v-if="stuOrCls==4">
        <el-dialog title="直接导入" :visible="dialogVisible" width="50%" :destroy-on-close="true" :show-close="false"
                   style="font-weight: bold">
          <el-form>
            <el-row>
              <el-col>
                <span>请下载<a
                  href="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/%E6%88%91%E7%9A%84%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5.xls">导入模板</a>
                ,在模板中录入数据后再导入(模板中带*号的字段为必填项)
                </span>
              </el-col>
            </el-row>
            <el-row style="margin: 20px 30px">
              <el-col :span="5">
                <span style="font-size: 20px">请选择文件:</span>
              </el-col>
              <el-col :span="15" style="height: 50px;width: 300px">
                <el-input placeholder="请选择文件" style="border-radius: 20px"/>
              </el-col>
              <el-col :span="4">
                <el-upload
                  class="upload"
                  action=""
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :on-exceed="handleExceed"
                  :limit="limitUpload"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  :auto-upload="false">
                  <el-button type="primary" icon="el-icon-upload" round style="margin-left: 20px" @click="changeStatus">
                    浏览
                  </el-button>
                  <!-- <div slot="tip" class="el-upload__tip">只 能 上 传 xlsx / xls 文 件</div> -->
                </el-upload>
              </el-col>
            </el-row>
            <el-row>
              <template v-if="onExcel==1">
                <el-table border :data="excelData" style="width: 100%">
                  <!--  数据展示              -->
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                </el-table>
              </template>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" round>取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false" round>上传</el-button>
          </div>
        </el-dialog>
      </template>
    </el-main>
  </el-container>

</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
//getExcel接口
export const uploadExcel = (data) => {
  return axios.request({
    //接口url
    url: 'xxxx/xxxxx/uploadexcel',
    method: 'post',
    data
  })
}
export default {
  name: 'StudentManage',
  components: {
    Header
  },
  data () {
    return {
      pageSizes: [6, 12, 18, 24],
      currentPage: 1,
      pageSize: 6,
      total: 1,
      excelData: [{}],
      addOrUpdate: 1, // 1表示添加，2表示更新
      stuOrCls: 1, // 1表示按学生，2表示班级添加 3 .表示编辑学生信息 4 .表示批量添加学生（Excel添加）
      formLabelWidth: '120px', // 对话框lable的宽度
      dialogVisible: false,
      searchContent: '',
      currentPage4: 10,
      limitUpload: 1,
      fileTemp: null,
      file: null,
      da: [],
      dalen: 0,
      onExcel: 0,  // 默认为0，表不显示，为1时，导出表数据
      getExceldata: {
        allExceldata: [],
      },
      options: [{
        value: '选项1',
        label: '全部'
      }, {
        value: '选项2',
        label: '信科院'
      }, {
        value: '选项3',
        label: '水土院'
      }, {
        value: '选项4',
        label: '机电院'
      }, {
        value: '选项5',
        label: '农学院'
      }],
      options1: [{
        value1: '全部',
        label: '全部'
      }, {
        value1: '2020上学期',
        label: '2020上学期'
      }, {
        value1: '2020下学期',
        label: '2020下学期'
      }, {
        value1: '2021上学期',
        label: '2021上学期'
      }, {
        value1: '2021下学期',
        label: '2021下学期'
      }],
      options2: [{
        value2: '选项1',
        label: '全部'
      }, {
        value2: '选项2',
        label: '2018级'
      }, {
        value2: '选项3',
        label: '2019级'
      }, {
        value2: '选项4',
        label: '2020级'
      }, {
        value2: '选项5',
        label: '2021级'
      }],
      options3: [{
        value3: '选项1',
        label: '全部'
      }, {
        value3: '选项2',
        label: '互联网'
      }, {
        value3: '选项3',
        label: '计算机'
      }, {
        value3: '选项4',
        label: '大数据'
      }, {
        value3: '选项5',
        label: '统计'
      }],
      tableData: [{}]
    }
  },
  created () {
    this.loadStudentData()
  },
  methods: {
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadStudentData()
    },
    queryStudentByStudentName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentsByLikeName',
        name: this.tableData.name
      })
        .then((res) => {
          if (res.data.status) {
            this.tableData = res.data.data
            console.log('根据学生姓名查询数据', res.data.data)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    updateStudents () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/changeInformation',
        params: {
          id: this.tableData.id,
          name: this.tableData.name,
          gender: this.tableData.gender,
          school: this.tableData.school,
          className: this.tableData.className,
          sid: this.tableData.sid
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.dialogVisible = false
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    queryStudentByClassName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentsByClassName',
        params: {
          className: this.tableData.className
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.tableData = response.data.data
            console.log('根据名字查的数据为:', response.data.data)
          } else {
            this.$message.error(response.data.message)
          }
        })
    },
    queryStudentBySid () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentsBySid',
        params: {
          sid: this.tableData.sid
        }
      })
        .then((res) => {
          if (res.data.status) {
            console.log('学生的数据为:', res.data.data)
            this.tableData = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadStudentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/teacher/showMyStudents',
        params: {
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((response) => {
          if (response.data.status) {
            // console.log('学生的数据为', response.data.data)
            this.tableData = response.data.data.content
          } else {
            this.$message.error(response.data.message)
          }
        })
    },
    handleEdit (index, row) {
      this.stuOrCls = 3
      this.dialogVisible = true
      console.log(index, row)
      this.tableData = row
    },
    handleDelete (index, row) {
      this.tableData.splice(index, this.tableData.length)
    },
    indexMethod (index) {
      return index * 2
    },
    addStu () {
      this.dialogVisible = true
      this.stuOrCls = 1
    },
    addCls () {
      this.dialogVisible = true
      this.stuOrCls = 2
    },
    addAll () {
      this.dialogVisible = true
      this.stuOrCls = 4
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    handleChange (file, fileList) {
      this.fileTemp = file.raw
      if (this.fileTemp) {
        if (
          this.fileTemp.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          this.fileTemp.type === 'application/vnd.ms-excel'
        ) {
          this.importfxx(this.fileTemp)
          //将数据传给后台
          this.douploadExcel()
        } else {
          this.$message({
            type: 'warning',
            message: '附件格式错误，请删除后重新上传！',
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请上传附件！',
        })
      }
    },
    handleExceed () {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！',
      })
      return
    },
    handleRemove (file, fileList) {
      this.fileTemp = null
    },
    importfxx (obj) {
      let _this = this
      let inputDOM = this.$refs.inputer
      // 通过DOM取文件数据

      this.file = event.currentTarget.files[0]

      var rABS = false //是否将文件读取为二进制字符串
      var f = this.file

      var reader = new FileReader()
      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = ''
        var rABS = false //是否将文件读取为二进制字符串
        var pt = this
        var wb //读取完成的数据
        var outdata
        var reader = new FileReader()
        const sheetspacing = {
          defval: '', //如果遇到excel中有控制空值，可以给他赋值"，不然数据中的空值的key将不显示
        }
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result)
          var length = bytes.byteLength
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          var XLSX = require('xlsx')
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: 'base64',
            })
          } else {
            wb = XLSX.read(binary, {
              type: 'binary',
            })
          }
          outdata = XLSX.utils.sheet_to_json(
            wb.Sheets[wb.SheetNames[0]],
            sheetspacing
          ) //outdata就是你想要的东西
          console.log('未处理的原始数据如下：')
          console.log(outdata)
          //将获取的数据赋值
          _this.formModel.getExceldata.allExceldata = outdata
          this.excelData = outdata
          //因为outdata已经将数据获取了，所以数据处理看个人需求
          //此处可对数据进行处理
          let arr = []
          outdata.map((v) => {
            let obj = {}
            obj.id = v['id']
            obj.cardnumber = v['CardNumber']
            obj.operator = v['Operator']
            obj.province = v['Province']
            obj.api = v['API']
            obj.purchase = v['Purchase']
            obj.imei = v['IMEI']
            obj.watermeter = v['WaterMeter']
            obj.cardtype = v['CardType']
            obj.remark = v['Remark']
            obj.status = v['Status']
            obj.isdeleted = v['IsDeleted']
            obj.code = v['Code']
            arr.push(obj)
          })
          _this.da = arr
          _this.dalen = arr.length
          return arr
        }
        reader.readAsArrayBuffer(f)
      }
      if (rABS) {
        reader.readAsArrayBuffer(f)
      } else {
        reader.readAsBinaryString(f)
      }
    },
    //传数据给后台的接口
    douploadExcel () {
      uploadExcel(this.formModel.getExceldata).then((res) => {
        if (res.data.code === 200) {
          this.$Message.success(res.data.message)
          //传入成功后，刷新界面
          this.loadMobileQueryList()
        } else {
          this.$Message.warning(res.data.message)
        }
      })
    },
    changeStatus () {
      this.onExcel = 1
    },
    deleteRow (index, rows) {
      rows.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.el-card {
  margin: 15px 0;
}

.el-tag {
  margin-right: 15px;
}
</style>
