<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png">
      </div>
      <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
        <el-row>
          <el-col :span="12">
            <CommentCharts></CommentCharts>
          </el-col>
          <el-col :span="12">
            <CommentChartsByStudent></CommentChartsByStudent>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
        <el-row style="margin-top: 10px;margin-bottom: 10px">
          <el-col>
            <el-button round icon="el-icon-plus" @click="toAddDiscussion">创建讨论</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="discussionData"
          border
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column prop="id" label="讨论编号" width="100">
          </el-table-column>
          <el-table-column prop="title" label="讨论名称" width="240">
          </el-table-column>
          <el-table-column prop="description" label="讨论详情" width="300">
          </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间" width="180">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button type="danger"
                         @click="deleteRow(scope.$index, scope.row)"
                         size="small">
                删除
              </el-button>
              <el-button size="small" @click="showComment(scope.$index, scope.row)">查看评论</el-button>
              <!--    内置表格的Drawer          -->
              <el-drawer
                title="展示所有的评论"
                :visible.sync="table"
                direction="rtl"
                size="50%">
                <template v-if="comments.length>0">
                  <el-row style="float: right;margin-right: 30px">
                    <el-col>
                      <span>共{{ comments.length }}条评论</span>
                    </el-col>
                  </el-row>
                </template>
                <el-table :data="comments">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column property="id" label="评论编号" width="100"></el-table-column>
                  <el-table-column property="student.name" label="学生姓名" width="100"></el-table-column>
                  <el-table-column property="content" label="评论详情" width="180"></el-table-column>
                  <el-table-column property="gmtCreate" label="创建时间" width="180"></el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="danger"
                                 @click="toDeleteComment(scope.$index, scope.row)"
                                 size="small">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-row style="margin-top: 20px">
                  <el-col>
                    <el-pagination
                      @size-change="handlePageSizeChange1"
                      @current-change="loadCommentData"
                      :current-page.sync="currentPage"
                      :page-sizes="pageSizes1"
                      :page-size="pageSize1"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="commentCount.totalCount">
                    </el-pagination>
                  </el-col>
                </el-row>
              </el-drawer>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top:50px">
          <el-col :span="12" :offset="6">
            <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="loadDiscussion"
              :current-page.sync="currentPage"
              :page-sizes="pageSizes"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="commentCountData.totalCount">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
      <template v-if="isAddOrUp===0">
        <el-dialog
          title="创建讨论"
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="discussion">
            <el-form-item label="学期编号">
              <el-input v-model="discussion.termId" disabled></el-input>
            </el-form-item>
            <el-form-item label="讨论名称">
              <el-input v-model="discussion.title"></el-input>
            </el-form-item>
            <el-form-item label="讨论详情">
              <el-input v-model="discussion.description"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createDiscussion">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--   编辑任务   -->
      <template v-if="isAddOrUp===1">
        <el-dialog
          title="编辑讨论"
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="editDiscussionData">
            <el-form-item label="讨论编号">
              <el-input v-model="editDiscussionData.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="讨论名称">
              <el-input v-model="editDiscussionData.title"></el-input>
            </el-form-item>
            <el-form-item label="讨论详情">
              <el-input v-model="editDiscussionData.description"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateDiscussionData">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--  老师评论     -->
      <template v-if="isAddOrUp===3">
        <el-dialog
          title="编辑讨论"
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="comment">
            <el-form-item label="讨论编号">
              <el-input v-model="comment.discussionId" disabled></el-input>
            </el-form-item>
            <el-form-item label="评论名称">
              <el-input v-model="comment.content"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createComment">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--    确认是否删除评论 -->
      <template v-if="isAddOrUp===4">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span style="color: rgba(255,48,48,0.5);font-weight: bold;font-size: 28px">确实要删除当前的讨论吗</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteDiscussion">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--    确认是否删除评论 -->
      <template v-if="isAddOrUp===5">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span style="color: rgba(255,48,48,0.5);font-weight: bold;font-size: 28px">确实要删除当前的评论吗</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteComment">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import CommentCharts from '@/components/charts/CommentCharts'
import CommentChartsByStudent from '@/components/charts/CommentChartsByStudent'

export default {
  name: 'CommentManage',
  components: {
    TermHeader,
    CommentCharts,
    CommentChartsByStudent
  },
  created () {
    this.termId = this.$route.query.termId
    this.discussion.termId = this.termId
    this.loadDiscussion()
  },
  data () {
    return {
      /*分页*/
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      pageSizes1: [10, 20, 30, 40, 50],
      currentPage1: 1,
      pageSize1: 10,
      total1: 1,
      counter: 0,//评论的次数
      comment: {},    //老师评论的数据
      table: false,  //打开drawer
      comments: [],   //评论数据
      discussion: {},  //单个讨论数据
      termId: 0,
      discussionData: [],
      isAddOrUp: 0,  //0表示添加，1表示更新 2表示老师评论  3表示创建老师评论   4表示确定删除当前的讨论 5 表示是否删除当前的评论
      editDiscussionData: {},
      dialogVisible: false,
      deleteDiscussionId: 0,//确认删除讨论的id
      deleteCommentId: 0,//确认删除评论的id
      loadCommentId: 0,  //加载评论数据的id
      commentCountData: {},      /*获得统计数据*/
      commentCount: {}
    }
  },
  methods: {
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadDiscussion()
    },
    /*改变页码*/
    handlePageSizeChange1 (val) {
      this.pageSize = val
      this.loadCommentData()
    },
    //跳转到添加讨论的弹窗
    toAddDiscussion () {
      this.isAddOrUp = 0
      this.dialogVisible = true
    },
    //添加讨论
    createDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/makeDiscussion',
        params: {
          termId: this.discussion.termId,
          title: this.discussion.title,
          description: this.discussion.description
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadDiscussion()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //加载讨论的数据
    loadDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/showDiscussionsByTermId',
        params: {
          termId: this.termId,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.discussionData = res.data.data.content
            this.commentCountData = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到编辑页面
    handleEdit (index, rowObject) {
      this.dialogVisible = true
      this.isAddOrUp = 1
      this.editDiscussionData = rowObject
    },
    //修改讨论
    updateDiscussionData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/changeTitle',
        params: {
          id: this.editDiscussionData.id,
          title: this.editDiscussionData.title
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.changeDiscussionDescription()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    changeDiscussionDescription () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/changeDescription',
        params: {
          id: this.editDiscussionData.id,
          title: this.editDiscussionData.description
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadDiscussion()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到是否删除讨论的dialog
    deleteRow (index, rows) {
      this.dialogVisible = true
      this.isAddOrUp = 4
      this.deleteDiscussionId = rows.id
    },
    deleteDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/removeDiscussion',
        params: {
          id: this.deleteDiscussionId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadDiscussion()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //展示所有的评论
    showComment (index, rows) {
      this.table = true
      this.loadCommentId = rows.id
      this.loadCommentData()
    },
    //加载所有的评论数据
    loadCommentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/showCommentsByDiscussionId',
        params: {
          discussionId: this.loadCommentId,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.comments = res.data.data.content
            this.commentCount = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到是否删除评论的表单
    toDeleteComment (index, rows) {
      this.dialogVisible = true
      this.isAddOrUp = 5
      this.deleteCommentId = rows.id
    },
    //删除非法的评论
    deleteComment () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/removeCommentById',
        params: {
          id: this.deleteCommentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadCommentData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到评论页面
    toAddComment () {
      this.dialogVisible = true
      this.isAddOrUp = 3
    },
    //老师评论
    createComment () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/makeComment',
        params: {
          discussionId: this.comment.discussionId,
          content: this.comment.content
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.table = false
            // this.showComment()
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
