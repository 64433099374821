<template>
  <div>
    <el-container>
      <el-main class="mainSection">
        <el-row :gutter="20">
          <el-col :md="24" :sm="24">
            <el-card class="box-card" style="margin: 10px 10px">
              <div class="blogs animate animated" data-delay="200">
                <div id="hover1" class="blogCol" @mouseover="handleHover">
                  <div style="display: flex;justify-content: space-between;align-items: flex-end">
                    <h3 style="margin-bottom: 0">学生信度</h3>
                    <el-button class="blogBtn" v-if="btn1" style="height: 40px;margin-bottom: 0" round>查看更多
                    </el-button>
                  </div>
                  <el-divider></el-divider>
                  <el-row>
                    <span>平均偏差绝对值:{{ studentAbilityData.averageAbsoluteDeviation.toFixed(2) }}</span>
                  </el-row>
                  <el-row>
                    <span>最大偏差绝对值:{{ studentAbilityData.maxAbsoluteDeviation.toFixed(2) }}</span>
                  </el-row>
                  <el-row>
                  </el-row>
                  <el-row>
                    <span>准确率:{{ studentAbilityData.accurateRate.toFixed(2) }}</span>
                  </el-row>
                </div>
                <div id="hover2" class="blogCol flex15" @mouseover="handleHover2">
                  <div>
                    <h3>学生信度(仅互评)</h3>
                    <el-divider></el-divider>
                    <el-row>
                      <span>平均偏差绝对值:{{ studentAbilityData.averageAbsoluteDeviationMutualOnly.toFixed(2) }}</span>
                    </el-row>
                    <el-row>
                      <span>最大偏差绝对值:{{ studentAbilityData.maxAbsoluteDeviationMutualOnly.toFixed(2) }}</span>
                    </el-row>
                    <el-row>
                      <span>准确率:{{ studentAbilityData.accurateRateMutualOnly.toFixed(2) }}</span>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-card>

          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { handleScroll } from '../../assets/api/animate.css'

export default {
  name: 'AbilityCard',
  props: {
    studentAbilityData: Object
  },
  data () {
    return {
      searchInput: '',
      headerFlag: false,
      searchFlag: false,
      btn1: false,
      slickOptions: {
        slidesToShow: 3,
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        speed: 500,
        slidesToScroll: 1
      }
    }
  },
  methods: {
    stepEnterHandler ({
      element,
      index,
      direction
    }) {
      document.getElementById('wCurtain').classList.add('w-0')
      this.searchFlag = true
    },
    handleHover () {
      this.btn1 = true
      document.getElementById('hover1').classList.add('flex15')
      document.getElementById('hover2').classList.remove('flex15')
    },
    handleHover2 () {
      this.btn1 = false
      document.getElementById('hover2').classList.add('flex15')
      document.getElementById('hover1').classList.remove('flex15')
    },
    getElementToPageTop (el) {
      if (el.parentElement) {
        return this.getElementToPageTop(el.parentElement) + el.offsetTop
      }
      return el.offsetTop
    },
    handleAnimate () {
      const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const vh = document.documentElement.clientHeight
      const dom = document.querySelectorAll('.animate');
      [].slice.call(dom).forEach(v => {
        v.style.opacity = 0
        if (top + 0.5 * vh > this.getElementToPageTop(v) / 3.09) {
          if (v.dataset.delay) {
            setTimeout(() => {
              v.style.opacity = 1
              v.classList.add('animated')
              if (v.dataset.ani) {
                v.classList.add(v.dataset.ani)
              } else {
                v.classList.add('fadeIn')
              }
            }, v.dataset.delay)
          } else {
            v.style.opacity = 1
            v.classList.add('animated')
            if (v.dataset.ani) {
              v.classList.add(v.dataset.ani)
            } else {
              v.classList.add('fadeIn')
            }
          }
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.handleAnimate()
      addEventListener('scroll', handleScroll)
      addEventListener('scroll', this.handleAnimate)
    })
  },
  destroyed () {
    removeEventListener('scroll', handleScroll)
    removeEventListener('scroll', this.handleAnimate)
  }
}
</script>

<style scoped>
.whiteCurtain {
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 0;
  transition: 1.5s ease-in-out;
}

.w-0 {
  width: 0 !important;
}

.pre-introduction {
  background: url(https://cos.mooc.huangzhaokun.top/moocImg/background2.png);
  background-repeat: no-repeat;
  background-position: 0 65%;
  background-size: 100%;
  height: 100vh;
  padding: 100px 0;
  box-sizing: border-box;
}

.flex15 {
  flex: 1.5 !important;
  background: #0052D9 !important;
  color: #ffffff;
}

#hover1 {
  margin-right: 32px;
}

.blogs {
  justify-content: space-between;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 180px;
}

.blogBtn {
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: transparent;
  transition: 0.35s;
}

.blogBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.blogCol p {
  position: relative;
  z-index: 100;
  /*transition: 0.35s;*/
}

.blogCol p:hover {
  color: rgba(255, 255, 255, 0.6);
}

.blogCol {
  justify-content: space-between;
  height: 450px;
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 48px;
  padding-top: 44px;
  border-radius: 24px;
  transition: 0.35s;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 16%);
}

.mainSection {
  display: block;
  width: 100%;
  padding: 0;
  height: 100%;
}

.animate {
  opacity: 0;
}

.fadeInDown {
  animation: fadeInDown .5s ease-in;
}

.owlImg img {
  width: 100%;
}

a {
  text-decoration: none;
}

.expertSection {
  background-repeat: no-repeat;
  background-position: 0 80%;
  background-size: 100%;
  padding: 100px 0;
  box-sizing: border-box;
}

.xqhzTea-R {
  padding: 35px 15px 20px 33px;
  float: left;
  width: calc(100% - 266px);
  background-color: #ffffff;
  height: 355px;
  margin-bottom: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.xqhzTea-R h6 {
  font-size: 22px;
  color: #333333;
  padding-bottom: 25px;
  line-height: 1;
  border-bottom: 1px solid #b2bcca;
  margin-right: 18px;
}

.xqhzTea-R ul {
  margin-top: 20px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.xqhzTea-list {
  overflow: hidden;
}

.xqhzTea-list img {
  width: 266px;
  height: 355px;
  display: inline-block;
  float: left;
}

.xqhzTea-R li {
  list-style: none;
}

.heading .divider-left, .heading .divider-center {
  height: 4px;
  width: 70px;
  background: #4587d9;
  display: block;
}

.heading .divider-left {
  margin: 10px 0 -2px;
}

#hover1::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 37%;
  height: 100%;
  background-image: url(https://cos.mooc.huangzhaokun.top/moocImg/quarter.png);
  background-size: cover;
  transform: rotate(180deg);
  z-index: 10;
}

#hover2::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: '';
  width: 26.88%;
  height: 60%;
  background-image: url(https://cos.mooc.huangzhaokun.top/moocImg/quarter.png);
  background-size: cover;
}

.slickItem {
  width: 100px;
  height: 100px;
  background-color: #348AC7;
}
</style>
