<template>
  <div>
    <el-row>
      <pre class="description">{{ question.paperQuestion.description }}({{ question.paperQuestion.score }}分)</pre>
    </el-row>
    <el-row>
      <div v-for="(o,i) in question.paperQuestion.paperOptions" :key="o.id">
        <input class="option" type="checkbox" :value="o.id" v-model="question.record" disabled>
        <label class="optionLabel">
          {{ order[i++] }}、{{ o.description }}
        </label>
      </div>
    </el-row>
    <el-row style="float: right;margin-top: -80px">
      <el-row>
        <span>得分:{{question.score}}'</span>
      </el-row>
      <template v-if="question.score===question.fullScore">
        <el-tag type="success">正确</el-tag>
      </template>
      <template v-if="question.score!==question.fullScore">
        <el-tag type="danger">错误</el-tag>
      </template>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'MutualQuestion',
  data () {
    return {
      record: [],
      order: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    }
  },
  props: {
    question: Object
  },
  methods: {
  }
}
</script>

<style scoped>
.option {
  font-size: 25px;
  line-height: 1.5;
}

.description {
  margin: 0;
  font-size: 20px;
}
</style>
