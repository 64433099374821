<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../../assets/img/situationanalysis.png"/>
      </div>
    </el-main>
    <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
      <el-col :span="12">
        <FinalScore></FinalScore>
      </el-col>
      <el-col :span="12">
        <ExamAverageStudent></ExamAverageStudent>
      </el-col>
    </el-card>
    <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
      <el-col :span="12">
        <TaskSubmissionCharts></TaskSubmissionCharts>
      </el-col>
      <el-col :span="12">
        <CommentChartsByStudent></CommentChartsByStudent>
      </el-col>
    </el-card>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import FinalScore from '@/components/charts/FinalScore'
import ExamScore from '@/components/charts/ExamScore'
import TaskSubmissionCharts from '@/components/charts/TaskSubmissionCharts'
import CommentChartsByStudent from '@/components/charts/CommentChartsByStudent'
import ExamAverageStudent from '@/components/charts/ExamAverageStudent'

export default {
  name: 'RecordManage',
  components: {
    ExamAverageStudent,
    TaskSubmissionCharts,
    ExamScore,
    FinalScore,
    TermHeader,
    CommentChartsByStudent
  },
  data () {
    return {
      termId: 0
    }
  },
  created () {
    this.termId = this.$route.query.termId
    this.studentId = this.$route.query.studentId
  },
  methods: {}
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

</style>
