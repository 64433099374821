<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-row style="margin-left: 30px;margin-top: 30px">
         <el-col :span="20">
           <i class="el-icon-s-order" style="font-weight: bold;font-size: 30px">{{ this.chapter.name }}</i>
         </el-col>
        <el-col :span="4">
          <el-button @click="returnData" icon="el-icon-right" size="small" round>返回</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div id="showStudent" style="width:360px;height:280px;margin-top: 30px;margin-left: 50px"></div>
          <el-row>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px">Statistics Information</span>
                <el-divider></el-divider>
                <span>学生已完成数:{{ this.finish }}</span>
                <el-divider></el-divider>
                <span>学生未完成数:{{ this.unFinish }}</span>
                <el-divider></el-divider>
                <span>本章节学生人数:{{ this.total }}</span>
                <el-divider></el-divider>
              </div>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="16" style="margin-top: 10px ;width: 600px">
          <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Student Information</span>
          <el-table
            :data="studentData"
            style="width: 100%">
            <el-table-column
              prop="chapterId"
              label="学生编号"
              width="180">
            </el-table-column>
            <el-table-column
              property="student.name"
              label="学生姓名"
              width="180">
              <template slot-scope="scope">
                <a style="cursor: pointer" @click="goStudent(scope.row.studentId)">{{ scope.row.student.name }}</a>
              </template>
            </el-table-column>
            <el-table-column property="finished" label="完成" width="180">
              <template slot-scope="scope">
                <template v-if="scope.row.finished===true">
                  <el-button type="success" circle icon="el-icon-circle-check"></el-button>
                </template>
                <template v-if="scope.row.finished===false">
                  <el-button circle icon="el-icon-circle-close" type="danger"></el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top:20px">
            <el-col :span="12">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadStudentChapter"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
              </el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'showStudentChapter',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.chapterId = this.$route.query.chapterId
    this.showChapterName()   /*拿到课程名字信息*/
    this.loadStudentChapter()  /*加载学生完成课程的数据*/
    this.loadStudentCount() /*获取及格人数和不及格人数*/
  },
  data () {
    return {
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      studentData: [],    //定义课程数据
      chapter: {},      //拿到课程对象
      termId: 0,
      chapterId: 0,
      recordData: [],
      finish: 0,
      unFinish: 0   /*统计完成和未完成的*/
    }
  },
  methods: {
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadStudentChapter()
    },
    /*跳转学生章节详细页面*/
    goStudent (id) {
      this.$router.push({
        path: '/showStudentChapter',
        name: 'ShowStudentChapter',
        query: {
          termId: this.termId,
          studentId: id
        }
      })
    },
    /*加载章节学生的数据*/
    loadStudentChapter () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/teacherShowRecordsByTermIdAndChapterId',
        params: {
          termId: this.termId,
          chapterId: this.chapterId,
          setStudent: true,
          currentPage: this.currentPage,
          pageSize: 8
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.studentData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadStudentCount () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/teacherShowRecordsByTermIdAndChapterId',
        params: {
          termId: this.termId,
          chapterId: this.chapterId,
          setStudent: true,
          currentPage: this.currentPage,
          pageSize: 1000
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            for (let i = 0; i < countData.length; i++) {
              if (countData[i].finished === false) {
                this.unFinish += 1
              } else if (countData[i].finished === true) {
                this.finish += 1
              }
            }
            this.total = res.data.data.totalCount
            this.showStudent()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    showStudent () {
      // 基于准备好的dom，初始化echarts实例
      const showStudent = this.$echarts.init(document.getElementById('showStudent'))
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: 'Learning Statistics',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ['完成', '未完成']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.finish,
                name: '完成'
              },
              {
                value: this.unFinish,
                name: '未完成'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      showStudent.setOption(option)
      window.addEventListener('resize', function () {
        showStudent.resize()
      })
    },
    /*拿到学生对象*/
    showChapterName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/showChapterById',
        params: {
          id: this.chapterId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.chapter = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    returnData () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.box-card {
  width: 250px;
  margin-left: 100px;
  margin-top: 10px;
  height: 350px;
  border-radius: 20px;
  mso-border-shadow: yes;
}
</style>
