<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'CourseBox',
  data: function () {
    return {
      rate: 4.5
    }
  },
  methods: {
    viewCourse () {
      this.$router.push({ path: '/coursevideo' })
    }
  }
}
</script>

<style scoped>
.viewed-courses-text {
  font-size: 12px;
}

.viewed-courses-box {
  float: left;
  margin-bottom: 50px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.viewed-courses-text h6 {
  font-size: 15px;
  font-weight: 400;
}

a {
  color: #222;
  transition: all 0.35s ease 0s;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #4587d9;
  text-decoration: none;
}
</style>
