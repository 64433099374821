<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {

  name: 'ShowPaper',
  components: {
    TermHeader
  },
  data () {
    return {
      paper: {},        /*试卷*/
      termId: 0,      /*学期Id*/
      examId: 0,     /*考试Id*/
      studentId: 0    /*学生Id*/
    }
  },
  created () {
    this.termId = this.$route.query.termId
    this.examId = this.$route.query.examId
    this.studentId = this.$route.query.studenId
    this.loadPaper()
  },
  methods: {
    loadPaper () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showExamPaperByIdAndStudentId',
        params: {
          id: this.examId,
          studentId: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.paper = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
</style>
