<template>
  <div id="studentComment" style="width:600px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CommentChartsByStudent',
  data () {
    return {
      rate: 0.0,
      termId: 0,
      /*等级*/
      S: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      /*等级*/
      commentData: [],
      commentName: [],
      commentRequire: 0      /*获得需要要求评论次数*/
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点

  },
  mounted () {
    /*展示学生已经完成的数据*/

    /*获得证书讨论次数*/
    this.loadThisTerm()
  },
  methods: {
    /*获得当前学期的证书*/
    loadThisTerm () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermById',
        params: {
          id: this.termId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.commentRequire = countData.certificateRule.commentRequire
            //  console.log(this.commentRequire)
            this.loadStudentComment()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadStudentComment () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/countCommentsByTermIdGroupByStudentId',
        params: {
          termId: this.termId,
          setStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            if (this.commentRequire !== 0) {
              for (let i = 0; i < countData.length; i++) {
                this.rate = countData[i].count / this.commentRequire
                if (this.rate === 0.0) {
                  this.E += 1
                } else if (this.rate > 0.0 && this.rate <= 0.25) {
                  this.D += 1
                } else if (this.rate > 0.25 && this.rate <= 0.50) {
                  this.C += 1
                } else if (this.rate > 0.50 && this.rate <= 0.75) {
                  this.B += 1
                } else if (this.rate > 0.75 && this.rate < 1.00) {
                  this.A += 1
                } else if (this.rate >= 1.00) {
                  this.S += 1
                }
              }
            }
            //console.log(this.A)
            this.studentComment()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*改变key的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*加载学生已经完成的数据*/
    studentComment () {
      //  let gayData = this.convertKey(this.commentData, ['id', 'name', 'value'])
      // 基于准备好的dom，初始化echarts实例
      const studentComment = this.$echarts.init(document.getElementById('studentComment'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生评论数据表',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ['0%', '1%~25%', '25%~50%', '50%~75%', '75%~99%', '100%']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.E,
                name: '0%'
              },
              {
                value: this.D,
                name: '1%~25%'
              },
              {
                value: this.C,
                name: '25%~50%'
              },
              {
                value: this.B,
                name: '50%~75%'
              },
              {
                value: this.A,
                name: '75%~99%'
              },
              {
                value: this.S,
                name: '100%'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      studentComment.on('click', params => {
        this.$router.push({
          path: '/commentmanage',
          name: 'CommentManage',
          query: {
            termId: this.termId
          }
        })
      })
      // 使用刚指定的配置项和数据显示图表。
      studentComment.setOption(option)
      window.addEventListener('resize', function () {
        studentComment.resize()
      })
    },
  }
}
</script>

<style scoped>

</style>
