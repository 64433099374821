<template>
  <div @click="exportPDF"> <!--@click="getPdf('#pdfDom')"-->
    <el-card shadow="always" style="border-radius: 30px"> <!-- @click="exportPDF('#pdfDom')"-->
      <div id="pdfDom">
        <div class="proBox">
          <p class="honor">荣誉证书</p>
          <p class="proid"><span>编号:</span> <span>{{ this.certificate.id }}</span></p>
          <p class="con">
            <span class="con-name">{{ this.certificate.student.name }}</span>
            同学<span>在{{ this.certificate.term.name }}</span>完成{{ this.certificate.course.name }}的学习。
            获得{{ this.certificate.score.toFixed(2) }}分,成绩合格，准予结业。
          </p>
          <div class="con-unit">
            <p>湖南农业大学</p>
            <p class="time">{{new Date(this.certificate.awardTime).getFullYear() + '年'
                +(new Date(this.certificate.awardTime).getMonth() + 1) + '月' +new Date(this.certificate.awardTime).getDate()+'日' }}</p>
          </div>
          <p class=" con-footer">{{ this.certificate.teacher.name }}</p>
          <div class="chapter" v-show="isShow">
            <canvas id="chapterCanvas" width="150" height="150"></canvas>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'CreateCertificate',
  props: {
    certificate: Object
  },
  data () {
    return {
      //从本地缓存中获得当前登录账号和等级
      pageData: null, //接收html格式代码
      htmlTitle: '荣誉证书',
      isShow: false,
      isCanvas: false,
      downType: true // false为 pdf , true为图片
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    preview () {
      this.$nextTick(() => {
        if (!this.isCanvas) {
          // 只绘画一次
          this.isCanvas = true
          this.getChapter()
        }
      })
    },
    /* getPdf(){
       this.preview()
       this.getPdf('pdfDom')
     },*/
    /*生成奖章*/
    exportPDF () {
      this.preview()

    },
    /*生成印章*/
    getChapter () {
      this.isShow = true
      let canvas = document.getElementById('chapterCanvas')
      let context = canvas.getContext('2d')
      let text = '信科院专用章'
      let companyName = '湖南农业大学'
      //  context.translate(0, 0);
      // context.clearRect(0, 0, 200, 200);//清除整个画布
      // 绘制印章边框
      let width = canvas.width / 2
      let height = canvas.height / 2
      context.lineWidth = 3
      context.strokeStyle = '#cf0c0c'
      context.beginPath()
      context.arc(width, height, 60, 0, Math.PI * 2) //宽、高、半径
      context.stroke()
      //画五角星
      create5star(context, width, height, 15, '#cf0c0c', 0)
      // 绘制印章名称
      context.font = '20px 宋体'
      context.textBaseline = 'middle' //设置文本的垂直对齐方式
      context.textAlign = 'center' //设置文本的水平对对齐方式
      context.lineWidth = 1
      context.strokeStyle = '#cf0c0c'
      // context.strokeText(text, width, height + 30); // 名称 xx专用章
      // 绘制印章单位
      context.translate(width, height) // 平移到此位置,
      context.font = '20px 宋体'
      let count = companyName.length // 字数
      let angle = (5 * Math.PI) / (3 * (count - 1)) // 字间角度
      let chars = companyName.split('')
      let c
      for (let i = 0; i < count; i++) {
        c = chars[i] // 需要绘制的字符
        if (i === 0) {
          context.rotate((4 * Math.PI) / 6)// 文字起始位置
        } else {
          context.rotate(angle)
        }
        context.save()
        context.translate(45, 0) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        context.strokeText(c, 0, 0) // 此点为字的中心点
        context.restore()
      }

      //绘制五角星
      function create5star (context, sx, sy, radius, color, rotato) {
        context.save()
        context.fillStyle = color
        context.translate(sx, sy) //移动坐标原点
        context.rotate(Math.PI + rotato) //旋转
        context.beginPath() //创建路径
        var x1 = Math.sin(0)
        var y1 = Math.cos(0)
        var dig = (Math.PI / 5) * 4
        for (var i = 0; i < 5; i++) {
          //画五角星的五条边
          var x = Math.sin(i * dig)
          var y = Math.cos(i * dig)
          context.lineTo(x * radius, y * radius)
        }
        context.closePath()
        context.stroke()
        context.fill()
        context.restore()
      }

      this.getPdf('pdfDom')
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
  display: flex;
  justify-content: center;
}

#pdfDom {
  /* 要想pdf周边留白，要在这里设置 */
}

.proBox {
  /* 奖状的模板 */
  background: url("../../assets/img/bg/test4.png") no-repeat;
  background-size: cover;
  width: 1250px;
  height: 700px;
  padding: 90px 94px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  color: #000;
  font-family: SimSun, serif;
}

.tit {
  color: #cf0c0c;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  top: -6px;
  left: 8px;
  letter-spacing: 20px;
  font-family: STHeiti, serif;
  margin: 20px 0;
}

.proid {
  margin-top: 80px;
  text-align: right;
  font-weight: 500;
  margin-right: 30px;
}

/*荣誉证书*/
.honor {
  font-size: 70px;
  font-weight: bold;
  line-height: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  color: rgba(255, 0, 0, 0.8);
}

/*字体问题*/
.con {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  line-height: 32px;
  text-indent: 2em;
  margin-top: 10px;
  margin-left: 170px;
}

.con-text {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 10px 0;
  line-height: 32px;
  text-indent: 5em;
}

.con-name {
  font-family: 华文行楷, STXingkai, serif;
  border-bottom: 2px solid #000;
}

.con-height {
  font-family: 华文行楷, STXingkai, serif;
  font-weight: 700;
  font-size: 2em
}

.con-unit {
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  right: 130px;
  bottom: 130px;
  text-align: center;
  letter-spacing: 3px;
}

.con-unit p {
  margin: 5px 0;
}

.con-footer {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

.time {
  font-weight: bold;
}

.chapter {
  border-radius: 50%;
  position: absolute;
  bottom: 75px;
  right: 134px;
}


</style>
