<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-row style="margin-left: 30px;margin-top: 30px">
        <i class="el-icon-document" style="font-weight: bold;font-size: 30px">{{ this.task.title }}</i>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-card shadow="always" style="width: 80%;border-radius: 30px;margin-left: 50px;margin-top: 20px">
            <div id="score" style="width:360px;height:280px;"></div>
            <el-row>
              <div id="grade" style="width:360px;height:280px;"></div>
            </el-row>
            <el-row>
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span style="font-weight: bold;font-size: 20px">Statistics Information</span>
                  <el-divider></el-divider>
                  <span>完成任务人数:{{ this.sum }}</span>
                  <el-divider></el-divider>
                  <span>通过任务人数:{{ this.pass }}</span>
                  <el-divider></el-divider>
                  <span>未通过任务人数:{{ this.fail }}</span>
                  <el-divider></el-divider>
                </div>
              </el-card>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="16" style="margin-top: 10px">
          <el-card shadow="always" style="border-radius: 30px;">
            <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Student Information</span>
            <el-table
              :data="taskStudentData"
              style="width: 100%">
              <el-table-column
                prop="studentId"
                label="学生编号"
                width="120">
              </el-table-column>
              <el-table-column
                prop="studentId"
                label="学生姓名"
                width="120">
              </el-table-column>
              <el-table-column
                property="submissionUrl"
                label="评分内容"
                width="180">
                <template slot-scope="scope">
                  <img :src="scope.row.submissionUrl" style="height: 80px;width: 80px">
                </template>
              </el-table-column>
              <el-table-column
                prop="score"
                label="分数"
                width="100">
              </el-table-column>
              <el-table-column property="scoreType" label="评分形式" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.scoreType==='teacher'">
                    <el-tag type="success">老师评分</el-tag>
                  </template>
                  <template v-if="scope.row.scoreType==='mutual'">
                    <el-tag type="warning">互评评分</el-tag>
                  </template>
                  <template v-if="scope.row.scoreType==='unchecked'">
                    <el-tag type="danger">未评分</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button size="small" round @click="viewTaskStudent(scope.row.studentId)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top:20px">
              <el-col :span="12" :offset="2">
                <el-pagination
                  @size-change="handlePageSizeChange"
                  @current-change="loadStudentTask"
                  :current-page.sync="currentPage"
                  :page-sizes="pageSizes"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.total">
                </el-pagination>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'TaskAverage',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.taskId = this.$route.query.taskId
    this.showTaskName()   /*拿到任务名字信息*/
    this.loadStudentTask()  /*加载学生完成课程的数据*/
    this.loadStudentTaskCount()
  },
  data () {
    return {
      /*分页*/
      /*等级*/
      S: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      sum: 0,              //统计总人数
      pass: 0,            //统计考试及格人数
      fail: 0,           //统计考试不及格人数
      taskStudentData: [],    //定义考试数据
      termId: 0,
      task: {},         //获得考试对象
      taskNum: [],
      taskStudentName: [],
      taskId: 0      //定义任务Id
    }
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadStudentTask()
    },
    /*查看学生的考试成绩*/
    viewTaskStudent (id) {
      this.$router.push({
        path: '/taskStudent',
        name: 'TaskByStudent',
        query: {
          termId: this.termId,
          studentId: id
        }
      })
    },
    /*加载学生课程信息*/
    loadStudentTask () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTaskSubmissionsByTaskId',
        params: {
          taskId: this.taskId,
          currentPage: this.currentPage,
          pageSize: 10,
          setStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.taskStudentData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadStudentTaskCount () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTaskSubmissionsByTaskId',
        params: {
          taskId: this.taskId,
          currentPage: this.currentPage,
          pageSize: 1000
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            for (let j = 0; j < countData.length; j++) {
              this.taskNum.push(countData[j].score)
              this.taskStudentName.push(countData[j].studentId)
            }
            this.total=res.data.data.totalCount
            for (let i = 0; i < countData.length; i++) {
              if (countData[i].score >= 6.0) {
                this.pass += 1
              } else {
                this.fail += 1
              }
              this.sum = this.pass + this.fail
            }
            for (let j = 0; j < countData.length; j++) {
              this.taskNum.push(countData[j].score)
              this.taskStudentName.push(countData[j].studentId)
              if (countData[j].score > 8.0 && countData[j].score < 10.0) {
                this.E += 1
              } else if (countData[j].score <= 2.0 && countData[j].score > 0) {
                this.D += 1
              } else if (countData[j].score > 2.0 && countData[j].score <= 4.0) {
                this.C += 1
              } else if (countData[j].score <= 6.0 && countData[j].score > 4.0) {
                this.B += 1
              } else if (countData[j].score <= 8.0 && countData[j].score > 6.0) {
                this.A += 1
              } else if (countData[j].score === 10.0) {
                this.S += 1
              }
            }
            this.loadScore()
            this.loadGrade()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载考试信息*/
    loadScore () {
      let gayData = this.convertKey(this.taskStudentData, ['id', 'score', 'name', 'url', 'value', 'scoreType'])
      //  console.log(kData)
      const score = this.$echarts.init(document.getElementById('score'))
      var option = {
        title: {
          text: '学生成绩表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: function (params) {
            //console.log(params)
            let str = ''
            for (let i = 0; i < params.length; i++) { // 添加单位(*^▽^*)
              str += '<br/>' + params[i].data.name + ' ' + params[i].data.score
              //console.log(params[i].data.name.name)
            }
            return str
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: '',
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: gayData,
            color: '#7FFFAA',
            itemStyle: {
              color: function (params) {
                /*        let colorList = ['rgb(164,205,238)', 'rgb(42,170,227)', 'rgb(25,46,94)', 'rgb(195,229,235)', 'rgb(195,229,235)']
                        return colorList[params.dataIndex]*/
                let c = ''
                if (params.data.scoreType === 'teacher') {
                  c = 'rgb(164,205,238)'
                } else if (params.data.scoreType === 'mutual') {
                  c = 'rgb(25,46,94)'
                }
                return c
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            }
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            data: gayData,
            color: '#FFFAA0'
          }
        ]
      }
      score.on('click', params => {
        this.$router.push({
          path: '/taskStudent',
          name: 'TaskByStudent',
          query: {
            termId: this.termId,
            studentId: params.data.name
          }
        })
        /// console.log('=====', index, '====')
        console.log(params)
      })
      score.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        score.resize()
      })
    },
    loadGrade () {
      // 基于准备好的dom，初始化echarts实例
      const grade = this.$echarts.init(document.getElementById('grade'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生层级图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 5,
          left: 'center',
          data: ['不及格', '及格', '良好', '优秀', '五星', '满分大神']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.D,
                name: '不及格'
              },
              {
                value: this.C,
                name: '及格'
              },
              {
                value: this.B,
                name: '良好'
              },
              {
                value: this.A,
                name: '优秀'
              },
              {
                value: this.E,
                name: '五星'
              },
              {
                value: this.S,
                name: '满分大神'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      grade.setOption(option)
      window.addEventListener('resize', function () {
        grade.resize()
      })
    },
    showTaskName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/showTaskById',
        params: {
          id: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.task = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.box-card {
  margin-left: 50px;
  width: 250px;
  margin-top: 10px;
  height: 350px;
  border-radius: 20px;
  mso-border-shadow: yes;
}


</style>

