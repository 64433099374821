<template>
  <div @click="exportPDF('#pdfDom')">
    <el-card shadow="always" style="border-radius: 30px">
      <div id="pdfDom">
        <div class="proBox">
          <p class="honor">荣誉证书</p>
          <p class="proid"><span>编号:</span> <span>{{ this.certificate.id }}</span></p>
          <p class="con">
            <span class="con-name">{{ this.certificate.student.name }}</span>
            同学<span>在{{ this.certificate.term.name }}</span>完成{{ this.certificate.course.name }}的学习。
            <br>
           获得{{this.certificate.score.toFixed(2)}}分,成绩合格，准予结业。
          </p>
          <div class="con-unit">
            <p>湖南农业大学</p>
            <p class="time">{{ this.certificate.awardTime }}</p>
          </div>
          <p class=" con-footer">{{ this.certificate.teacher.name }}</p>
          <!--            <div class="chapter" v-show="isShow">
                        <canvas id="chapterCanvas" width="150" height="150"></canvas>
                      </div>-->
          <!--          <el-row style="float: right ;margin-top: 50px">
                      <el-button size="small" @click="exportPDF('#pdfDom')" round>下载</el-button>
                    </el-row>-->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'CreateCertificate',
  props: {
    certificate: Object
  },
  data () {
    return {
      //从本地缓存中获得当前登录账号和等级
      pageData: null, //接收html格式代码
      htmlTitle: '荣誉证书',
      isShow: true,
      isCanvas: false,
      downType: true // false为 pdf , true为图片
    }
  },
  created () {
  },
  methods: {
    /*生成奖章*/
    preview () {
      var vm = this
      //获取当前日期
      var data = new Date(new Date().setHours(0, 0, 0, 0))
      vm.start =
        data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate()
      console.log(vm.start)
      this.dialogVisible = true
      this.$nextTick(() => {
        if (!this.isCanvas) {
          // 只绘画一次
          this.isCanvas = true
          // this.getChapter()
        }
      })
    },
    exportPDF () {
      this.getPdf('pdfDom')
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
  padding: 0px;
  display: flex;
  justify-content: center;
}

#pdfDom {
  /* 要想pdf周边留白，要在这里设置 */
}

.proBox {
  /* 奖状的模板 */
  background: url("../../assets/img/bg/test3.jpg") no-repeat;
  background-size: cover;
  width: 1250px;
  height: 1700px;
  padding: 90px 94px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  color: #000;
  font-family: SimSun, serif;
}

.tit {
  color: #cf0c0c;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  top: -6px;
  left: 8px;
  letter-spacing: 20px;
  font-family: STHeiti, serif;
  margin: 20px 0;
}

.proid {
  margin-top: 80px;
  text-align: right;
  font-weight: 500;
  margin-right: 30px;
}

/*荣誉证书*/
.honor {
  font-size: 70px;
  font-weight: bold;
  line-height: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 380px;
  color: rgba(255, 0, 0, 0.8);
}

/*字体问题*/
.con {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  line-height: 32px;
  text-indent: 2em;
  margin-top: 100px;
  margin-left: 80px;
}

.con-text {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 10px 0;
  line-height: 32px;
  text-indent: 5em;
}

.con-name {
  font-family: 华文行楷, STXingkai, serif;
  border-bottom: 2px solid #000;
}

.con-height {
  font-family: 华文行楷, STXingkai, serif;
  font-weight: 700;
  font-size: 2em
}

.con-unit {
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  right: 130px;
  bottom: 180px;
  text-align: center;
  letter-spacing: 3px;
}

.con-unit p {
  margin: 5px 0;
}

.con-footer {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  bottom: 250px;
  left: 0;
  right: 0;
  text-align: center;
}

.time{
  font-weight: bold;
}
.chapter {
  border-radius: 50%;
  position: absolute;
  bottom: 75px;
  right: 134px;
}


</style>
