import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/css/style.css'
import axios from 'axios'
import qs from 'qs'
import * as echarts from 'echarts'
import COS from 'cos-js-sdk-v5'
import htmlToPdf from '@/assets/api/utils/htmlToPdf'
//cos的配置

const cos = new COS({
  SecretId: 'AKIDjIdjZ9brtgEWaNvDG3bv0rSntqY0C9qH',
  SecretKey: 'C9rKYWGkywf8LJ1UheMp20iCPM4WieKk'
})
export default cos
//
const instance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
instance.interceptors.request.use(
  config => {
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config
  },
  error => Promise.error(error)
)
axios.defaults.baseURL = 'https://mooc.kirinnorine.top/teacher'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')





