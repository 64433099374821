<template>
  <div class="successContainer">
    <p>恭喜您完成注册!</p>
    <p>现在登录!</p>
  </div>
</template>

<script>
export default {
  name: 'RegisterStepFour'
}
</script>

<style scoped>
.successContainer{
  padding: 60px 0;
  text-align: center;
  font-size: 24px;
}
</style>
