<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png"
             style="position: relative;z-index: -100">
        <span
          style="font-weight: bold;font-size: 28px;position: absolute;margin-top: 50px;margin-left: -1300px;color: #ffffff;mso-border-shadow: yes">
          {{ this.course.name }}</span>
      </div>
      <el-row>
        <el-tabs type="border-card">
          <!--    获得课程的基本信息  -->
          <el-tab-pane label="基本信息">
            <el-form :model="course" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-upload style="margin-left: 10%;margin-bottom: 10px" action=" " list-type="picture-card" multiple
                         :limit="1" :before-upload="beforeAvatarUpload"
                         :on-change="handleAddChange" :on-remove="handleAddRemove" :class="{'hide':hideUploadAdd}"
                         :auto-upload="false">
                <i class="el-icon-plus"></i>
                <div class="el-upload__tip" slot="tip">（支持jpg，jpeg，png图片格式，图片大小不超过20M,上传图片尺寸比例必须为3：2）</div>
              </el-upload>
              <el-form-item label="所属课程" prop="name" style="width: 60%">
                <el-input v-model="course.name" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属编码" prop="id" style="width: 45%">
                <el-input v-model="course.id" disabled></el-input>
              </el-form-item>
              <el-form-item label="学期" prop="termData">
                <el-select v-model="termData.name" clearable>
                  <el-option v-for="item in termData" :key="item.name" :label="item.label" :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开放时间" required>
                <el-col :span="7">
                  <el-form-item prop="gmtCreate">
                    <el-date-picker type="datetime" placeholder="选择日期" v-model="course.gmtCreate" style="width: 90%;"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="1">至</el-col>
                <el-col :span="7">
                  <el-form-item prop="gmtModified">
                    <el-date-picker type="datetime" placeholder="选择日期" v-model="course.gmtModified" style="width: 100%;"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item label="共享状态" prop="status">
                <el-radio-group v-model="course.status">
                  <el-radio :label="'public'">共享</el-radio>
                  <el-radio :label="'private'">私有</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="课程简要" prop="courseInfo.infoUrl">
                <el-button type="primary" @click="goChangeUrl" icon="el-icon-upload" round></el-button>
              </el-form-item>
              <el-form-item label="课程概述" prop="courseInfo.sketch">
                <el-input type="textarea" v-model="course.courseInfo.sketch" clearable style="height: 100px"></el-input>
              </el-form-item>
              <el-form-item label="课程页面" prop="courseInfo.imageUrl">
                <img alt="" style="height: 200px;width: 200px" :src="course.courseInfo.imageUrl">
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="changeCourseSketch">修改</el-button>
                <el-button @click="returnData">返回</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!--  上传资源的对话框   -->
          <!--   单元级活动       -->
          <el-tab-pane label="单元级活动">
            <!--     树形控件          -->
            <el-row>
              <el-col :offset="21">
                <el-button round type="primary" icon="el-icon-plus" style="margin-bottom:20px" @click="addParent">添加章节
                </el-button>
              </el-col>
            </el-row>
            <el-tree
              :props="treeProps"
              :data="data"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <template v-if="data.parent===null">
                      第{{ data.orderNumber }}章
                    (
                     <template v-if="data.chapterInfo.contentType==='video'">
                      视频
                     </template>
                     <template v-if="data.chapterInfo.contentType==='quiz'">
                       测试
                     </template>
                    <template v-if="data.chapterInfo.contentType=='none'">资料</template>
                    )
                  </template>
                  <template v-if="data.parent!==null">
                       第{{ data.orderNumber }}课时
                   ---  <template v-if="data.chapterInfo.contentType==='video'">
                      视频
                     </template>
                     <template v-if="data.chapterInfo.contentType==='quiz'">
                       测试
                     </template>
                    <template v-if="data.chapterInfo.contentType==='none'">资料</template>
                  </template>
                  <span>{{ node.label }}</span>
                   <span>
                     <el-button
                       type="text"
                       size="mini"
                       @click="() => updateKey(node,data)" icon="el-icon-plus-outline">
                      VIDEO
                      </el-button>
                     <el-button
                       type="text"
                       size="mini"
                       @click="() => addQuiz(node,data)" icon="el-icon-plus-outline">
                       QUIZ
                      </el-button>
                     <el-button
                       type="text"
                       size="mini"
                       @click="() => updateKeyChildren(node,data)">
                      Edit
                    </el-button>
                   <el-button
                     type="text"
                     size="mini"
                     @click="() => append(data, node)">
                      Append
                    </el-button>
                    <el-button
                      type="text"
                      size="mini"
                      @click="() => remove(node,data)">
                      Delete
                    </el-button>
                   </span>
               </span>
            </el-tree>
          </el-tab-pane>
          <!-- 单元级活动-->
          <!--   添加树形控件的信息       -->
          <!--添加or修改章节-->
          <!--   添加父章节的dialog       -->
          <template v-if="this.addOrUp===0">
            <el-dialog class="add-dialog" title="章节信息" :visible.sync="dialogVisible">
              <el-form
                label-position="right"
                label-width="80px"
                ref="addSectionForm"
                :model="addSectionForm">
                <el-form-item label="课程名称" prop="courseName">
                  <el-input v-model="addSectionForm.courseName" disabled></el-input>
                </el-form-item>
                <el-form-item label="章节名称" prop="name">
                  <el-input v-model="addSectionForm.name"></el-input>
                </el-form-item>
                <el-form-item label="章节排序" prop="orderNumber">
                  <el-input type="number" v-model="addSectionForm.orderNumber">
                    <template slot="append">数字控制排序，数字越大越靠后</template>
                  </el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click=" dialogVisible= false">取 消</el-button>
                <el-button @click="addChapter">确 定</el-button>
              </span>
            </el-dialog>
          </template>
          <!--   添加子章节的dialog       -->
          <template v-if="this.addOrUp===1">
            <el-dialog class="add-dialog" title="章节信息" :visible.sync="dialogVisible">
              <el-form
                label-position="right"
                label-width="80px"
                ref="addSectionForm"
                :model="addChildrenForm">
                <el-form-item label="课程名称" prop="courseName">
                  <el-input v-model="addChildrenForm.courseName" disabled></el-input>
                </el-form-item>
                <el-form-item label="Parent" prop="parent">
                  <el-input v-model="addChildrenForm.parent" disabled></el-input>
                </el-form-item>
                <el-form-item label="章节名称" prop="name">
                  <el-input v-model="addChildrenForm.name"></el-input>
                </el-form-item>
                <el-form-item label="课时排序" prop="orderNumber">
                  <el-input type="number" v-model="addChildrenForm.orderNumber">
                    <template slot="append">数字控制排序，数字越大越靠后</template>
                  </el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click=" dialogVisible= false">取 消</el-button>
                <el-button @click="addChildrenChapter">确 定</el-button>
              </span>
            </el-dialog>
          </template>
          <!--   编辑章节信息的dialog       -->
          <template v-if="this.addOrUp===2">
            <el-dialog class="add-dialog" title="章节信息" :visible.sync="dialogVisible">
              <el-form
                label-position="right"
                label-width="80px"
                ref="addSectionForm"
                :model="checkChapter">
                <el-form-item label="章节编号" prop="courseName">
                  <el-input v-model="checkChapter.id" disabled></el-input>
                </el-form-item>
                <el-form-item label="检查点" prop="checkType">
                  <el-select v-model="checkChapter.chapterInfo.checkType" placeholder="请选择">
                    <el-option value="quiz">quiz</el-option>
                    <el-option value="video">video</el-option>
                    <el-option value="html">html</el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click=" dialogVisible= false">取 消</el-button>
                <el-button @click="checkChapterPoint">确 定</el-button>
              </span>
            </el-dialog>
          </template>
          <!--    修改课程图片资源      -->
          <!--   编辑章节信息的dialog       -->
          <template v-if="this.addOrUp===3">
            <el-dialog class="add-dialog" title="章节信息" :visible.sync="dialogVisible">
              <el-form label-width="80px" :model="addChapterQuiz">
                <el-form-item label="章节编号">
                  <el-input v-model="addChapterQuiz.chapterId" disabled></el-input>
                </el-form-item>
              </el-form>
              <el-button @click="addChapterQuizData" round style="float: right;margin-top: -20px">Quiz</el-button>
              <span slot="footer" class="dialog-footer">
                <el-button @click=" dialogVisible= false">取 消</el-button>
                <el-button @click="toCreateQuestion">确 定</el-button>
              </span>
            </el-dialog>
          </template>
          <!--     修改课程图片的dialog     -->
          <template v-if="addOrEdit===1">
            <el-dialog
              title="提示"
              :visible.sync="visible"
              width="40%">
              <el-card shadow="never" style="padding: 10px 0">
                <input type="file" class="fileExport" @change="handleFileChange" ref="inputer1">
                <el-row>
                  <el-button @click="reload" round style="margin-left: 350px">刷新</el-button>
                  <el-button id="submitButton" style="float: right" @click="putObj1()" round>提交</el-button>
                  <!--          <a id="buttonTag" class="center-to-head submit" @click="putObj()">提交</a>-->
                  <el-row>
                    <el-progress id="uploadPercentage1" v-show="loadingPercentage!==0"
                                 :percentage="loadingPercentage"
                                 style="bottom: -20px;position: relative;width: 0" :show-text="false"></el-progress>
                  </el-row>
                </el-row>
              </el-card>
              <el-dialog
                width="80%"
                title="上传资源"
                :visible.sync="innerVisible"
                append-to-body>
                <el-form label-width="80px" :model="addResourceData">
                  <el-form-item label="课程编号">
                    <el-input v-model="addResourceData.courseId" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="课程链接">
                    <el-input v-model="addResourceData.bucket" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="课程路径">
                    <el-input v-model="addResourceData.path" disabled></el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisible= false">取 消</el-button>
                <el-button type="primary" @click="changeCourseImage">确 定</el-button>
             </span>
              </el-dialog>
              <span slot="footer" class="dialog-footer">
                <el-button @click="visible=false">取 消</el-button>
                <el-button type="primary" @click="innerVisible=true">确 定</el-button>
             </span>
            </el-dialog>
          </template>
          <!--     修改章节视频资源     -->
          <template v-if="addOrEdit===2">
            <el-dialog
              title="提示"
              :visible.sync="visible"
              width="40%">
              <el-card shadow="never" style="padding: 10px 0">
                <input type="file" class="fileExport" @change="handleFileChange" ref="inputer1">
                <el-row>
                  <el-button @click="reload" round style="margin-left: 350px">刷新</el-button>
                  <el-button id="submitButton1" style="float: right" @click="putObj()" round>提交</el-button>
                  <!--          <a id="buttonTag" class="center-to-head submit" @click="putObj()">提交</a>-->
                  <el-row>
                    <el-progress id="uploadPercentage" v-show="loadingPercentage!==0"
                                 :percentage="loadingPercentage"
                                 style="bottom: -20px;position: relative;width: 0" :show-text="false"></el-progress>
                  </el-row>
                </el-row>
              </el-card>
              <el-dialog
                width="80%"
                title="上传资源"
                :visible.sync="innerVisible"
                append-to-body>
                <el-form label-width="80px" :model="addChapterData">
                  <el-form-item label="章节编号">
                    <el-input v-model="addChapterData.chapterId" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="章节链接">
                    <el-input v-model="addChapterData.bucket" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="章节路径">
                    <el-input v-model="addChapterData.path" disabled></el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisible= false">取 消</el-button>
                <el-button type="primary" @click="changeChapterVideo">确 定</el-button>
             </span>
              </el-dialog>
              <span slot="footer" class="dialog-footer">
                <el-button @click="visible=false">取 消</el-button>
                <el-button type="primary" @click="innerVisible=true">确 定</el-button>
             </span>
            </el-dialog>
          </template>
          <!--  ==============================  -->
        </el-tabs>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import cos from '@/main'

let id = 1000
export default {
  name: 'BasicInfo',
  components: {
    Header
  },
  data () {
    const addResourceData = {
      courseId: 0,
      bucket: '',
      path: ''
    }
    const addChapterData = {
      chapterId: 0,
      bucket: '',
      path: ''
    }
    const checkChapter = {
      id: 0,
      chapterInfo: {
        checkType: ''
      }
    }
    const data = [{
      id: 0,
      label: '',
      children: []
    }]
    return {
      loadingPercentage: 0,
      addChapterQuiz: {
        chapterId: 0
      },
      teacherId: 0,
      addResourceData: JSON.parse(JSON.stringify(addResourceData)),
      checkChapter: JSON.parse(JSON.stringify(checkChapter)),
      addChapterData: JSON.parse(JSON.stringify(addChapterData)),   //添加章节视频资源的数据
      visible: false,
      innerVisible: false,   //上传内部资源开启的表单
      addOrEdit: 0,    //0 表示编辑资源数据对话框， 1表示上传资源的对话框
      termData: [],
      addOrUp: 0,   //为0时，为添加章节页面，为1时，表示添加子章节（课时），为2时，表示修改、编辑章节信息
      addSectionForm: [{
        courseName: '',
        name: '',
        orderNumber: 0
      }],
      addChildrenForm: [{
        courseName: '',
        name: '',
        parent: 0,
        orderNumber: 0
      }],
      treeProps: {
        label: 'name',
        children: 'children'
      },
      data: JSON.parse(JSON.stringify(data)),
      courseId: 0,
      course: {},
      activeNames: ['1'],
      dialogVisible: false,
      hideUploadAdd: false,
      course_practice: '',
      fileList: [],
      file: {},
      ruleForm: {},
      rules: {
        name: [{
          required: true,
          message: '请输入课程名称',
          trigger: 'blur'
        },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur'
          }
        ],
        gmtCreate: [{
          type: 'date',
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }],
        gmtModified: [{
          type: 'date',
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '是否共享',
          trigger: 'change'
        }]
      }
    }
  },
  created () {
    this.courseId = this.$route.query.courseId
    this.checkLogin()
    this.loadCourseData()
    this.loadChapterData()
    this.loadTermDataByCourseId()
    this.loadTempFile()
  },
  methods: {
    /*checkLogin*/
    checkLogin () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
      })
        .then(response => {
          if (response.data.status) {
            console.log('login:', response.data)
            this.teacher = response.data.data
            this.teacherId = response.data.data.id
            const json = JSON.stringify(this.teacher)
            this.loginInfo = JSON.parse(json)
          } else {
            this.$message.error(response.data.message)
          }
          const email = response.data.data.email
          if (email.substring(email.length - 7) === '@qq.com') {
            const qqId = email.substring(0, email.indexOf('@'))
            this.teacher.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
          }
        })
    },
    //跳转到修改图片的功能
    goChangeUrl () {
      this.visible = true
      this.addOrEdit = 1
    },
    //修改课程信息
    //复制课程的详情
    changeCourseSketch () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/courseInfo/changeSketch',
        params: {
          courseId: this.courseId,
          sketch: this.course.courseInfo.sketch
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            //this.changeCourseInfoImg()
            this.changeCourseStatus()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    changeCourseStatus () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/changeStatus',
        params: {
          id: this.courseId,
          status: this.course.status
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.loadCourseData()
            this.goCourse()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*跳转到课程页面*/
    goCourse () {
      this.$router.push({ path: '/teachercourse' })
    },
    //根据复制复刻该课程的image
    changeCourseImage () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/courseInfo/changeImage',
        params: {
          courseId: this.addResourceData.courseId,
          bucket: this.addResourceData.bucket,
          path: this.addResourceData.path
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadCourseData()
            this.visible = false
            this.innerVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //添加父章节
    addParent () {
      this.dialogVisible = true
      this.addOrUp = 0
    },
    addChapter () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/createChapter',
        params: {
          courseId: this.courseId,
          name: this.addSectionForm.name,
          orderNumber: this.addSectionForm.orderNumber
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadChapterData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //添加子章节
    addChildrenChapter () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/createChapter',
        params: {
          courseId: this.courseId,
          parent: this.addChildrenForm.parent,
          name: this.addChildrenForm.name,
          orderNumber: this.addChildrenForm.orderNumber
        }
      })
        .then((res) => {
          if (res.data.message) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadChapterData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //改变单个章节的检查点
    checkChapterPoint () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/changeModeToCheckpoint',
        params: {
          id: this.checkChapter.id,
          checkType: this.checkChapter.chapterInfo.checkType
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadChapterData()
            this.dialogVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    append (data, node) {
      const parent = node.parent
      this.dialogVisible = true
      this.addOrUp = 1
      this.addChildrenForm.parent = node.key
      /*  const newChild = {
          id: id++,
          name: 'yf',
          children: []
        }
        if (!data.children) {
          this.$set(data, 'children', [])
        }
        data.children.push(newChild)*/
    },
    //删掉子节点
    remove (node, data) {
      const chapterId = data.id
      const orderNumber = data.orderNumber
      /*      const parent = node.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)*/
      // children.splice(index, 1)
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/removeChapterById',
        params: {
          id: data.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadChapterData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //改变章节详情内容为视频
    changeChapterVideo () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapterInfo/changeContentTypeToVideo',
        params: {
          chapterId: this.addChapterData.chapterId,
          bucket: this.addChapterData.bucket,
          path: this.addChapterData.path
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadChapterData()
            this.innerVisible = false
            this.dialogVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //更新子节点的方法
    updateKeyChildren (node, data) {
      this.dialogVisible = true
      this.addOrUp = 2
      this.checkChapter.id = data.id
    },
    updateKey (node, data) {
      this.visible = true
      this.addOrEdit = 2
      this.addChapterData.chapterId = data.id
    },
    //添加章节测试资源
    addQuiz (node, data) {
      this.dialogVisible = true
      this.addOrUp = 3
      this.addChapterQuiz.chapterId = data.id
    },
    //改变章节详情为测试quiz
    addChapterQuizData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapterInfo/changeContentTypeToQuiz',
        params: {
          chapterId: this.addChapterQuiz.chapterId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadChapterData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到创建的页面
    toCreateQuestion () {
      this.$router.push({ path: '/createquestion?chapterId=' + this.addChapterQuiz.chapterId })
    },
    //加载临时数据
    loadTempFile () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/tempFile/showMyTempFiles'
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    // 上传资源到cos
    putObj () {
      const date = new Date()
      const hour = date.getHours()
      let bucket
      if (hour < 8) {
        bucket = 'a'
      } else if (hour < 16) {
        bucket = 'b'
      } else {
        bucket = 'c'
      }
      const fileName = this.file.name
      const keyID = 'teacher/' + this.teacherId + '/' + fileName
      // student/100001/abc.txt
      console.log(keyID)
      //定义变量
      this.addResourceData.path = keyID
      this.addResourceData.bucket = bucket.toUpperCase()
      this.addChapterData.path = keyID
      this.addChapterData.bucket = bucket.toUpperCase()
      cos.putObject({
        Bucket: 'mooc-temp-' + bucket + '-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: this.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
          const percentage = document.getElementById('uploadPercentage')
          const submitButton = document.getElementById('submitButton')
          percentage.style.width = progressData.percent * 100 + '%'
          if (progressData.percent === 1) {
            percentage.style.width = '0%'
           // this.$message.success('临时文件上传成功!')
            alert('临时文件上传成功')
            submitButton.disabled = 'false'
            this.reload()
          } else {
            submitButton.disabled = 'disabled'
          }
        }
      }, function (err, data) {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          console.log('bucket:' + bucket + 'keyID:' + keyID)
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/tempFile/checkUploadedFile',
            params: {
              bucket: bucket.toUpperCase(),
              path: keyID
            }
          }).then((res) => {
            if (res.data.status) {
              // this.path = keyID
              //  console.log('离谱的数据:', this.addResourceData.path + this.addResourceData.bucket)
              this.$message.success(res.data.message)
            }
          })
        }
      })
    },
    putObj1 () {
      const date = new Date()
      const hour = date.getHours()
      let bucket
      if (hour < 8) {
        bucket = 'a'
      } else if (hour < 16) {
        bucket = 'b'
      } else {
        bucket = 'c'
      }
      const fileName = this.file.name
      const keyID = 'teacher/' + this.teacherId + '/' + fileName
      // student/100001/abc.txt
      console.log(keyID)
      //定义变量
      this.addResourceData.path = keyID
      this.addResourceData.bucket = bucket.toUpperCase()
      this.addChapterData.path = keyID
      this.addChapterData.bucket = bucket.toUpperCase()
      cos.putObject({
        Bucket: 'mooc-temp-' + bucket + '-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: this.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
          const percentage1 = document.getElementById('uploadPercentage1')
          const submitButton = document.getElementById('submitButton')
          percentage1.style.width = progressData.percent * 100 + '%'
          if (progressData.percent === 1) {
            percentage1.style.width = '0%'
            alert('临时文件上传成功')
            submitButton.disabled = 'false'
            this.reload()
          } else {
            submitButton.disabled = 'disabled'
          }
        }
      }, function (err, data) {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          console.log('bucket:' + bucket + 'keyID:' + keyID)
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/tempFile/checkUploadedFile',
            params: {
              bucket: bucket.toUpperCase(),
              path: keyID
            }
          }).then((res) => {
            if (res.data.status) {
              // this.path = keyID
              //  console.log('离谱的数据:', this.addResourceData.path + this.addResourceData.bucket)
              this.$message.success(res.data.message)
            }
          })
        }
      })
    },
    /*   changeOrderNum (id,orderNumber) {
         if(orderNumber){

         }
         axios({
           method: 'POST',
           url: 'https://mooc.kirinnorine.top/chapter/changePosition',
           params: {
             id: id,
             orderNumber:
           }
         })
       },*/
    renderContent (h, {
      node,
      data,
      store
    }) {
      return (
        <span class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button type="text" size="mini" on-click={() => this.updateKeyChildren(key, data)}>Edit</el-button>
              <el-button size="mini" type="text" on-click={() => this.append(data)}>Append</el-button>
              <el-button size="mini" type="text" on-click={() => this.remove(node, data)}>Delete</el-button>
            </span>
          </span>)
    },
    //改变
    //加载当个课程所有的章节
    loadChapterData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/showCascadedChaptersByCourseIdOrderByOrderNumber',
        params: {
          courseId: this.courseId
        }
      })
        .then((res) => {
          const datas = res.data.data
          if (res.data.status) {
            this.data = datas
            this.data.label = datas.name
            //this.data.id = res.data.data.id
            // this.data.label = res.data.data.name
            //this.data.children = res.data.data.children
            console.log('章节名称数据为:', this.data)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //加载课程数据
    loadCourseData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCourseById',
        params: {
          id: this.courseId
        }
      })
        .then((res) => {
          if (res.data.status) {
            // this.$message.success(res.data.message)
            this.course = res.data.data
            //  console.log('课程数据为:', res.data.data)
            this.addSectionForm.courseName = res.data.data.name
            this.addChildrenForm.courseName = res.data.data.name
            this.addResourceData.courseId = this.courseId
            this.ruleForm = res.data.data.name
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //加载课程的开始和结束时间
    loadTermDataByCourseId () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermsByCourseId',
        params: {
          courseId: this.courseId,
          currentPage: 1,
          pageSize: 100
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.termData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    // 上传change事件
    handleAddChange (file, fileList) {
      this.beforeAvatarUpload(fileList[0].raw)
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length === 1) {
        this.hideUploadAdd = true
      }
    },
    handleFileChange (e) {
      const inputDOM = this.$refs.inputer1
      this.file = inputDOM.files[0]
      this.formData = new FormData()
      this.formData.append('file', this.file)
    },
    reload () {
      this.isComponentAlive = false
      this.$nextTick(function () {
        this.isComponentAlive = true
      })
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG，PNG,JPEG 格式!')
      }
      console.log(isJPG)
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      const _this = this
      const isSize = new Promise(function (resolve, reject) {
        const img = new Image()
        const _URL = window.URL
        img.onload = function () {
          file.width = img.width // 图片宽度
          file.height = img.height // 图片高度
          const valid = img.width / img.height === 3 / 2 // 上传图片尺寸判定
          valid ? resolve() : reject(new Error('error'))
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          _this.$message.error('上传图片尺寸比例必须为3：2')
          return Promise.reject(new Error('error'))
        }
      )
      return isJPG && isLt2M && isSize
    },
    handleAddRemove (file, fileList) {
      this.hideUploadAdd = false
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    handleChange (val) {
      console.log(val)
    },
    returnData () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.row-bg {
  padding: 10px 0;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
