<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="container">
      <el-card :body-style="{ padding: '40px' }">
        <!--      修改基本信息-->
        <el-row class="outer">
          基本信息
          <el-button type="text" class="el-icon-edit-outline" @click="dialogVisible=true"></el-button>
          <!--     修改教师基本信息的dialog     -->
          <el-dialog title="修改基本信息" :visible="dialogVisible" width="50%" center>
            <el-form ref="form" :model="teacher" label-width="80px">
              <el-form-item label="工号">
                <el-input v-model="teacher.id"></el-input>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="teacher.name"></el-input>
              </el-form-item>
              <el-form-item label="出生日期">
                <el-date-picker
                  v-model="teacher.gmtCreate"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="teacher.gender">
                  <el-radio label="male"><i class="el-icon-male"/>男</el-radio>
                  <el-radio label="female"><i class="el-icon-female"/>女</el-radio>
                  <el-radio label="unknown">保密</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="学科">
                <el-input v-model="teacher.subject"></el-input>
              </el-form-item>
              <el-form-item label="教师职称">
                <el-input v-model="teacher.authorization"></el-input>
              </el-form-item>
              <el-form-item label="座右铭">
                <el-input v-model="teacher.motto"></el-input>
              </el-form-item>
              <el-form-item label="组织机构">
                <el-input v-model="teacher.school"></el-input>
              </el-form-item>
              <el-form-item label="个人简介" prop="decription">
                <el-input v-model="teacher.description"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="editInformation">确 定</el-button>
            </span>
          </el-dialog>
        </el-row>
        <!--   基本信息-->
        <el-row>
          <el-col :span="18">
            <div class="basicinfo" v-if="reloadDrawer">
              <div>
                <el-col :span="6"><span class="fweight">姓名:{{ loginInfo.name }}</span></el-col>
                <el-col :span="4">
                  <p v-show="loginInfo.gender!=='unknown'"><i class="el-icon-s-custom"></i>性别:
                    <i v-if="loginInfo.gender==='male'" class="el-icon-male">男</i>
                    <i v-else-if="loginInfo.gender==='female'" class="el-icon-female">女</i>
                  </p></el-col>
                <el-col :span="8"><span class="fweight">出生日期:{{ loginInfo.gmtCreate }}</span></el-col>
              </div>
              <div>
                <el-col :span="8"><span class="fweight">学科:{{ loginInfo.subject }}</span></el-col>
                <el-col :span="10"><span class="fweight">工号:{{ loginInfo.id }}</span></el-col>
              </div>
              <div>
                <el-col :span="10"><span class="fweight">教师职称:{{ loginInfo.authorization }}</span></el-col>
                <el-col :span="8"><span class="fweight">座右铭:{{ loginInfo.motto }}</span></el-col>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <el-upload action=" " list-type="picture-card" multiple
                       :limit="1" :before-upload="beforeAvatarUpload"
                       :on-change="handleAddChange" :on-remove="handleAddRemove" :class="{'hide':hideUploadAdd}"
                       :auto-upload="false">
              <i class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">1.支持jpg，jpeg，png图片格式</div>
              <div class="el-upload__tip" slot="tip">2.图片大小不超过5M</div>
              <div class="el-upload__tip" slot="tip">3.上传图片尺寸比例必须为1:1）</div>
            </el-upload>
          </el-col>
        </el-row>
        <el-divider content-position="right"></el-divider>
        <!--组织机构-->
        <el-row class="outer">
          组织机构
        </el-row>
        <el-row>
          <el-col>
            <span class="fweight">所属组织:{{ loginInfo.school }}</span>
          </el-col>
        </el-row>
        <el-divider content-position="right"></el-divider>
        <!--        联系方式-->
        <el-row class="outer">
          联系方式
          <el-button type="text" class="el-icon-edit-outline" @click="dialogVisible=true;dialogSwitch=2"></el-button>
        </el-row>
        <el-row>
          <el-col :span="12"><span class="fweight">移动电话:{{ loginInfo.tel }}</span></el-col>
          <el-col :span="12"><span class="fweight">电子邮件:{{ loginInfo.email }}</span></el-col>
        </el-row>
        <el-divider content-position="right"></el-divider>
        <!--     个人简介-->
        <el-row class="outer">
          个人简介
          <i class="el-icon-edit-outline"></i>
        </el-row>
        <el-row v-show="loginInfo.description!=null">
          {{ loginInfo.description }}
        </el-row>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'

export default {
  name: 'TeacherInfo',
  components: {
    Header
  },
  data: function () {
    return {
      reloadDrawer: true,  //组件局部刷新
      hideUploadAdd: false,
      teacher: {},
      dialogVisible: false,
      dialogSwitch: 0,
      radio: 1,
      loginInfo: {}
    }
  },
  created () {
    this.checkLogin()
  },
  methods: {
    checkLogin () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
      })
        .then(response => {
          if (response.data.status) {
            console.log('login:', response.data)
            this.teacher = response.data.data
            const json = JSON.stringify(this.teacher)
            this.loginInfo = JSON.parse(json)
          } else {
            this.$message.error(response.data.message)
          }
          const email = response.data.data.email
          if (email.substring(email.length - 7) === '@qq.com') {
            const qqId = email.substring(0, email.indexOf('@'))
            this.teacher.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
          }
        })
    },
    editInformation () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/teacher/changeInformation',
        params: {
          id: this.teacher.id,
          name: this.teacher.name,
          gender: this.teacher.gender,
          subject: this.teacher.subject,
          authorization: this.teacher.authorization,
          motto: this.teacher.motto,
          school: this.teacher.school,
          tel: this.teacher.tel,
          email: this.teacher.email
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message)
            // console.log('修改的数据')
            //  this.checkLogin()
            this.dialogVisible = false
            this.reload()
          } else {
            this.$message.error(response.data.message)
          }
        })
    },
    //局部刷新
    reload () {
      this.reloadDrawer = false
      this.$nextTick(function () {
        this.reloadDrawer = true
      })
    },
    // 上传change事件
    handleAddChange (file, fileList) {
      this.beforeAvatarUpload(fileList[0].raw)
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length === 1) {
        this.hideUploadAdd = true
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG，PNG,JPEG 格式!')
      }
      console.log(isJPG)
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      const _this = this
      const isSize = new Promise(function (resolve, reject) {
        const img = new Image()
        const _URL = window.URL
        img.onload = function () {
          file.width = img.width // 图片宽度
          file.height = img.height // 图片高度
          const valid = img.width / img.height === 1 / 1 // 上传图片尺寸判定
          valid ? resolve() : reject(new Error('error'))
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          _this.$message.error('上传图片尺寸比例必须为1:1')
          return Promise.reject(new Error('error'))
        }
      )
      return isJPG && isLt2M && isSize
    },
    handleAddRemove (file, fileList) {
      this.hideUploadAdd = false
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    handleChange (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped>
.hide .el-upload--picture-card {
  display: none !important;
}

.el-icon-edit-outline {
  font-size: 25px;
  color: #000;
  outline: none
}

.fweight {
  font-weight: 400;
}

.outer {
  font-size: 25px;
  font-weight: 400
}

.basicinfo > div {
  padding-bottom: 80px
}

.basicinfo > div:first-child {
  margin-top: 7px;
}


</style>
