<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../assets/img/teachercoursecenter.png">
      </div>
      <!-- 分类-->
      <el-row style="margin-top:20px">
        <el-col :span="20" :offset="2">
          <el-card>
            <span style="margin-right:10px">分类为:</span>
            <el-button round @click="loadCourseData">全部</el-button>
            <el-button round>公开课程</el-button>
            <el-button round>私有课程</el-button>
            <el-input placeholder="请输入内容" style="width:200px;margin-left:200px" size="medium" v-model="courseData.name"
                      clearable></el-input>
            <el-button @click="queryByName" type="primary" style="margin-left: 10px">查询</el-button>
          </el-card>
        </el-col>
      </el-row>
      <!-- 内容-->
      <el-row>
        <el-col :span="20" :offset="2">
          <div class="box-card" style="margin-top: 10px;margin-left: 20px">
            <el-row>
              <el-col :span="6" :sm="12" :md="8" v-for="course in courseData" :key="course"
                      style="margin-top:15px;display: flex;">
                <CourseCenter :course="course"></CourseCenter>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top:50px">
        <el-col :span="12" :offset="6">
          <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="loadCourseData"
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
          </el-pagination>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import CourseCenter from '@/components/CourseCenter'

export default {
  name: 'TeacherCourseCenter',
  components: {
    Header,
    CourseCenter
  },
  data () {
    return {
      //实现分页
      pageSizes: [6, 12, 18, 24],
      currentPage: 1,
      pageSize: 6,
      total: 1,
      courseData: [],
      radio: 1,
      searchInfo: '',
      teacher: {
        id: '',
        email: '',
        gender: '',
        motto: '',
        school: '',
        sid: '',
        tel: ''
      },
    }
  },
  created () {
    this.loadCourseData()
  },
  methods: {
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCourseData()
    },
    loadCourseData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showMyCourses',
        params: {
          currentPage: this.currentPage,
          pageSize: 6
        }
      })
        .then((res) => {
          // console.log(this.teacher.id)
          if (res.data.status) {
            this.courseData = res.data.data.content
            this.total = res.data.data.totalCount
            // console.log('黄老师所教的课程为:', this.courseData)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    queryByName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCoursesByLikeName',
        params: {
          likeName: this.courseData.name,
          currentPage: this.currentPage,
          pageSize: 6
        }
      })
        .then((res) => {
          //  console.log('queryByLikeName', res.data.data)
          this.courseData = res.data.data.content
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.box-card img {
  margin: 0;
  object-fit: cover;
  height: 200px;
  width: 100%;
}
</style>
