<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-row style="margin-left: 30px;margin-top: 30px">
        <i class="el-icon-document" style="font-weight: bold;font-size: 30px">{{ this.exam.name }}</i>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-card shadow="always" style="width: 80%;border-radius: 30px;margin-left: 50px;margin-top: 20px">
            <ExamScore></ExamScore>
            <el-row>
              <div id="grade" style="width:360px;height:280px;"></div>
            </el-row>
            <el-row>
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span style="font-weight: bold;font-size: 20px">Statistics Information</span>
                  <el-divider></el-divider>
                  <span>考试人数:{{ this.sum }}</span>
                  <el-divider></el-divider>
                  <span>及格人数:{{ this.pass }}</span>
                  <el-divider></el-divider>
                  <span>不及格人数:{{ this.fail }}</span>
                  <el-divider></el-divider>
                </div>
              </el-card>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="16" style="margin-top: 10px">
          <el-card shadow="always" style="border-radius: 30px;">
            <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Student Information</span>
            <el-table
              :data="examData"
              style="width: 100%">
              <el-table-column
                prop="studentId"
                label="学生编号"
                width="120">
              </el-table-column>
              <el-table-column
                prop="student.name"
                label="学生姓名"
                width="120">
              </el-table-column>
              <el-table-column
                prop="result"
                label="分数"
                sortable
                width="100">
              </el-table-column>
              <el-table-column property="status" label="考试状态" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.status==='checked'">
                    <el-tag type="success">老师检查</el-tag>
                  </template>
                  <template v-if="scope.row.finished===''">
                    <el-tag type="warning">寄</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="startTime"
                label="开始时间"
                width="160">
              </el-table-column>
              <el-table-column
                prop="submitTime"
                label="提交时间"
                width="160">
              </el-table-column>
              <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                  <el-button size="small" round @click="viewStudentScore(scope.row.studentId)">查看</el-button>
                  <el-button size="small" round @click="viewPaper(scope.row.examId,scope.row.studentId)">Paper
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top:20px">
              <el-col :span="12" :offset="2">
                <el-pagination
                  @size-change="handlePageSizeChange"
                  @current-change="loadStudentExam"
                  :current-page.sync="currentPage"
                  :page-sizes="pageSizes"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.examCountData.totalCount">
                </el-pagination>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import ExamScore from '@/components/charts/ExamScore.vue'

export default {
  name: 'showStudentChapter',
  components: {
    TermHeader,
    ExamScore
  },
  created () {
    this.termId = this.$route.query.termId
    this.examId = this.$route.query.examId
    this.showExamName()   /*拿到学生名字信息*/
    this.loadStudentExam()  /*加载学生完成课程的数据*/
    this.loadExamScore()
  },
  data () {
    return {
      /*分页*/
      sum: 0,              //统计总人数
      pass: 0,            //统计考试及格人数
      fail: 0,           //统计考试不及格人数
      examId: 0,
      S: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      examData: [],    //定义考试数据
      termId: 0,
      exam: {},         //获得考试对象
      examStudentName: [],
      examCountData: {}
    }
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadStudentExam()
    },
    /*查看学生的考试成绩*/
    viewStudentScore (id) {
      this.$router.push({
        path: '/studentScore',
        name: 'StudentScore',
        query: {
          termId: this.termId,
          studentId: id
        }
      })
    },
    /*加载学生考试成绩*/
    loadExamScore () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showResultsByExamId',
        params: {
          examId: this.examId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.examNum = countData
            for (let i = 0; i < countData.length; i++) {
              if (countData[i] >= 60.0) {
                this.pass += 1
              } else {
                this.fail += 1
              }
            }
            this.sum = this.pass + this.fail
            for (let j = 0; j < countData.length; j++) {
              if (countData[j] === 0.0) {
                this.E += 1
              } else if (countData[j] <= 59 && countData[j] > 0) {
                this.D += 1
              } else if (countData[j] > 60 && countData[j] <= 70) {
                this.C += 1
              } else if (countData[j] < 85 && countData[j] > 71) {
                this.B += 1
              } else if (countData[j] <= 99 && countData[j] >= 85) {
                this.A += 1
              } else if (countData[j] === 100) {
                this.S += 1
              }
            }
            this.loadGrade()
          }
        })
    },
    /*查看试卷*/
    viewPaper (examId, studentId) {
      this.$router.push({
        path: '/examPaper',
        name: 'ExamPaper',
        query: {
          examId: examId,
          studentId: studentId,
          termId: this.termId
        }
      })
    },
    /*加载学生课程信息*/
    loadStudentExam () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showScoresByExamId',
        params: {
          examId: this.examId,
          currentPage: this.currentPage,
          pageSize: 10,
          setStudent: true
        }
      })
        .then((res) => {
            if (res.data.status) {
              this.examCountData = res.data.data
              const countData = res.data.data.content
              this.examData = countData
              for (let j = 0; j < countData.length; j++) {
                this.examStudentName.push(countData[j].student.name)
              }
            } else {
              this.$message.error(res.data.message)
            }
          }
        )
    },
    loadGrade () {
      // 基于准备好的dom，初始化echarts实例
      const grade = this.$echarts.init(document.getElementById('grade'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生层级图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 5,
          left: 'center',
          data: ['不及格', '及格', '良好', '优秀', '满分大神']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.D,
                name: '不及格'
              },
              {
                value: this.C,
                name: '及格'
              },
              {
                value: this.B,
                name: '良好'
              },
              {
                value: this.A,
                name: '优秀'
              },
              {
                value: this.S,
                name: '满分大神'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      grade.setOption(option)
      window.addEventListener('resize', function () {
        grade.resize()
      })
    }
    ,
    showExamName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showExamById',
        params: {
          id: this.examId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.exam = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.box-card {
  margin-left: 50px;
  width: 250px;
  margin-top: 10px;
  height: 350px;
  border-radius: 20px;
  mso-border-shadow: yes;
}


</style>

