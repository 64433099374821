<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-row>
        <el-col :span="10">
          <el-card shadow="always" style="border-radius: 30px;margin-left: 0px;margin-top: 20px">
            <FinalScore></FinalScore>
          </el-card>
        </el-col>
        <el-col :span="14">
          <el-card shadow="always" style="border-radius: 30px;margin-left: 20px;margin-top: 20px">
            <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Certificate Information</span>
            <el-table
              :data="certificateData"
              @sort-change="loadCertificateDataByOrder"
              style="width: 100%">
              <el-table-column
                prop="studentId"
                label="学生编号"
                width="120">
              </el-table-column>
              <el-table-column
                prop="student.name"
                label="学生姓名"
                width="120">
              </el-table-column>
              <el-table-column
                prop="score"
                label="分数"
                sortable="custom"
                width="100">
                <template slot-scope="scope">
                  {{ scope.row.score.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column property="status" label="考试状态" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.status==='pass'">
                    <el-tag type="success">通过</el-tag>
                  </template>
                  <template v-if="scope.row.status==='fail'">
                    <el-tag type="danger">挂科</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="awardTime"
                label="发放证书时间"
                width="200">
              </el-table-column>
              <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                  <el-button size="small" round @click="downLoadCertificate(scope.$index, scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top:20px">
              <el-col :span="12" :offset="2">
                <el-pagination
                  @size-change="handlePageSizeChange"
                  @current-change="loadCertificateData"
                  :current-page.sync="currentPage"
                  :page-sizes="pageSizes"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.counterData.totalCount">
                </el-pagination>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <!--   开启证书下载页面   -->
      <el-dialog
        title="证书预览和下载"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
        <CreateCertificateTest :certificate="certificate"></CreateCertificateTest>
      </el-dialog>
      <!--   结束证书下载页面   -->
    </el-main>
  </el-container>
</template>
<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import FinalScore from '@/components/charts/FinalScore'
import CreateCertificate from '@/components/part/CreateCertificate'
import CreateCertificateTest from '@/components/part/CreateCertificateTest'

export default {
  name: 'CertificateScore',
  components: {
    CreateCertificate,
    TermHeader,
    FinalScore,
    CreateCertificateTest
  },
  data () {
    return {
      normalScore: [],/*除考试成绩，其他成绩的综合评分*/
      dialogVisible: false,/*是否开启表单*/
      counterData: {},
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      certificateData: [],
      sortProp: '',  // 新增
      sortOrder: '',  // 新增
      certificate: {},   /*证书数据*/
      certificateId: 0,   /*获得证书Id*/
      scoreData: []
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //this.loadCertificateData()
    //  this.loadCertificateDataByOrder()
    this.loadCertificateDataByOrderDesc()
    /*获得证书数据*/

  },
  methods: {
    /*关闭表单*/
    handleClose () {
      this.dialogVisible = false
    },
    /*排序*/
    sortChange (column) {   // 新增
      this.sortProp = column.prop
      this.sortOrder = column.order
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCertificateData()
    },
    loadCertificateData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/certificate/showCertificatesAwardedByMeByTermId',
        params: {
          termId: this.termId,
          withDetails: true,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.counterData = res.data.data
            this.certificateData = countData
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadCertificateDataByOrder () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/certificate/showCertificatesAwardedByMeByTermIdOrderByScore',
        params: {
          termId: this.termId,
          withDetails: true,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.counterData = res.data.data
            this.certificateData = countData
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadCertificateDataByOrderDesc () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/certificate/showCertificatesAwardedByMeByTermIdOrderByScoreDesc',
        params: {
          termId: this.termId,
          withDetails: true,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.counterData = res.data.data
            this.certificateData = countData
            /* this.loadScoreGroup()   /!*加载分数数据*!/*/
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*下载证书的axios*/
    loadCertificateDataAndDownload () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/certificate/showCertificateById',
        params: {
          id: this.certificateId,
          withDetails: true,
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.certificate = countData
            let date = new Date(this.certificate.awardTime)
            
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*下载证书*/
    downLoadCertificate (index, rows) {
      this.certificateId = rows.id
      if (rows.score >= 60.00) {
        this.loadCertificateDataAndDownload()
        this.dialogVisible = true
      } else {
        this.$message.error('课程成绩未达到及格线，无法获取证书!')
      }
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
</style>
