<template>
  <div class="border-bottom" style="background-color: #ffffff">
    <div class="container">
      <div class="row header">
        <div class="logo_img" @click="goHome()">
          <img src="https://mooc-webfront-1303967055.cos.ap-shanghai.myqcloud.com/moocImg/logo.png" alt=""
               style="cursor: pointer">
        </div>
        <el-menu mode="horizontal"
                 class="headerMenu" default-active="1">
          <el-menu-item v-for="item in navItems" :index="item.index" :key="item.url">
            <el-link @click="backurl(item.url)" style="margin-top: 10px">{{ item.tab }}</el-link>
          </el-menu-item>
          <el-menu-item>
            <el-input
              class="hidden-sm-and-down"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="searchContent">
            </el-input>
          </el-menu-item>
          <el-menu-item>
            <div v-if="!(user.username==='')">
              <el-avatar :src="user.avatarUrl" style="margin-right: 5px"></el-avatar>
              <el-link @click="goTeacherPage"> {{ user.username }}</el-link>
              <el-link style="margin-left: 20px" @click="logout">Logout</el-link>
            </div>
            <div v-else>
              <el-link @click="goLogin">登录</el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link @click="goRegister">注册</el-link>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Header',
  data () {
    return {
      user: {
        username: '',
        avatarUrl: ''
      },
      searchContent: '',
      navItems: [
        {
          index: 1,
          tab: '课程中心',
          url: 'teachercoursecenter'
        },
        {
          index: 2,
          tab: '我的课程',
          url: 'teachercourse'
        },
        {
          index: 3,
          tab: '我的学期',
          url: 'teacherclass'
        },
        {
          index: 4,
          tab: '学生管理',
          url: 'studentmanage'
        },
        {
          index: 5,
          tab: '考试手册',
          url: 'teachermanual'
        }
      ]
    }
  },
  methods: {
    goTeacherPage () {
      this.$router.push({ path: '/teacherinfo' })
    },
    goLogin () {
      this.$router.push({ path: '/login' })
    },
    goRegister () {
      this.$router.push({ path: '/register' })
    },
    backurl (value) {
      this.$emit('sendurl', value)
      this.$router.push({
        path: value
      })
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    logout () {
      if (confirm('确定要退出登录吗?')) {
        axios({
          method: 'GET',
          url: 'https://mooc.kirinnorine.top/teacher/logout'
        }).then(res => {
          if (res.status) {
            this.$message.success('登出成功')
            this.user.username = ''
            this.$router.push({ path: '/' })
          }
        })
      }
    }
  },
  created () {
    axios({
      methods: 'GET',
      url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
    }).then(response => {
      // console.log('login:', response.data)
      this.user.username = response.data.data.name
      const email = response.data.data.email
      if (email.substring(email.length - 7) === '@qq.com') {
        const qqId = email.substring(0, email.indexOf('@'))
        this.user.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
      }
    })
  },
  handleSelect (key, keyPath) {
    console.log(key, keyPath)
  }
}
</script>

<style scoped>
.logo_img {
}

.logo_img img {
  width: 150px;
  margin-left: -140px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.headerMenu {
  border: 0 !important;
}

.header {
  justify-content: space-between;
}
</style>
