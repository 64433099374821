import { render, staticRenderFns } from "./TeacherCourseCenter.vue?vue&type=template&id=2b9aadce&scoped=true&"
import script from "./TeacherCourseCenter.vue?vue&type=script&lang=js&"
export * from "./TeacherCourseCenter.vue?vue&type=script&lang=js&"
import style0 from "./TeacherCourseCenter.vue?vue&type=style&index=0&id=2b9aadce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9aadce",
  null
  
)

export default component.exports