<template>
  <div class="viewed-courses">
    <div class="container">
      <el-row>
        <el-col :span="5" v-for="(o, index) in 4" :key="o" :offset="index > 0 ? 1 : 0">
          <el-card :body-style="{ padding: '0px' }">
            <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
            <div style="padding: 14px;">
              <span>好吃的汉堡</span>
              <div class="bottom clearfix">
                <time class="time">{{ currentDate }}</time>
                <el-button type="text" class="button">操作按钮</el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="row">
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
        <CourseBox></CourseBox>
      </div>
    </div>
  </div>
</template>

<script>
import CourseBox from '@/components/CourseBox'

export default {
  name: 'CourseSection',
  components: { CourseBox }
}
</script>

<style scoped>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
