<template>
  <div id="activeRanking"></div>
</template>

<script>
export default {
  name: 'ActivityRanking',
  mounted () {
    // 在mounted生命周期函数中实例化echarts对象。因为要确保dom元素已经挂载到页面中
    this.myRanking()
  },
  methods: {
    myRanking () {
      // 基于准备好的dom，初始化echarts实例
      const activeRanking = this.$echarts.init(document.getElementById('activeRanking'))
      var years = ['2019', '2020', '2021']
      var stuData = [
        ['颜芬', '蹇滔', '黄照坤', '黄宇玮', '张璐瑶'],
        ['颜芬', '蹇滔', '黄照坤', '黄宇玮', '张璐瑶'],
        ['颜芬', '蹇滔', '黄照坤', '黄宇玮', '张璐瑶']
      ]
      var data = [
        [88.0, 86.1, 85.3, 84.9, 82],
        [92.0, 96.1, 89.3, 88.9, 87],
        [98.0, 96.1, 98.3, 90.9, 92]
      ]
      var option = {
        baseOption: {
          backgroundColor: 'white', // 背景颜色
          timeline: {
            data: years,
            axisType: 'category',
            autoPlay: true,
            playInterval: 1500, // 播放速度
            left: '5%',
            right: '5%',
            bottom: '0%',
            width: '90%', // height: null,
            label: {
              normal: {
                textStyle: {
                  color: 'red'
                }
              },
              emphasis: {
                textStyle: {
                  color: 'red'
                }
              }
            },
            symbolSize: 10,
            lineStyle: {
              color: '#red'
            },
            checkpointStyle: {
              borderColor: '#red',
              borderWidth: 2
            },
            controlStyle: {
              showNextBtn: true,
              showPrevBtn: true,
              normal: {
                color: '#ff8800',
                borderColor: '#ff8800'
              },
              emphasis: {
                color: 'red',
                borderColor: 'red'
              }
            }
          },
          title: {
            text: '',
            right: '2%',
            bottom: '8%',
            textStyle: {
              fontSize: 50,
              color: 'black'// 标题字体颜色
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          calculable: true,
          grid: {
            left: '8%',
            right: '2%',
            bottom: '6%',
            top: '0%',
            containLabel: true
          },
          label: {
            normal: {
              textStyle: {
                color: 'red'
              }
            }
          },
          yAxis: [{
            nameGap: 50,
            offset: '37',
            type: 'category',
            interval: 50, // inverse: ture, //图表倒叙或者正序排版
            data: '',
            nameTextStyle: {
              color: 'red'
            },
            axisLabel: { // rotate:45,
              show: false,
              textStyle: {
                fontSize: 32,
                color: function (params, Index) { // 标签学生字体颜色
                  // color:function(d){return "#"+Math.floor(Math.random()*(256*256*256-1)).toString(16);//随机生成颜色
                  var colorarrays = ['#6bc0fb', '#7fec9d', '#fedd8b', '#ffa597', '#84e4dd', '#749f83', '#ca8622', '#bda29a']
                  // console.log("111", Index, colorarrays[Index],params); //打印序列
                  return colorarrays[stuData[0].indexOf(params)]
                }
              }, // rotate:45,
              interval: 50
            },
            axisLine: {
              lineStyle: {
                color: 'black' // Y轴颜色
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: 'black'
              }
            }
          }],
          xAxis: [{
            type: 'value',
            name: '',
            splitNumber: 8, // 轴线个数
            nameTextStyle: {
              color: 'black'
            },
            axisLine: {
              lineStyle: {
                color: '#ffa597' // X轴颜色
              }
            },
            axisLabel: {
              formatter: '{value} '
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#fedd8b'
              }
            }
          }],
          series: [{
            name: '',
            type: 'bar',
            markLine: {
              label: {
                normal: {
                  show: false
                }
              },
              lineStyle: {
                normal: {
                  color: 'red',
                  width: 3
                }
              }
            },
            label: {
              normal: {
                show: true,
                position: 'right', // 数值显示在右侧
                formatter: '{c}'
              }
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // build a color map as your need.
                  // color:function(d){return "#"+Math.floor(Math.random()*(256*256*256-1)).toString(4);//随机生成颜色
                  // , '#a8d8ea', '#aa96da', '#fcbad3', '#ffffd2','#f38181', '#fce38a', '#eaffd0', '#95e1d3', '#e3fdfd', '#749f83', '#ca8622'
                  var colorList = ['#6bc0fb', '#7fec9d', '#fedd8b', '#ffa597', '#84e4dd', '#749f83', '#ca8622', '#bda29a']
                  // return colorList[params.dataIndex]
                  console.log('111', params.name)// 打印序列
                  return colorList[stuData[0].indexOf(params.name)]
                }
              }
            }
          },
          {
            name: '',
            type: 'bar',
            markLine: {
              label: {
                normal: {
                  show: false
                }
              },
              lineStyle: {
                normal: {
                  color: 'red',
                  width: 3
                }
              }
            },
            barWidth: '50%',
            barGap: '-100%',
            label: {
              normal: {
                show: true,
                fontSize: 16, // 标签学生字体大小
                position: 'left', // 数值显示在右侧
                formatter: function (p) {
                  return p.name
                }
              }
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // build a color map as your need.
                  // color:function(d){return "#"+Math.floor(Math.random()*(256*256*256-1)).toString(4);//随机生成颜色
                  var colorList = ['#6bc0fb', '#7fec9d', '#fedd8b', '#ffa597', '#84e4dd', '#749f83', '#ca8622', '#bda29a']
                  // return colorList[params.dataIndex]
                  // console.log("111", params.name); //打印序列
                  return colorList[stuData[0].indexOf(params.name)]
                }
              }
            }
          }
          ],
          animationEasingUpdate: 'quinticInOut',
          animationDurationUpdate: 1500// 动画效果
        },
        options: []
      }
      for (var n = 0; n < years.length; n++) {
        var res = []
        // alert(stuData.length);
        for (var j = 0; j < data[n].length; j++) {
          res.push({
            name: stuData[n][j],
            value: data[n][j]
          })
        }
        res.sort(function (a, b) {
          return b.value - a.value
        }).slice(0, 6)
        res.sort(function (a, b) {
          return a.value - b.value
        })
        var res1 = []
        var res2 = []
        // console.log(res);
        for (var t = 0; t < res.length; t++) {
          res1[t] = res[t].name
          res2[t] = res[t].value
        }
        option.options.push({
          title: {
            text: years[n] + '年'
          },
          yAxis: {
            data: res1
          },
          series: [{
            data: res2
          }, {
            data: res2
          }]
        })
      }
      activeRanking.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        activeRanking.resize()
      })
    }
  }
}
</script>

<style scoped>
  #activeRanking{
    width: 800px;
    height: 500px;
  }
</style>
