<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main style="position:relative" class="container">
      <!-- 头部-->
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/teachermanual.png">
      </div>

      <!--中间-->

      <el-row :gutter="20" style="margin-top: 40px">
        <el-col :span="6">
          <el-card :body-style="{ height: '770px' }" class="card">
            <p style="text-align:center;font-size:32px">教学准备</p>

            <i class="fa fa-envelope-o" style="display:flex;justify-content:center;font-size:40px"></i>

            <el-row class="container" style="margin-left:40px;margin-right:40px;margin-top: 20px">
              <el-col>
                <span style="color:#abc">资源管理</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师可以管理个人资源库，查看院校资源库</span>
              </el-col>

              <el-col style="margin-top:12px">
                <span style="color:#abc">测试管理</span>
                <span style="display:block;font-size:15px;margin-right: 65px">包括试题管理、试卷管理和考试安排管理</span>
              </el-col>

              <!--              <el-col style="margin-top:12px">-->
              <!--                <span style="color:#abc">教案管理</span>-->
              <!--                <span style="display:block;font-size: 15px;margin-right: 65px">管理教师个人课程教案，包括教案的创建及设计。可以查看和复用其他教师共享教案和弘成课程教案。教案用于发布学生在线学习任务和课堂教学。</span>-->
              <!--              </el-col>-->
            </el-row>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card :body-style="{ height: '770px' }" class="card">
            <p style="text-align:center;font-size:30px">课程建设</p>
            <i class="fa fa-paper-plane-o" style="display:flex;justify-content:center;font-size:40px"></i>

            <el-row class="container" style="margin-left:40px;margin-right:40px;margin-top: 20px">
              <el-col>
                <span style="color:#abc">教案管理</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">管理教师个人课程教案，包括教案的创建及设计。可以查看和复用其他教师共享教案。教案用于发布学生在线学习任务和课堂教学。</span>
              </el-col>

              <!--              <el-col style="margin-top:12px">-->
              <!--                <span style="color:#abc">测试管理</span>-->
              <!--                <span style="display:block">diandae soluta voluptatibus!diandae soluta voluptatibus!</span>-->
              <!--              </el-col>-->
              <!--              <el-col style="margin-top:12px">-->
              <!--                <span style="color:#abc">教案管理</span>-->
              <!--                <span style="display:block">diandae soluta voluptatibus!diandae soluta voluptatibus!</span>-->
              <!--              </el-col>-->
            </el-row>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card :body-style="{ height: '770px' }" class="card">
            <p style="text-align:center;font-size:30px">课程教学</p>
            <i class="fa fa-map-o" style="display:flex;justify-content:center;font-size:40px"></i>

            <el-row class="container" style="margin-left:40px;margin-right:40px;margin-top: 20px">
              <el-col>
                <span style="color:#abc">课程互动</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师可在课堂上进行签到、提问、讨论、测试、 推送课件等活动方式与学生进行互动，并随时查 看教学反馈和统计分析</span>
              </el-col>

              <el-col style="margin-top:12px">
                <span style="color:#abc">预习/复习</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师可发布预习、复习任务，用于课堂教学。</span>
              </el-col>
              <el-col style="margin-top:12px">
                <span style="color:#abc">课程辅导</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师对学生的课程学习进行课下辅导，包括测 试、作业的批阅、线下活动的评分、参与主题 讨论等</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card :body-style="{ height: '770px' }" class="card">
            <p style="text-align:center;font-size:30px">教学管理</p>
            <i class="fa fa-server" style="display:flex;justify-content:center;font-size:40px"></i>

            <el-row class="container" style="margin-left:40px;margin-right:40px;margin-top: 20px">
              <el-col>
                <span style="color:#abc">考核管理</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师针对课程和课堂设置考核标准，根据考核标准核算学生平时成绩，导入期末成绩后核算课程最终成绩，并发布学生成绩</span>
              </el-col>

              <el-col style="margin-top:12px">
                <span style="color:#abc">教学分组</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">支持基于分组的学习活动，如分组讨论等</span>
              </el-col>
              <el-col style="margin-top:12px">
                <span style="color:#abc">班级通知</span>
                <span style="display:block;font-size: 15px;margin-right: 65px">教师发布课程消息，查看学生签收情况</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <!--常见问题-->
      <el-row style="margin-top: 25px">
        <el-col :span="4" style="font-size:30px">常见问题</el-col>
        <el-col :span="2" :offset="18" style="font-size:15px;margin-top: 25px;"><a href="" style="color: #71BBE6">查看全部>>></a>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-card>
            <div style="display:flex;justify-content:space-around">
              <div style="display:flex;align-items:center;">
                <ul>
                  <li style="margin-top:10px">如何创建教案</li>
                  <li style="margin-top:10px">如何设置本学期教案</li>
                  <li style="margin-top:10px">怎么管理课程目录</li>
                  <li style="margin-top:10px">如何在教案中添加教学活动？</li>
                  <li style="margin-top:10px">怎么上传资源</li>
                </ul>
              </div>
              <div style="border:1px dashed #aaa;float:left;height:280px;"></div>
              <div style="display:flex;align-items:center">
                <ul>
                  <li style="margin-top:10px">怎么添加试题</li>
                  <li style="margin-top:10px">怎么组卷</li>
                  <li style="margin-top:10px">如何创建考试安排</li>
                  <li style="margin-top:10px">在课堂上怎么发起签到</li>
                  <li style="margin-top:10px">在课堂上怎么提问</li>
                </ul>
              </div>
              <div style="border:1px dashed #aaa;float:left;height:280px;"></div>
              <div style="display:flex;align-items:center">
                <ul>
                  <li style="margin-top:10px">在课堂上怎么进行分组讨论</li>
                  <li style="margin-top:10px">怎么在课堂中添加资源</li>
                  <li style="margin-top:10px">如何查看历史课堂记录</li>
                  <li style="margin-top:10px">怎么进行作业辅导</li>
                  <li style="margin-top:10px">如何进行教学分组</li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>


<script>

import Header from '@/components/Header'

export default {
  name: 'TeacherManual',
  components: {
    Header
  },
  data: function () {
    return {
      ctitle: '教师操作手册',
      visible: false
    }
  }
}
</script>

<style scoped>
.CourseMeg {
  width: 100%;
  height: 150px;
  background-color: #71BBE6;
  border-radius: 3px;
}

.csMeg-secterm {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 0 10px 0;
}

.csMeg-title {
  font-size: 37px;
  font-weight: bold;
  display: flex;
  align-content: center;
  justify-content: center;
}

.card:hover {
  background-color: #aaa !important;
  color: #FFF !important
}

.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}


</style>
