<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png"
             style="position: relative;z-index: -100">
        <span
          style="font-weight: bold;font-size: 28px;position: absolute;margin-top: 50px;margin-left: -1300px;color: #ffffff;mso-border-shadow: yes">
          {{ this.student.name }}</span>
      </div>
      <el-row style="margin: 0 0">
        <el-col :span="12">
          <AbilityCard :studentAbilityData="studentAbilityData"></AbilityCard>
        </el-col>
        <el-col :span="12">
          <el-card shadow="always" style="border-radius: 30px;margin-top: 20px;margin-left: 20px">
            <el-row>
              <el-col :span="20">
                <h3>学生信度详情</h3>
              </el-col>
              <el-col :span="4">
                <el-button @click="returnData" icon="el-icon-right" size="small" round>返回</el-button>
              </el-col>
            </el-row>
            <el-table
              :data="studentAbilityData.mutualRecords">
              <el-table-column prop="taskSubmissionId" label="互评编号" width="100">
              </el-table-column>
              <el-table-column property="taskSubmissionScore" label="最终提交分数" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.taskSubmissionScore.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="mutualScore" label="互评分数" width="80">
              </el-table-column>
              <el-table-column property="scoreType" label="评分形式" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.scoreType==='teacher'">
                    <el-tag type="success">老师评分</el-tag>
                  </template>
                  <template v-if="scope.row.scoreType==='mutual'">
                    <el-tag type="warning">互评评分</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column property="deviation" label="信度" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.deviation.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>

      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import AbilityCard from '@/components/part/AbilityCard'

export default {
  name: 'StudentAbility',
  components: {
    TermHeader,
    AbilityCard
  },
  data () {
    return {
      student: {},  //获得学生的信息
      studentId: 0,  //获得学生Id
      termId: 0,      //获得学期Id
      studentAbilityData: {}     //学生评价能力数据
    }
  },
  created () {
    this.termId = this.$route.query.termId
    this.studentId = this.$route.query.studentId
    this.loadStudentAbility()
    this.loadStudentName()
  },
  methods: {
    /*加载学生能力分析的方法*/
    loadStudentAbility () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top//taskMutualAssessment/showStudentMutualAbilityEvaluationByTermIdAndStudentId',
        params: {
          termId: this.termId,
          studentId: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const counterData = res.data.data
            this.studentAbilityData = counterData
            this.loadStudentAbilityCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    returnData () {
      this.$router.go(-1)
    },
    /*加载学生评价能力的图表*/
    loadStudentAbilityCharts () {

    },
    /*加载学生姓名*/
    loadStudentName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentById',
        params: {
          id: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.student = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
</style>
