<template>
  <div>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png">
      </div>
      <div class="container">
        <el-row>
          <el-col :span="6">
            <el-card shadow="always" style="margin: 20px auto;border-radius: 30px">
              <el-row>
                <i class="el-icon-user" style="font-weight: bold;font-size: 25px">{{ this.student.name }}</i>
              </el-row>
              <el-row>
                得分: <span
                style="font-weight: bold;font-size:20px;color: rgba(255,0,0,0.5) ">{{ paper.score.result }}</span>
              </el-row>
              <el-row>
                <span>提交时间:{{ paper.score.submitTime }}</span>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="16" :offset="1">
            <el-card shadow="always" style="margin: 20px auto;border-radius: 30px">
              <el-row>
                <h2>{{ paper.exam.name }}</h2>
                <span>开始时间:<i class="el-icon-time" style="margin: 0 3px"></i>{{ paper.score.startTime }}</span>
                <span style="margin-left: 50px">考试时间:<i class="el-icon-time" style="margin: 0 3px">
                </i>{{ paper.exam.duration }} min</span>
                <el-button size="small" @click="goBack" circle style="float: right">返回</el-button>
              </el-row>
              <el-divider></el-divider>
              <div class="questionSection" v-for="(qs,key,i) in paper.questions" :key="i">
                <h3>{{ order[i] }}、{{ getType(key) }}</h3>
                <el-row :gutter="10" v-for="(q,i) in qs" :key="q.id">
                  <el-col :span="1" style="text-align: center;vertical-align: middle"><p style="margin-top: 7px">{{
                      i + 1
                    }}、</p>
                  </el-col>
                  <el-col :span="20">
                    <div class="answer">
                      <SingleQuestion v-if="key==='single'" :question="q"></SingleQuestion>
                      <MutualQuestion :question="q" v-else-if="key==='multiple'"></MutualQuestion>
                      <Completion :question="q" v-else-if="key==='completion'"></Completion>
                      <SingleQuestion :question="q" v-else-if="key==='subject'"></SingleQuestion>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <el-tag effect="plain" type="info">{{ q.paperQuestion.score }}分</el-tag>
                  </el-col>
                </el-row>
              </div>
              <el-divider></el-divider>
              <div style="text-align: center ;margin-bottom: 100px">
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </div>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import SingleQuestion from '@/components/exam/SingleQuestion'
import MutualQuestion from '@/components/exam/MutualQuestion'
import Completion from '@/components/exam/Completion'
import SubjectiveQuestion from '@/components/exam/SubjectiveQuestion'

export default {
  name: 'ExamPaper',
  components: {
    Completion,
    MutualQuestion,
    SingleQuestion,
    TermHeader
  },
  mounted () {
    this.loadStudentName()
    this.getExamPaper()
  },
  data () {
    return {
      student: {},
      termId: this.$route.query.termId,
      examId: this.$route.query.examId,
      studentId: this.$route.query.studentId,
      paper: {},
      order: ['一', '二', '三', '四', '五', '六']
    }
  },
  props: {},

  methods: {
    goBack () {
      this.$router.push({
        path: '/showExamStudent',
        name: 'ShowExamGroupStudent',
        query: {
          termId: this.termId,
          examId: this.examId
        }
      })
    },
    getType (key) {
      switch (key) {
        case 'single':
          return '单选题'
        case 'multiple':
          return '多选题'
        case 'completion':
          return '填空题'
        case 'subjective':
          return '主观题'
      }
    },
    getExamPaper () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showExamPaperByIdAndStudentId',
        params: {
          id: this.examId,
          studentId: this.studentId
        }
      }).then(res => {
        if (res.data.status) {
          this.paper = res.data.data
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
          this.$router.go(-1)
        }
      })
    },
    /*加载学生姓名*/
    loadStudentName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentById',
        params: {
          id: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.student = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
