<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../../assets/img/situationanalysis.png"/>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 80%;margin: 20px auto;">
        <el-tab-pane label="学习资源使用" name="first">
          <!--热门资源榜-->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">热门资源榜</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <!-- Projects table -->
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">资源名称</th>
                      <th scope="col">资源类型</th>
                      <th scope="col">点击量</th>
                      <th scope="col">下载量</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>hadoop操作教程.pdf</td>
                      <td>文档</td>
                      <td>555</td>
                      <td>132</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>hive课件.ppt</td>
                      <td>文档</td>
                      <td>505</td>
                      <td>102</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--热门视频榜-->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">热门视频榜</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">视频名称</th>
                      <th scope="col">视频时长（分）</th>
                      <th scope="col">总观看时间（分）</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>hadoop操作</td>
                      <td>15</td>
                      <td>555</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>hive</td>
                      <td>18</td>
                      <td>266</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--热点问题-->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">热点问题榜</h3>
                    </div>
                    <div class="col">
                      <el-select v-model="danyuan.value" placeholder="请选择单元" clearable>
                        <el-option v-for="item in danyuan" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col">
                      <el-select v-model="keshi.value1" placeholder="请选择课时" clearable style="margin-left: 10px">
                        <el-option v-for="item in keshi" :key="item.value1" :label="item.label1" :value="item.value1">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <!-- Projects table -->
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">问题描述</th>
                      <th scope="col">点赞数</th>
                      <th scope="col">评论数</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>hadoop操作xxxxxxx</td>
                      <td>15</td>
                      <td>555</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>hivexxxxxxxxxxxxxxxxxx</td>
                      <td>18</td>
                      <td>266</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--达人榜-->
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">提问达人榜单</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">姓名</th>
                      <th scope="col">学号</th>
                      <th scope="col">提问数</th>
                      <th scope="col">被回答数</th>
                      <th scope="col">被点赞数</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>张三</td>
                      <td>201941891245</td>
                      <td>515</td>
                      <td>55</td>
                      <td>123</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>李四</td>
                      <td>201941891235</td>
                      <td>318</td>
                      <td>66</td>
                      <td>100</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">回复达人榜单</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">姓名</th>
                      <th scope="col">学号</th>
                      <th scope="col">回答数</th>
                      <th scope="col">提问数</th>
                      <th scope="col">被点赞数</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>张三</td>
                      <td>201941891245</td>
                      <td>515</td>
                      <td>55</td>
                      <td>123</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>李四</td>
                      <td>201941891235</td>
                      <td>318</td>
                      <td>66</td>
                      <td>100</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--词云-->
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">问题热词</h3>
                    </div>
                  </div>
                </div>
                <div id="wordCloud" style="width:600px;height:320px;">
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">词频统计</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <!-- Projects table -->
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">关键词</th>
                      <th scope="col">出现次数</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>hadoop操作</td>
                      <td>132</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>hive课件</td>
                      <td>102</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>sqoop</td>
                      <td>102</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">提问数量变化曲线</h3>
                    </div>
                  </div>
                </div>
                <div id="question" style="width:1200px;height:360px;"></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课堂" name="second">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">各教学视频观看进度</h3>
                    </div>
                  </div>
                </div>
                <!--       观看各教学视频的次数         -->
                <ChapterCharts></ChapterCharts>
                <!--       观看各教学视频的次数         -->
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">学生完成课程情况</h3>
                    </div>
                  </div>
                </div>
                <!--       学生完成课程情况         -->
                <StudentCharts></StudentCharts>
                <!--      学生完成课程情况        -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                    </div>
                  </div>
                </div>
                <!--                <div id="heat" style="width:600px;height:350px;padding-left: 30px"></div>-->
                <FinalScore></FinalScore>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                    </div>
                  </div>
                </div>
                <!--         讨论次数        -->
                <CommentCharts></CommentCharts>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">智慧之星</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">姓名</th>
                      <th scope="col">学号</th>
                      <th scope="col">在线答题正确率</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>张三</td>
                      <td>201941891245</td>
                      <td>100%</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>李四</td>
                      <td>201941891245</td>
                      <td>95%</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>王五</td>
                      <td>201941891215</td>
                      <td>90%</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">活跃之星</h3>
                    </div>
                    <div class="col text-right">
                      <a href="" class="btn btn-sm btn-primary">完整榜单</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                    <tr>
                      <th scope="col">排名</th>
                      <th scope="col">姓名</th>
                      <th scope="col">学号</th>
                      <th scope="col">参与总次数</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>张三</td>
                      <td>201941891245</td>
                      <td>92</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>李四</td>
                      <td>201941891245</td>
                      <td>90</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>王五</td>
                      <td>201941891215</td>
                      <td>83</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card">
                <div class="card-header border-0">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0">互动类型</h3>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <!-- Projects table -->
                  <table class="table align-items-center table-flush">
                    <tbody>
                    <tr>
                      <th scope="row">
                        资源播放
                      </th>
                      <td>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="progress">
                              <div class="progress-bar bg-gradient-danger" role="progressbar" aria-valuenow="60"
                                   aria-valuemin="0" aria-valuemax="100" style="width: 60%;"></div>
                            </div>
                          </div>
                          <span class="mr-2" style="margin-left: 20px">360次</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        课堂测试
                      </th>
                      <td>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="progress">
                              <div class="progress-bar bg-gradient-success" role="progressbar" aria-valuenow="70"
                                   aria-valuemin="0" aria-valuemax="100" style="width: 70%;"></div>
                            </div>
                          </div>
                          <span class="mr-2" style="margin-left: 20px">123次</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        调查问卷
                      </th>
                      <td>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="progress">
                              <div class="progress-bar bg-gradient-primary" role="progressbar" aria-valuenow="90"
                                   aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
                            </div>
                          </div>
                          <span class="mr-2" style="margin-left: 20px">80次</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        主题讨论
                      </th>
                      <td>
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="progress">
                              <div class="progress-bar bg-gradient-info" role="progressbar" aria-valuenow="75"
                                   aria-valuemin="0" aria-valuemax="100" style="width: 75%;"></div>
                            </div>
                          </div>
                          <span class="mr-2" style="margin-left: 20px">75次</span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="作业与考试" name="fourth">
          <el-card shadow="always" style="border-radius: 30px;margin-left: 50px;margin-top: 20px">
            <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Score Information</span>
            <span style="float: right">共{{ this.scoreData.length }}人</span>
            <el-table
              :data="scoreData"
              @sort-change="loadScoreGroup"
              style="width: 100%">
              <el-table-column
                prop="studentId"
                label="学生编号"
                width="160">
              </el-table-column>
              <el-table-column
                prop="student.name"
                label="学生姓名"
                width="160">
              </el-table-column>
              <el-table-column
                prop="score"
                label="平时权重分"
                sortable="custom"
                width="130">
                <template slot-scope="scope">
                  {{ (scope.row.checkpointScore + scope.row.commentScore + scope.row.taskScore).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="score"
                label="考试权重分数"
                sortable="custom"
                width="130">
                <template slot-scope="scope">
                  {{ (scope.row.examScore).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="score"
                label="最终得分"
                sortable="custom"
                width="130">
                <template slot-scope="scope">
                  {{
                    (scope.row.checkpointScore + scope.row.commentScore + scope.row.taskScore + scope.row.examScore).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column property="status" label="评价" width="140">
                <template slot-scope="scope">
                  <template v-if="scope.row.status==='pass'">
                    <el-tag type="success">通过</el-tag>
                  </template>
                  <template v-if="scope.row.status==='fail'">
                    <el-tag type="danger">挂科</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button size="small" round @click="toCertificate(scope.$index, scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import '@/assets/css/nucleo.css'
import '@/assets/css/argon.css'
import '../../../plugins/echarts-wordcloud.js'
import axios from 'axios'
import ChapterCharts from '@/components/charts/ChapterCharts'
import StudentCharts from '@/components/charts/StudentCharts'
import CommentCharts from '@/components/charts/CommentCharts'
import FinalScore from '@/components/charts/FinalScore'

export default {
  name: 'SituationAnalysis',
  components: {
    StudentCharts,
    ChapterCharts,
    TermHeader,
    CommentCharts,
    FinalScore
  },
  data () {
    return {
      scoreData: [],/*成绩数据*/
      termId: 0,
      activeName: 'second',
      danyuan: [{
        value: '选项1',
        label: '第一单元'
      }, {
        value: '选项2',
        label: '第二单元'
      }, {
        value: '选项3',
        label: '第三单元'
      }, {
        value: '选项4',
        label: '第四单元'
      }, {
        value: '选项5',
        label: '第五单元'
      }],
      keshi: [{
        value1: '1课时xxxx',
        label: '1课时xxxx'
      }, {
        value1: '2课时xxxx',
        label: '2课时xxxx'
      }, {
        value1: '3课时xxxx',
        label: '3课时xxx'
      }],
      wordList: [
        {
          name: '珍珠奶茶',
          value: 15000
        },
        {
          name: '冰激凌红茶',
          value: 10081
        },
        {
          name: '椰果奶茶',
          value: 9386
        },
        {
          name: '波霸奶茶',
          value: 7500
        },
        {
          name: '金桔柠檬',
          value: 7500
        },
        {
          name: '乌龙奶茶',
          value: 6500
        },
        {
          name: '芒果欧蕾',
          value: 6500
        },
        {
          name: '仙草奶茶',
          value: 6000
        },
        {
          name: '翡翠柠檬',
          value: 4500
        },
        {
          name: '芒果养乐多',
          value: 3800
        },
        {
          name: '柠檬养乐多',
          value: 3000
        },
        {
          name: '波霸奶绿',
          value: 2500
        },
        {
          name: '四季春茶',
          value: 2300
        },
        {
          name: '茉莉绿茶',
          value: 2000
        },
        {
          name: '阿萨姆红茶',
          value: 1900
        },
        {
          name: '奶冻摇摇乐',
          value: 1800
        },
        {
          name: '冻顶乌龙茶',
          value: 1700
        },
        {
          name: '咖啡',
          value: 1600
        },
        {
          name: '焦糖玛奇朵',
          value: 1500
        },
        {
          name: '金桔柠檬',
          value: 1200
        }
      ]
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点
  },
  mounted () {
    // 在mounted生命周期函数中实例化echarts对象。因为要确保dom元素已经挂载到页面中
    this.wordCloud()
    this.question()
    /* this.time()*/
    /*展示学生未完成的数据*/
    /*展示完成章节的数据*/
    /*获得当前学期的证书*/
    this.loadThisTerm()
    this.loadScoreGroup()/*加载成绩数据*/
  },
  methods: {
    /*跳转到证书页面*/
    toCertificate (index, rows) {
      this.$router.push({
        path: '/certificateScore',
        name: 'CertificateScore',
        query: {
          termId: this.termId
        }
      })
    },
    /*加载分数数据*/
    loadScoreGroup () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/selection/showSelectionsWithRealtimeScoresByTermId',
        params: {
          termId: this.termId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.scoreData = countData
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*获得当前学期的证书*/
    loadThisTerm () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermById',
        params: {
          id: this.termId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    wordCloud () {
      // 基于准备好的dom，初始化echarts实例
      const wordCloud = this.$echarts.init(document.getElementById('wordCloud'))
      wordCloud.setOption({
        series: [
          {
            type: 'wordCloud',
            // 用来调整词之间的距离
            gridSize: 5,
            // 用来调整字的大小范围
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [12, 60],
            // 用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            // rotationRange: [-45, 0, 45, 90],
            // rotationRange: [ 0,90],
            rotationRange: [0, 0],
            // 随机生成字体颜色
            // maskImage: maskImage,
            textStyle: {
              normal: {
                color: function () {
                  return 'rgb(' + [
                    Math.round(Math.random() * 255),
                    Math.round(Math.random() * 255),
                    Math.round(Math.random() * 255)
                  ].join(',') + ')'
                }
              }
            },
            // 位置相关设置
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            width: '200%',
            height: '200%',
            // 数据
            data: this.wordList
          }
        ]
      })
      window.addEventListener('resize', function () {
        wordCloud.resize()
      })
    },
    question () {
      // 基于准备好的dom，初始化echarts实例
      const question = this.$echarts.init(document.getElementById('question'))
      var option
      option = {
        toolbox: {
          right: 25,
          show: true,
          feature: {
            dataView: {
              show: true
            },
            restore: {
              show: true
            },
            dataZoom: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [62, 93, 80, 94, 89, 83, 92],
            type: 'line',
            areaStyle: {}
          }
        ]
      }
      question.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        question.resize()
      })
    },
    time () {
      // 基于准备好的dom，初始化echarts实例
      const time = this.$echarts.init(document.getElementById('time'))
      // 指定图表的配置项和数据
      var option = {
        toolbox: {
          show: true,
          right: 25,
          feature: {
            dataView: {
              show: true
            },
            restore: {
              show: true
            },
            dataZoom: {
              show: true
            },
            saveAsImage: {
              show: true
            },
            magicType: {
              type: ['line', 'bar']
            }
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['考试情况']
        },
        xAxis: {
          data: ['第一次测试', '第二次测试', '第三次测试', '第四次测试', '第五次测试', '期末测试']
        },
        yAxis: {},
        series: [{
          name: '平均分',
          type: 'line',
          data: [82, 90, 70, 80, 85, 95],
          markPoint: {
            data: [
              {
                type: 'max',
                name: '最大值'
              },
              {
                type: 'min',
                name: '最小值'
              }
            ]
          },
          markLine: {
            data: [
              {
                type: 'average',
                name: '平均值'
              }
            ]
          }
        }]
      }
      // 使用刚指定的配置项和数据显示图表。
      time.setOption(option)
      window.addEventListener('resize', function () {
        time.resize()
      })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.el-card {
  margin: 15px 0;
}

.el-tag {
  margin-right: 15px;
}
</style>
