<template>
  <div id="examAverageStudent" style="width:600px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExamAverageCharts',
  data () {
    return {
      termId: 0,
      examData: [],/*评论数据*/
      examName: []
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点
  },
  mounted () {
    /*展示学生已经完成的数据*/
    this.loadExamAverageData()
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*加载讨论完成的数据*/
    loadExamAverageData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showAverageByTermIdGroupByStudentId',
        params: {
          termId: this.termId,
          setStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            for (let i = 0; i < countData.length; i++) {
              this.examData.push(countData[i])
            }
            this.exam()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    exam () {
      let gayData = this.convertKey(this.examData, ['id', 'value', 'name'])
      // 基于准备好的dom，初始化echarts实例
      const examAverageStudent = this.$echarts.init(document.getElementById('examAverageStudent'))
      let option = {
        title: {
          text: '考试学生成绩表'
        },
        dataZoom: [{
          type: 'slider',
          show: true, //flase直接隐藏图形
          xAxisIndex: [0],
          left: '5%', //滚动条靠左侧的百分比
          right: 50,
          bottom: -5,
          start: 35,//滚动条的起始位置
          end: 65 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: function (params) {
            //console.log(params)
            let str = ''
            for (let i = 0; i < params.length; i++) { // 添加单位(*^▽^*)
              str += '<br/>' + params[i].data.name.name + ' ' + params[i].value
              //console.log(params[i].data.name.name)
            }
            return str
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: this.commentName,
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '完成',
            type: 'bar',

            data: gayData,
            color: '#87CEEB'
          },
          {
            name: '完成',
            type: 'line',
            data: gayData,
            smooth: true,
            color: 'rgba(255,0,0,0.8)'
          }
        ]
      }
      examAverageStudent.on('click', params => {
        this.$router.push({
          path: '/studentScore',
          name: 'StudentScore',
          query: {
            termId: this.termId,
            studentId: params.data.id
          }
        })
      })
      examAverageStudent.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        examAverageStudent.resize()
      })
    }
    ,
  }
}
</script>

<style scoped>

</style>

