<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-container>
          <el-card shadow="always" style="margin: 20px auto;border-radius: 30px">
            <!--  考试成绩            -->
            <el-col :span="12">
              <ExamAverageCharts></ExamAverageCharts>
            </el-col>
            <el-col :span="12">
              <ExamAverageStudent></ExamAverageStudent>
            </el-col>
          </el-card>
      </el-container>

      <el-container>
        <el-card shadow="always" style="margin: 20px auto;border-radius: 30px">
          <el-table
            :data="examData">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column prop="id" label="考试编号" width="120">
            </el-table-column>
            <el-table-column prop="name" label="考试名称" width="180">
            </el-table-column>
            <el-table-column label="考试状态" width="100" property="status">
              <template slot-scope="scope">
                <template v-if="scope.row.status==='normal'">
                  <el-tag>正常</el-tag>
                </template>
                <template v-if="scope.row.status==='pass'">
                  <el-tag>普通测试</el-tag>
                </template>
                <template v-if="scope.row.status===''">
                  <el-tag>大考</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="duration" label="考试时长(min)" width="150">
            </el-table-column>
            <el-table-column prop="start" label="考试时间" width="200">
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button @click="showExam(scope.$index, scope.row)" size="small" round>查看</el-button>
                <el-button @click="deleteExam(scope.$index, scope.row)" size="small" type="danger" round>退选
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top:20px">
            <el-col :span="12" :offset="6">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadExamData"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
              </el-pagination>
            </el-col>
          </el-row>
        </el-card>
      </el-container>
      <!--    确认是否删除任务  -->
      <template v-if="isAddOrUp===3">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span style="color: rgba(255,48,48,0.5);font-weight: bold;font-size: 28px">确实要退课吗?</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deselectCourse">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import ExamAverageCharts from '@/components/charts/ExamAverageStudent'
import ExamAverageStudent from '@/components/charts/ExamAverageCharts'

export default {
  name: 'StudentSectionManage',
  components: {
    ExamAverageCharts,
    TermHeader,
    ExamAverageStudent
  },
  created () {
    this.termId = this.$route.query.termId
    /*  this.loadStudentCountData()*/
    this.loadExamData()
  },
  data () {
    return {
      isAddOrUp: 0,  //0表示添加，1表示更新  2表示编辑评分   3 表示是否确认删除任务
      dialogVisible: false,
      /*分页*/
      pageSizes: [6, 12, 18, 24, 30],
      currentPage: 1,
      pageSize: 10,
      total: 1,
      termId: 0,
      examData: [],
      examCountData: {}
    }
  },
  methods: {
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadExamData()
    },
    /*加载考试信息*/
    loadExamData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showExamsByTermId',
        params: {
          termId: this.termId,
          currentPage: this.currentPage,
          pageSize: 6
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.examData = countData
            this.total = res.data.data.totalCount
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //查看学生的课程数据
    showExam (index, rowObject) {
      this.$router.push({
        path: '/showExamStudent',
        name: 'ShowExamGroupStudent',
        query: {
          termId: this.termId,
          examId: rowObject.id
        }
      })
    },
    /*删除学生信息*/
    deleteExam (index, rowObject) {
      this.dialogVisible = true
      this.isAddOrUp = 3
    },
    deselectCourse () {

    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.main-table {
  width: 80%;
}
</style>
