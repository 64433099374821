<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png" alt="">
        <span
          style="font-weight: bold;font-size: 28px;position: absolute;margin-top: 50px;margin-left: -1300px;color: #ffffff;mso-border-shadow: yes">
          {{ this.student.name }}</span>
      </div>
      <el-container>
          <el-card shadow="always" style="width: 100%;border-radius: 30px;margin-top: 30px;height:450px">
            <el-card id="sideBox" shadow="always"
                     style="margin-top: 20px;float: right;display: flex;border-radius: 25px">
              <el-avatar style="vertical-align: middle"></el-avatar>
              <el-button @click="startRow()" size="small" round style="margin-left: 10px"
                         type="success">Start
              </el-button>
              <el-button @click="endRow()" size="small" round type="warning">End</el-button>
              <el-button @click="completeRow()" size="small" round type="primary">Complete
              </el-button>
              <el-button @click="mutual()" size="small" round style="margin-left: 10px">Mutual
              </el-button>
              <el-divider></el-divider>
            </el-card>
            <el-col :span="10">
            <span style="margin-left: 30px;font-weight: bold;font-size: 28px;margin-right: 30px"><i
              class="el-drawer-fade-enter-active">{{ this.task.title }}</i></span>
              <template v-if="this.str==='jpg'||this.str==='png'||this.str==='jpeg'">
                <img :src="this.taskData.submissionUrl" style="width: 300px;height: 300px;" alt="">
              </template>
              <template v-if="this.str==='mp4'">
                <d-player id="player" ref="dplayer" :options="d_options"
                          style="width: 816px;height: 459px;"></d-player>
              </template>
              <template v-if="this.str===''">
                <h3>非作业格式，请重新提交。</h3>
              </template>
            </el-col>
            <el-col :span="6">
              <el-row>
                <span>评分</span>
                <div class="block">
                  <el-rate :max="maxScore" v-model="editScore"></el-rate>
                  <el-button size="small" @click="scoreTask" round>Score</el-button>
                </div>
              </el-row>
            </el-col>
          </el-card>
        <el-row>
          <el-col :span="4">
            <el-button @click="returnData" icon="el-icon-right" size="small" round>返回</el-button>
          </el-col>
        </el-row>
      </el-container>
      <template v-if="isAddOrUp===4">
        <el-drawer
          title="请开始判别互评评分吧"
          :visible.sync="table"
          direction="rtl"
          size="50%">
          <el-table :data="mutualData" v-if="reloadDrawer">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column property="taskSubmissionId" label="互评编号" width="80"></el-table-column>
            <!--        拿到学生的名字            -->
            <el-table-column property="student.name" label="学生名称" width="120"></el-table-column>
            <el-table-column property="scoreType" label="状态" width="80">
              <template slot-scope="scope">
                <template v-if="scope.row.status==='normal'">
                  <el-tag type="success">正常</el-tag>
                </template>
                <template v-if="scope.row.status==='mutual'">
                  <el-tag type="danger">互评评分</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column property="score" label="分数" width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.score.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="gmtCreate" label="创建时间" width="180"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="removeMutual(scope.$index, scope.row)" size="small" round type="danger">
                  Delete
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top:20px">
            <el-col :span="12" :offset="2">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadMutualData"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </el-col>
          </el-row>
        </el-drawer>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
import Vue from 'vue'

Vue.use(VueDPlayer)
export default {
  name: 'TaskScore',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.taskSubmissionId = this.$route.query.taskSubmissionId
    this.studentId = this.$route.query.studentId
    this.loadStudentData()
    this.loadTaskSubmission()
  },
  data () {
    return {
      reloadDrawer: true,
      table: false,   //展示评分的数据
      isAddOrUp: 0,
      mutualData: [],
      maxScore: 10,
      termId: 0,  /*学期Id*/
      taskSubmissionId: 0, /*任务提交Id*/
      studentId: 0,
      editScore: 0.0,
      student: {},          /*学生数据*/
      taskData: {},
      str: '',
      d_options: {
        container: document.getElementById('d-player'),
        video: {
          url: ''
        }
      },
      taskId: 0,
      task: {},
      pageSizes: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
      currentPage: 1,
      pageSize: 100,
      total: 1
    }
  },
  watch: {
    deep: true,
    handler (val, old) {
      if (this.str === 'mp4') {
        this.$refs.dplayer.dp.switchVideo({ url: val.taskData.submissionUrl })
        if (val.chapterInfo.contentType === 'video') {
          this.hasVideo = true
        } else {
          this.hasVideo = false
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadMutualData()
    },
    //加载互评数据
    loadMutualData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showByTaskId',
        params: {
          taskId: this.taskId,
          withStudent: true,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.mutualData = res.data.data.content
            this.total = res.data.data.totalCount
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //开启互评评分
    mutual () {
      this.table = true
      this.isAddOrUp = 4
      this.loadMutualData()
    },
    /*加载任务评分数据*/
    loadTaskSubmission () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTaskSubmissionById',
        params: {
          id: this.taskSubmissionId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.taskData = res.data.data
            this.str = res.data.data.submissionUrl.substring(res.data.data.submissionUrl.lastIndexOf('.') + 1)
            this.taskId = res.data.data.taskId
            this.loadTaskName()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //正式开始评分
    scoreTask () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/scoreByTeacher',
        params: {
          id: this.taskSubmissionId,
          score: this.editScore
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载任务名称*/
    loadTaskName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/showTaskById',
        params: {
          id: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.task = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    returnData () {
      this.$router.go(-1)
    },
    loadStudentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentById',
        params: {
          id: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.student = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //开启互评
    startRow () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/start',
        params: {
          taskId: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //结束互评
    endRow () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/end',
        params: {
          taskId: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //完成任务
    completeRow () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/complete',
        params: {
          id: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*滚动页面*/
    scrollToTop () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const sideBox = document.getElementById('sideBox')
      if (scrollTop >= 450) {
        sideBox.classList.add('fixedBox')
        this.sideButton = true
      } else {
        sideBox.classList.remove('fixedBox')
        this.sideButton = false
      }
    },
    //删除Mutual
    removeMutual (index, rows) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/removeByTeacher',
        params: {
          id: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadMutualData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.fixedBox {
  margin-top: 100px;
  top: 0;
  position: fixed;
  z-index: 100;
}

#sideBox {
  transition: 2s;
}
</style>
