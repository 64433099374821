<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="mainSection">
      <div class="owlImg">
        <div class="block">
          <el-carousel height="400px" style="margin: 10px 20px;margin-top: 20px">
            <el-carousel-item>
              <img src="../assets/img/home/lunbo4.svg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!--四个指标 begin-->
      <div class="row" style="padding-top:30px;padding-left:15px;padding-right:15px;background-color:#71BBE6">
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Num-Classes</h5>
                  <span class="h2 font-weight-bold mb-0"></span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i class="ni ni-active-40"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Num-Exams</h5>
                  <span class="h2 font-weight-bold mb-0">36</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                    <i class="ni ni-chart-pie-35"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Num-Courses</h5>
                  <span class="h2 font-weight-bold mb-0">{{ this.courseTotal }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i class="ni ni-money-coins"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Num-Student</h5>
                  <span class="h2 font-weight-bold mb-0">{{ this.studentTotal }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                    <i class="ni ni-chart-bar-32"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--四个指标 end-->
      <!--最近课程 begin-->
      <div class="courseSection animate" data-ani="fadeInUp">
        <el-card shadow="always" style="border-radius: 30px;width: 80%;margin: 0 auto">
          <div class="sectionCourseMain container">
            <el-tabs active-name="first">
              <!--   后面有course-tab标签，之后分类在做         -->
              <el-tab-pane label="编程" name="first"></el-tab-pane>
              <el-tab-pane label="数据科学" name="second"></el-tab-pane>
              <el-tab-pane label="人工智能" name="third"></el-tab-pane>
              <el-tab-pane label="UI设计" name="fourth"></el-tab-pane>
            </el-tabs>
            <!--   课程数据        -->
            <el-row>
              <el-col :span="20" :offset="2">
                <div class="box-card" style="margin-top: 10px;margin-left: 20px">
                  <el-row>
                    <el-col :span="6" :sm="12" :md="8" v-for="course in courseData" :key="course"
                            style="margin-top:15px;display: flex;">
                      <CourseCenter :course="course"></CourseCenter>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <!--   拿到课程卡片       -->
          </div>
        </el-card>
      </div>
      <!--最近课程 end-->
      <!--图表 begin-->
      <div class="row" style="padding-left: 40px;padding-right: 40px;padding-top:30px;background-color: #77CDFF">
        <div class="col-xl-7">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                </div>
              </div>
            </div>
            <!--     图表数据       -->
            <div style="margin-left: 100px;width: 800px">
              <ExamAverageChartsByHome></ExamAverageChartsByHome>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                </div>
              </div>
            </div>
            <!--  图表数据    -->
            <StudentChartsByHome></StudentChartsByHome>
          </div>
        </div>
      </div>
      <!--图表 end-->
      <!--最近发布的讨论+成绩表格-->
      <div class="row" style="padding-left: 40px;padding-right: 40px;padding-top:30px;">
        <div class="col-xl-7">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col"><h5 class="h3 mb-0">最近一次发布的讨论</h5></div>
                <div class="col text-right">
                  <button class="btn btn-sm btn-primary">more</button>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center">
              <div class="d-flex align-items-center">
                <a href="#">
                  <img :src="teacher.avatarUrl" class="avatar">
                </a>
                <div class="mx-3">
                  <a href="#" class="text-dark font-weight-600 text-sm">{{ this.teacher.username }}</a>
                  <small class="d-block text-muted" style="margin-top: 10px">{{ this.discussion.gmtCreate }}</small>
                </div>
                <span style="font-weight: bold;font-size: 28px;margin-left: 20px">{{ this.discussion.title }}</span>
              </div>
            </div>
            <div class="card-body">
              <p class="mb-4">
              </p>
              <img alt="Image placeholder" src="../assets/img/exammanage.png" class="img-fluid rounded">
              <div class="row align-items-center my-3 pb-3 border-bottom">
                <div class="col-sm-6">
                  <div class="icon-actions">
                    <a href="#" class="like active">
                      <i class="ni ni-like-2"></i>
                      <span class="text-muted">150</span>
                    </a>
                    <a href="#">
                      <i class="ni ni-chat-round"></i>
                      <span class="text-muted">{{ this.discussionTotal }}</span>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6 d-none d-sm-block">
                  <div class="d-flex align-items-center justify-content-sm-end">
                    <div class="avatar-group">
                      <a href="#" class="avatar avatar-xs rounded-circle" data-toggle="tooltip"
                         data-original-title="Jessica Rowland">
                        <img alt="Image placeholder" class="" src="../assets/img/logo.png">
                      </a>
                    </div>
                    <small class="pl-2 font-weight-bold">and 30+ more</small>
                  </div>
                </div>
              </div>
              <!-- Comments -->
              <div class="mb-1">
                <div class="media media-comment" v-for="item in discussionDataAndPic" :key="item">
                  <img alt="Image placeholder" :src="item.avatarUrl" class="avatar avatar-lg media-comment-avatar rounded-circle">
                  <div class="media-body">
                    <div class="media-comment-text">
                      <h6 class="h5 mt-0">{{ item.student.name }}</h6>
                      <p class="text-sm lh-160">{{ item.content }}</p>
                      <div class="icon-actions">
                        <a href="#" class="like active">
                          <i class="ni ni-like-2"></i>
                          <span class="text-muted">3 likes</span>
                        </a>
                        <a href="#">
                          <i class="ni ni-chat-round"></i>
                          <span class="text-muted">1 discussion</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">最近一次考试成绩xxx</h3>
                </div>
                <div class="col text-right">
                  <a href="#" class="btn btn-sm btn-primary">See all</a>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                <tr>
                  <th scope="col">姓名</th>
                  <th scope="col">成绩</th>
                  <th scope="col">交卷时间</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in examData" :key="item">
                  <th scope="row">
                    {{ item.student.name }}
                  </th>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="mr-2">{{ item.result }}</span>
                    </div>
                  </td>
                  <td>
                    {{ item.submitTime }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--最近发布的讨论+成绩表格-->
      <!--继续加图 背景颜色#6f86d6-->
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from '@/components/Header'
import CourseSection from '@/components/CourseSection'
import Footer from '@/components/Footer'
import axios from 'axios'
import CourseCenter from '@/components/CourseCenter'
import ExamAverageChartsByHome from '@/components/charts/ExamAverageChartsByHome'
import StudentChartsByHome from '@/components/charts/StudentChartsByHome'

export default {
  name: 'Home',
  components: {
    StudentChartsByHome,
    Footer,
    CourseSection,
    Header,
    CourseCenter,
    ExamAverageChartsByHome
  },
  mounted () {
    this.$nextTick(() => {
      this.handleAnimate()
      /*    addEventListener('scroll', handleScroll)*/
      addEventListener('scroll', this.handleAnimate)
    })
  },
  created () {
    this.loadCourseData()  /*统计课程总数*/
    this.loadStudentData()  /*统计学生总数*/
    this.loadDiscussionAndStudentPic()  /*获取讨论的信息和学生信息*/
    this.checkLogin()           /*检测登录状态*/
    this.loadDiscussionData()  /*获取讨论的详情数据*/
    this.loadStudentExam()   /*加载考试数据*/
  },
  data () {
    return {
      courseData: [],   /*课程数据*/
      currentPage: 1,       /*分页页数*/
      courseTotal: 0,   /*统计课程总数*/
      studentTotal: 0,  /*统计学生总数*/
      discussionId: 600003, /*定义假数据Id获取讨论列表*/
      termId: 400003,       /*定义假数据获得图表数据*/
      examId: 800002,  /*获得考试假数据*/
      discussionDataAndPic: [],  /*获取讨论数据*/
      discussionTotal: 0,   /*讨论总数*/
      teacher: {
        username: '', /*一个老师对象，获取老师信息*/
        avatarUrl: ''
      },
      discussion: {},         /*一个讨论对象*/
      students: [],             /*获得一群学生头像*/
      examData: [],            /*考试数据*/
      examCountData: 0,        /*总数*/
      picData: [],           /*存放图片数据*/
      pic: [],
      str: ''
    }
  },

  methods: {
    /*获取讨论数据*/
    loadDiscussionData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/showDiscussionById',
        params: {
          id: this.discussionId
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.discussion = response.data.data
          } else {
            this.$message.error(response.data.message)
          }
        })
    },
    /*检测登录状态并获得老师信息*/
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
      }).then(response => {
        this.teacher.username = response.data.data.name
        const email = response.data.data.email
        if (email.substring(email.length - 7) === '@qq.com') {
          const qqId = email.substring(0, email.indexOf('@'))
          this.teacher.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
        }
      })
    },
    /*统计学生总数*/
    loadStudentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/teacher/showMyStudents',
        params: {
          currentPage: 1,
          pageSize: 10
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.studentTotal = response.data.data.totalCount
          }
        })
    },
    /*统计课程总数*/
    loadCourseData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showMyCourses',
        params: {
          currentPage: this.currentPage,
          pageSize: 6
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.courseData = res.data.data.content
            this.courseTotal = res.data.data.totalCount
          }
        })
    },
    /*获取讨论的人的头像和讨论信息*/
    loadDiscussionAndStudentPic () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/showCommentsByDiscussionId',
        params: {
          discussionId: this.discussionId,
          currentPage:6,
          pageSize: 4
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            this.discussionDataAndPic = res.data.data.content
            this.discussionTotal = res.data.data.totalCount
            for (let i = 0; i < countData.length; i++) {
              if (countData[i].student.email != null) {
                if (countData[i].student.email.substring(countData[i].student.email.length - 7) === '@qq.com') {
                  this.str = 'http://q1.qlogo.cn/g?b=qq&nk=' + countData[i].student.email.substring(0, countData[i].student.email.indexOf('@')) + '&s=100'
                  this.picData.push(this.str)
                }
              } else {
                this.str = 'http://q1.qlogo.cn/g?b=qq&nk=' + '1395202514' + '&s=100'
                this.picData.push(this.str)
              }
              this.discussionDataAndPic[i]['avatarUrl'] = this.picData[i]   /*sb写法，不必深究*/
              /*      this.picData.map(item => {
                      this.discussionDataAndPic.push({
                        avatarUrl: item
                      })
                    })*/
            }
            console.log(this.discussionDataAndPic)
          }
        })
    },
    /*拿到学生的头像*/
    /*加载学生课程信息*/
    loadStudentExam () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showScoresByExamId',
        params: {
          examId: this.examId,
          currentPage: this.currentPage,
          pageSize: 10,
          setStudent: true
        }
      })
        .then((res) => {
            if (res.data.status) {
              this.examData = res.data.data.content
              //this.examCountData = res.data.data.totalCount
            }
          }
        )
    },
    handleAnimate () {
      const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const vh = document.documentElement.clientHeight
      const dom = document.querySelectorAll('.animate');
      [].slice.call(dom).forEach(v => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1
              v.classList.add(v.dataset.ani)
            }, delay)
          } else {
            v.style.opacity = 1
            v.classList.add(v.dataset.ani)
          }
        } else {
          v.classList.remove(v.dataset.ani)
          v.style.opacity = 0
        }
      })
    }
  },
  destroyed () {
    /*  removeEventListener('scroll', handleScroll)*/
    removeEventListener('scroll', this.handleAnimate)
  }
}
</script>

<style scoped>
.mainSection {
  height: 100%;
}

.animate {
  opacity: 0;
}

.pulse {
  animation: pulse 1s ease-in;
}

.fadeInUp {
  animation: fadeInUp 1s ease-in;
}

.bounceInLeft {
  animation: bounceInLeft 2s ease-in;
}

.bounceInRight {
  animation: bounceInRight 2s ease-in;
}

.fadeInDown {
  animation: fadeInDown .5s ease-in;
}

.zoomIn {
  animation: zoomIn .5s ease-in;
}

.owlImg {
  margin-top: 10px;
}

.owlImg img {
  width: 100%;
}

.courseSection {
  padding-top: 80px;
  padding-bottom: 80px;
}

.sectionTitle {
  text-align: center;
  font-size: 24px;
  padding: 20px;
}

.sectionCourseMain {
}


a {
  text-decoration: none;
}

.button:hover {
  background: #1557a9;
}

.overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #499bea;
  opacity: .46;
}

.xqhzTea-R {
  padding: 35px 15px 20px 33px;
  float: left;
  width: calc(100% - 266px);
  background-color: #ffffff;
  height: 355px;
  margin-bottom: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.xqhzTea-R h6 {
  font-size: 22px;
  color: #333333;
  padding-bottom: 25px;
  line-height: 1;
  border-bottom: 1px solid #b2bcca;
  margin-right: 18px;
}

.xqhzTea-R ul {
  margin-top: 20px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.xqhzTea-list {
  overflow: hidden;
  margin-top: 50px;
}

.xqhzTea-list img {
  width: 266px;
  height: 355px;
  display: inline-block;
  float: left;
}

.xqhzTea-R li {
  list-style: none;
}
</style>
