<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../assets/img/teachercourse2.png">
      </div>
      <div class="theselect">
        <el-row :gutter="40">
          <el-col :span="10" class="semester" style="display: flex;">
            <el-select v-model="courseData.id" placeholder="课程名称" clearable filterable>
              <el-option
                v-for="item in courseData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="termData.name" placeholder="学期名称" clearable filterable style="margin-left: 20px">
              <el-option
                v-for="item in termData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
            <el-button style="margin-left: 10px" @click="queryTermByName" round>查询</el-button>
          </el-col>
          <el-col :span="4" class="estNewClass">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="createClass">创建学期</el-button>
          </el-col>
        </el-row>
      </div>
      <!--   正在进行中的学期   -->
      <template v-if="this.showTermAlive===1">
        <el-card style="margin-top: 20px" class="theselect">
        <span style="margin-right: 5px;font-size: 22px;font-weight: bold;color: rgba(0,0,0,0.5)"><i
          class="el-icon-loading"></i>正在进行中的学期</span>
          <div class="cards">
            <el-row>
              <el-col :span="7" :sm="12" :md="7" style="margin-top: 10px" v-for="(term, index) in ongoingTerm"
                      :key="term"
                      :offset="1">
                <TermBox :term="term" :index="index"></TermBox>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <!-- 已经完成的学期  -->
        <el-card class="theselect">
        <span style="margin-right: 5px;font-size: 22px;font-weight: bold;color: rgba(255,128,255,0.5)"><i
          class="el-icon-s-claim"></i>已经完成的学期</span>
          <div class="cards">
            <el-row>
              <el-col :span="7" :sm="12" :md="7" style="margin-top: 10px" v-for="(term, index) in completedTerm"
                      :key="term"
                      :offset="1">
                <TermBox :term="term" :index="index"></TermBox>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <!-- 已经结束的学期  -->
        <el-card class="theselect">
        <span style="margin-right: 5px;font-size: 22px;font-weight: bold;color: rgba(255,0,0,0.5)"><i
          class="el-icon-s-release"></i>已经结束的学期</span>
          <div class="cards">
            <el-row>
              <el-col :span="7" :sm="12" :md="7" style="margin-top: 10px" v-for="(term, index) in endedTerm"
                      :key="term"
                      :offset="1">
                <TermBox :term="term" :index="index"></TermBox>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <!--    分页  -->
        <el-row style="margin-left: 400px;margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handlePageSizeChange"
              @current-change="loadTermData"
              :current-page.sync="currentPage"
              :page-sizes="pageSizes"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total">
            </el-pagination>
          </el-col>
        </el-row>
      </template>
      <!--  展示查询名称之后的样式     -->
      <template v-if="showTermAlive===0">
        <el-card class="theselect" style="margin-top: 20px;border-radius: 20px" shadow="always">
        <span style="margin-right: 5px;font-size: 22px;font-weight: bold;color: rgba(255,0,0,0.5)">
          <i class="el-icon-loading"></i>查询的学期</span>
          <div class="cards">
            <el-row style="width: 300px">
              <TermBox :term="termByName"></TermBox>
            </el-row>
          </div>
        </el-card>
      </template>
      <!--   创建学期   -->
      <template v-if="addOrUpdate===1&&confirmDelete===0">
        <el-dialog
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="addTermData">
            <el-form-item label="课程名称">
              <el-select v-model="courseData.id" placeholder="课程名称" clearable filterable>
                <el-option
                  v-for="item in courseData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学期名称">
              <el-input v-model="addTermData.name"></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker
                v-model="addTermData.startTime"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="addTermData.endTime"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createTerm">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import TermBox from '@/components/TermBox'

export default {
  name: 'TeacherClass',
  components: {
    TermBox,
    Header
  },
  data () {
    const courseId = 0
    return {
      termByName: {},
      showTermAlive: 1,//1表示未按名字查询的样式，0表示按名字查询的样式 ，默认为1
      /*分类学期数据*/
      ongoingTerm: [],     //正在进行中的学期
      completedTerm: [],   //已经完成的学期
      endedTerm: [],       //已经结束的学期
      /*分页*/
      currentPage: 1,
      total: 1,
      pageSize: 4,
      pageSizes: [6, 12, 20, 30],
      visible: false,
      task: {
        termId: 0,
        title: ''
      },
      discussion: {
        termId: 0,
        title: '',
        description: ''
      },
      termId: 0,
      confirmDelete: 1,  //为1时，开启是否确认删除这个学期的dialog,其他的为0
      courseId,// 定义一个课程Id,根据id获取到当前的课程
      course: {},
      searchContent: '',
      addOrUpdate: 1, //1表示添加学期，2表示更新学期  3 。表示创建任务  4.表示创建讨论
      teacher: {},
      termData: [],
      addTermData: {}, //添加的学期数据
      editTermData: {}, // 编辑的学期数据
      courseData: [],
      hideUploadAdd: false,
      dialogVisible: false,
    }
  },
  created () {
    this.loadTermData()
    this.loadCourseData()
  },
  methods: {
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
    },
    //创建学期
    createTerm () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/createTerm',
        params: {
          courseId: this.courseData.id,
          name: this.addTermData.name,
          startTime: this.addTermData.startTime,
          endTime: this.addTermData.endTime
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadTermData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    createClass () {
      this.confirmDelete = 0
      this.addOrUpdate = 1
      this.dialogVisible = true
    },
    //跳转到创建讨论的弹窗
    toCreateDiscussion (id) {
      this.dialogVisible = true
      this.addOrUpdate = 4
      this.confirmDelete = 0
      this.discussion.termId = id
    },
    queryTermByName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermByCourseIdAndName',
        params: {
          courseId: this.courseData.id,
          name: this.termData.name,
          withCourse: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            /*分类区分学期的形态*/
            this.ongoingTerm.splice(0, this.ongoingTerm)
            this.completedTerm.splice(0, this.completedTerm)
            this.endedTerm.splice(0, this.endedTerm)
            this.termByName = countData
            this.showTermAlive = 0
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadCourseData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showMyCourses',
        params: {
          currentPage: 1,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.courseData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //加载学期数据
    loadTermData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showMyTerms',
        params: {
          currentPage: this.currentPage,
          pageSize: 6
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data.content
            /*分类区分学期的形态*/
            for (let i = 0; i < countData.length; i++) {
              if (countData[i].status === 'ended') {
                this.endedTerm.push(countData[i])  //加载已经结束的学期
              } else if (countData[i].status === 'ongoing') {
                this.ongoingTerm.push(countData[i])  //加载正在进行的学期
              } else if (countData[i].status === 'completed') {
                this.completedTerm.push(countData[i])  //加载已经结束的学期
              }
            }
            this.total = res.data.data.totalCount
            this.termData = countData
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style>
.hide .el-upload--picture-card {
  display: none !important;
}

/*course message begin*/
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.semester, .status, .estNewClass {
  margin-top: 20px;
}

/*course message end*/
/*card begin*/
.theselect, .cards {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.cards {
  padding: 22px 0 10px 0;
}

.time {
  font-size: 13px;
  color: #999;
}

.image {
  width: 100%;
  display: block;
}

/*card end*/
.pagination {
  margin-top: 25px;
}

.box-card img {
  margin: 0;
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.box-card {
  width: 100%;
}
</style>
