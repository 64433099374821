<template>
  <div class="courseStudy">
    <div class="container">
      <div class="courseTitle">
        <span style="font-size: 20px">{{ courseInfo.name }}</span>
        {{ d_options.video.url }}
      </div>
      <div v-show="hasVideo" class="videoSection">
        <d-player id="player" ref="dplayer"  :options="d_options"
                  style="width: 816px;height: 459px;"></d-player>
      </div>
      <div v-show="hasHtml">
        <iframe :src="htmlUrl" frameborder="0" width="100%"></iframe>
      </div>
      <div v-show="hasQuiz">
        <div class="question" v-for="(question,i) in quiz" :key="question.id">
        </div>
        <div>
        </div>
      </div>
      <div class="courseInfo">
      </div>
    </div>
  </div>
</template>

<script>
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
import Vue from 'vue'
import axios from 'axios'

Vue.use(VueDPlayer)

export default {
  name: 'CourseStudy',
  props: {
    courseInfo: Object
  },
  data () {
    return {
      hasVideo: false,
      hasHtml: false,
      htmlUrl: '',
      hasQuiz: false,
      quiz: [],
      d_options: {
        container: document.getElementById('d-player'),
        video: {
          url: ''
        }
      },
      answerList: []
    }
  },
  watch: {
    courseInfo: {
      deep: true,
      handler (val, old) {
        this.$refs.dplayer.dp.switchVideo({ url: val.chapterInfo.content })
        if (val.chapterInfo.contentType === 'video') {
          this.hasVideo = true
        } else {
          this.hasVideo = false
        }
        if (val.chapterInfo.contentType === 'html') {
          this.hasHtml = true
          this.htmlUrl = val.chapterInfo.content
        } else {
          this.htmlUrl = false
        }
        if (val.chapterInfo.contentType === 'quiz') {
          this.hasQuiz = true
          this.getQuestions(val.id)
        } else {
          this.hasQuiz = false
        }
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.courseStudy {
  width: 100%;
  height: 100%;
  margin-left:160px;
  margin-top: 20px;
  padding: 0;
}

.courseTitle {
  margin-bottom: 20px;
}
</style>
