<template>
  <div>
    <el-row>
      <pre class="description">{{ question.paperQuestion.description }}</pre>
    </el-row>
    <el-row>
      <div v-for="(b) in question.paperQuestion.paperBlanks" :key="b.id">
        <div style="margin: 8px">第{{ b.orderNumber }}空({{ b.score }}分):
          <el-input v-model="b.answer" disabled></el-input>
        </div>
      </div>
    </el-row>
    <el-row style="float: right;">
      <el-row>
        <span>得分:{{ question.score }}'</span>
      </el-row>
      <template v-if="question.score===question.fullScore">
        <el-tag type="success">正确</el-tag>
      </template>
      <template v-if="question.score!==question.fullScore">
        <el-tag type="danger">错误</el-tag>
      </template>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Completion',
  data () {
    return {
    }
  },
  props: {
    question: Object
  },
  created () {
    this.createRecord()
  },
  methods: {
    createRecord () {
      this.question.paperQuestion.paperBlanks.forEach(function (item) {
        this.record.put(item.id, '')
      })
    }
  }
}
</script>

<style scoped>
.description {
  margin: 0;
  font-size: 20px;
}
</style>
