<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png"
             style="position: relative;z-index: -100">
        <span
          style="font-weight: bold;font-size: 28px;position: absolute;margin-top: 50px;margin-left: -1300px;color: #ffffff;mso-border-shadow: yes">
          {{ this.task.title }}</span>
      </div>
      <div style="flex-direction: row;display: flex">
        <el-card shadow="always" style="border-radius: 30px;margin-top: 20px;width:53%">
          <el-row>
            <h3>实时分数详情</h3>
            <el-table
              :data="taskSubmissionData">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column prop="id" label="互评编号" width="100">
              </el-table-column>
              <el-table-column prop="student.name" label="学生姓名" width="120">
              </el-table-column>
              <el-table-column property="score" label="分数" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.score.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column property="scoreType" label="评分形式" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.scoreType==='teacher'">
                    <el-tag type="success">老师评分</el-tag>
                  </template>
                  <template v-if="scope.row.scoreType==='mutual'">
                    <el-tag type="warning">互评评分</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="taskMutualAssessmentCount" label="互评次数" width="100">
              </el-table-column>
              <el-table-column property="realTimeMutualScore" label="实时分数" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.realTimeMutualScore.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                  <el-button size="small" round @click="viewStudentReliability(scope.$index, scope.row)">View
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>
        <el-card shadow="always" style="border-radius: 30px;margin-top: 20px;width:47%;margin-left: 10px">
          <el-row>
            <el-col :span="11">
              <div id="realTime" style="width:300px;height:300px;"></div>
            </el-col>
            <el-col :span="11">
              <div id="recordMutual" style="width:300px;height:300px;"></div>
            </el-col>
            <el-col :span="2">
            <el-button @click="returnData" icon="el-icon-right" size="small" round>返回</el-button>
          </el-col>
          </el-row>
          <el-row>
            <h3>学生成绩数据详情</h3>
            <el-table
              :data="recordMutualData">
              <el-table-column prop="taskSubmissionId" label="互评编号" width="80">
              </el-table-column>
              <el-table-column property="taskSubmissionScore" label="互评分数" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.taskSubmissionScore.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column property="scoreType" label="评分形式" width="80">
                <template slot-scope="scope">
                  <template v-if="scope.row.scoreType==='teacher'">
                    <el-tag type="success">老师评分</el-tag>
                  </template>
                  <template v-if="scope.row.scoreType==='mutual'">
                    <el-tag type="warning">互评评分</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column property="averageScore" label="平均分" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.averageScore.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column property="variance" label="方差" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.variance.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column property="standardDeviance" label="标准差" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.standardDeviance.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                  <el-button size="small" round>View</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'TaskSubmissionDetail',
  components: {
    TermHeader
  },
  data () {
    return {
      taskNum: [],
      countData: [],
      countData1: [],
      level11: 0,
      level12: 0,
      level13: 0,
      level14: 0,
      level15: 0,
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      variance: [],
      standardDeviance: [],
      task: {},     //获得task对象获取名称值
      termId: 0,
      taskId: 0,
      taskSubmissionData: [],   //获得taskSubmission的数据
      recordMutualData: []            //获得计算数据
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  created () {
    this.termId = this.$route.query.termId
    this.taskId = this.$route.query.taskId
    //获得task的名字
    this.loadTaskName()
    //获得taskId
    this.loadTaskSubmission()
    //获得计算数据
    this.loadRecordSubmission()
  },
  methods: {
    //跳转学生的信度页面
    viewStudentReliability (index, rows) {
      this.$router.push({
        path: '/taskMutualDetail',
        name: 'TaskMutualDetail',
        query: {
          termId: this.termId,
          taskSubmissionId: rows.id
        }
      })
    },
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    returnData(){
      this.$router.go(-1)
    },
    //加载任务提交数据
    loadTaskSubmission () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showTaskSubmissionsByTaskIdWithTaskMutualAssessmentsAndRealTimeMutualScore',
        params: {
          taskId: this.taskId,
          withStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const counterData = res.data.data
            this.taskSubmissionData = counterData
            for (let i = 0; i < counterData.length; i++) {
              this.taskNum.push(counterData[i].realTimeMutualScore)
            }
            const cout = this.taskNum.sort()
            for (let i = 0; i < cout.length; i++) {
              if (cout[i] >= 0 && cout[i] <= 1) {
                this.level1 += 1
              } else if (cout[i] > 1 && cout[i] <= 2) {
                this.level2 += 1
              } else if (cout[i] > 2 && cout[i] <= 3) {
                this.level3 += 1
              } else if (cout[i] > 3 && cout[i] <= 4) {
                this.level4 += 1
              } else if (cout[i] > 4 && cout[i] <= 5) {
                this.level5 += 1
              } else if (cout[i] > 5 && cout[i] <= 6) {
                this.level6 += 1
              } else if (cout[i] > 6 && cout[i] <= 7) {
                this.level7 += 1
              } else if (cout[i] > 7 && cout[i] <= 8) {
                this.level8 += 1
              } else if (cout[i] > 8 && cout[i] <= 9) {
                this.level9 += 1
              } else if (cout[i] > 9 && cout[i] <= 10) {
                this.level10 += 1
              }
            }
            for (let i = 0; i < cout.length; i++) {
              if (cout[i] >= 0 && cout[i] <= 2) {
                this.level11 += 1
              } else if (cout[i] > 2 && cout[i] <= 4) {
                this.level12 += 1
              } else if (cout[i] > 4 && cout[i] <= 6) {
                this.level13 += 1
              } else if (cout[i] > 6 && cout[i] <= 8) {
                this.level14 += 1
              } else if (cout[i] > 8 && cout[i] <= 10) {
                this.level15 += 1
              }
            }
            this.countData = [this.level1, this.level2, this.level3, this.level4, this.level5, this.level6, this.level7, this.level8, this.level9, this.level10]
            this.countData1 = [this.level11, this.level12, this.level13, this.level14, this.level15]
            this.loadTaskCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*展示计算数据"方差"，平均值，标准差*/
    loadRecordSubmission () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showMutualRecordsByTaskId',
        params: {
          taskId: this.taskId,
        }
      })
        .then((res) => {
          if (res.data.status) {
            const counterData = res.data.data
            this.recordMutualData = counterData
            for (let i = 0; i < counterData.length; i++) {
              this.variance.push(counterData[i].variance)
              this.standardDeviance.push(counterData[i].standardDeviance)
            }
            this.loadMutualCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*展示实时分数的图表*/
    loadTaskCharts () {
      const realTime = this.$echarts.init(document.getElementById('realTime'))
      let option = {
        title: {
          text: '学生实时成绩表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          },
          {
            type: 'category',
            position: 'bottom',
            data: [2, 4, 6, 8, 10],
            show: false
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: this.countData,
            color: '#7FFFAA',
            xAxisIndex: 0//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            xAxisIndex: 1, //使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
            data: this.countData1,
          }
          //鼠标悬停时
        ]
      }
      realTime.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        realTime.resize()
      })
    },
    /*获得计算数据的图表*/
    loadMutualCharts () {
      //方差
      const recordMutual = this.$echarts.init(document.getElementById('recordMutual'))
      let option = {
        title: {
          text: '互评分数视图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: '',
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'line',
            smooth: true,
            data: this.variance,
            color: '#FFFAA0'
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            data: this.standardDeviance,
            color: 'rgb(42,170,227)'
          }
        ]
      }
      recordMutual.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        recordMutual.resize()
      })
    },
    /*获得任务名称*/
    loadTaskName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/showTaskById',
        params: {
          id: this.taskId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const taskData = res.data.data
            this.task = taskData
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.fixedBox {
  margin-top: 100px;
  top: 0;
  position: fixed;
  z-index: 100;
}

#sideBox {
  transition: 2s;
}
</style>
