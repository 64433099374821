import { render, staticRenderFns } from "./TermBox.vue?vue&type=template&id=52aa267a&scoped=true&"
import script from "./TermBox.vue?vue&type=script&lang=js&"
export * from "./TermBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52aa267a",
  null
  
)

export default component.exports