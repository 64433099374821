<template>
  <div class="infoContainer">
    <p style="font-size: 24px">Step 3 : 填写信息完成注册</p>
    <div class="infoForm">
      <el-form :label-position="left" @submit.native.prevent ref="infoForm" :rules="rules" :model="infoForm">
        <el-form-item label-width="100px" label="用户名 :" prop="usernameR">
          <el-input type="text" aria-placeholder="用户名" v-model="infoForm.username" v-on:change="passUserName"
                    style="width:350px"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="密  码:" prop="passwordR">
          <el-input type="password" aria-placeholder="请输入密码..." v-model="infoForm.password"
                    style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="确认密码:" prop="passwordR">
          <el-input type="password" aria-placeholder="请再次确认密码..." v-model="infoForm.confirmPassword"
                    v-on:change="passPassword"
                    style="width: 350px"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterStepThree',
  data: function () {
    return {
      infoForm: {
        username: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        usernameR: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur'
          }
        ],
        passwordR: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    passUserName () {
      this.$emit('func', this.infoForm.username)
    },
    passPassword () {
      if (this.infoForm.password !== this.infoForm.confirmPassword) {
        this.$message.error('两次输入的密码不一致！请重试！')
      } else {
        this.$emit('funct', this.infoForm.confirmPassword)
      }
    }
  }
}
</script>

<style scoped>
.infoContainer {
  padding: 20px 30px;
  box-sizing: border-box;
}

.infoForm {
  margin: 15px auto;
}
</style>
