<template>
  <el-row>
    <pre class="description">{{ question.paperQuestion.description }}({{ question.paperQuestion.score }}分)</pre>
  </el-row>
  <p>
    {{ question.record }}
  </p>
  <el-row style="float: right;margin-top: -80px">
    <el-row>
      <span>得分:{{ question.score }}'</span>
    </el-row>
    <template v-if="question.score===question.fullScore">
      <el-tag type="success">正确</el-tag>
    </template>
    <template v-if="question.score!==question.fullScore">
      <el-tag type="danger">错误</el-tag>
    </template>
  </el-row>
</template>

<script>
export default {
  name: 'SubjectiveQuestion',
  props: {
    question: Object
  }
}
</script>

<style scoped>

.description {
  margin: 0;
  font-size: 20px;
}
</style>
