<template>
  <div id="comment" style="width:600px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'studentCharts',
  data () {
    return {
      termId: 0,
      commentData: [],/*评论数据*/
      commentName: []
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点
  },
  mounted () {
    /*展示学生已经完成的数据*/
    this.loadCommentCountData()
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*加载讨论完成的数据*/
    loadCommentCountData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/countCommentsByTermIdGroupByDiscussionId',
        params: {
          termId: this.termId,
          setDiscussion: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.commentData = res.data.data
            const countData = res.data.data
            for (let i = 0; i < countData.length; i++) {
              this.commentName.push(countData[i].discussion.title)
            }
            console.log(this.commentName)
            this.comment()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    comment () {
      let gayData = this.convertKey(this.commentData, ['id', 'value', 'name'])
      // 基于准备好的dom，初始化echarts实例
      const comment = this.$echarts.init(document.getElementById('comment'))
      var option = {
        title: {
          text: '评论数量表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: this.commentName,
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '完成',
            type: 'bar',
            data: gayData,
            color: '#87CEEB'
          },
        ]
      }
      comment.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        comment.resize()
      })
    }
    ,
  }
}
</script>

<style scoped>

</style>

