<template>
  <div id="score" style="width:360px;height:280px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExamScore',
  mounted () {
    this.termId = this.$route.query.termId
    this.examId = this.$route.query.examId
    this.loadExamScore()
  },
  data () {
    return {
      /*等级*/
      termId: 0,
      examId: 0,
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      examNum: [],
      countData: [],
      countData1: [],
    }
  },
  methods: {
    /*加载学生考试成绩*/
    loadExamScore () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/score/showResultsByExamId',
        params: {
          examId: this.examId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.examNum = countData
            for (var i = 0; i < this.examNum.length; i++) {
              for (var j = i + 1; j < this.examNum.length; j++) {
                //如果第一个比第二个大，就交换他们两个位置
                if (this.examNum[i] > this.examNum[j]) {
                  let temp = this.examNum[i]
                  this.examNum[i] = this.examNum[j]
                  this.examNum[j] = temp
                }
              }
            }
            const cout = this.examNum
            for (let i = 0; i < cout.length; i++) {
              if (cout[i] >= 0 && cout[i] <= 10) {
                this.level1 += 1
              } else if (cout[i] > 10 && cout[i] <= 20) {
                this.level2 += 1
              } else if (cout[i] > 20 && cout[i] <= 30) {
                this.level3 += 1
              } else if (cout[i] > 30 && cout[i] <= 40) {
                this.level4 += 1
              } else if (cout[i] > 40 && cout[i] <= 50) {
                this.level5 += 1
              } else if (cout[i] > 50 && cout[i] <= 60) {
                this.level6 += 1
              } else if (cout[i] > 60 && cout[i] <= 70) {
                this.level7 += 1
              } else if (cout[i] > 70 && cout[i] <= 80) {
                this.level8 += 1
              } else if (cout[i] > 80 && cout[i] <= 90) {
                this.level9 += 1
              } else if (cout[i] > 90 && cout[i] <= 100) {
                this.level10 += 1
              }
            }
            this.countData = [this.level1, this.level2, this.level3, this.level4, this.level5, this.level6, this.level7, this.level8, this.level9, this.level10]
            this.countData1 = [this.level1 + this.level2, this.level3 + this.level4, this.level5 + this.level6, this.level7 + this.level8, this.level9 + this.level10]
            this.loadScore()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载考试信息*/
    loadScore () {
      //  console.log(kData)
      const score = this.$echarts.init(document.getElementById('score'))
      var option = {
        title: {
          text: '学生成绩表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          },
          {
            type: 'category',
            data: [20, 40, 60, 80, 100],
            show: false
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',

            type: 'bar',
            data: this.countData,
            color: '#7FFFAA',
            xAxisIndex: 0//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            data: this.countData1,
            color: 'rgba(0,0,0,0.5)',
            xAxisIndex: 1//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          }
        ]
      }
      score.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        score.resize()
      })
    }
  }
}
</script>

<style scoped>

</style>
