<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../assets/img/teachercourse2.png">
      </div>
      <el-row>
        <el-col>
          <h1>欢迎来到学期管理中心!!!</h1>
        </el-col>
      </el-row>

    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'TermManage',
  components: { TermHeader },
  data () {
    return {
      termId: 0
    }
  },
  created () {
    this.termId = this.$route.query.termId
  },
  methods: {

  }
}

</script>

<style scoped>

.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

</style>
