<template>
  <div>
    <footer class="padding-top">
      <div class="container">
        <el-row gutter="20">
          <el-col :xl="6" :sm="24" :md="6">
            <img src="../assets/img/logo.png" alt="" style="width: 150px">
            <a href="#"><p>学习课程</p></a>
            <a><p>教师团队</p></a>
            <a><p>学习社区</p></a>
          </el-col>
          <el-col :xl="6" :sm="24" :md="6">
            <h3>关于我们</h3>
            <a><p>关于我们</p></a>
            <a><p>联系我们</p></a>
            <a><p>意见反馈</p></a>
            <a><p>常见问题</p></a>
          </el-col>
          <el-col :xl="6" :sm="24" :md="6">
            <h3>关注我们</h3>
            <img alt="" src="../assets/img/code.png">
          </el-col>
          <el-col :xl="6" :sm="24" :md="6">
            <h3>联系我们</h3>
            <p>您可以和我们联系，提出您的建议。</p>
            <img src="../assets/img/earth.png">
          </el-col>
        </el-row>
      </div>
    </footer>
    <div class="copyright">
      <div style="text-align: center">
        <p>Copyright &copy; 2021 湖南农业大学 信息与智能科学技术学院</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  background: #7474BF; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #348AC7, #7474BF); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding-top: 90px;
  padding-bottom: 40px;
  text-align: center;
  color: #fff;
}

footer, footer p, .footer_panel ul.social_icon li a, .copyright, .copyright p {
  color: #fff;
}

footer h3 {
  display: block;
  color: #343a40;
}
footer p{
  color: #343a40;
}
footer .footer_panel p.address > i, .services p.address > i {
  display: inline-block;
  font-size: 19px;
  margin-left: -30px;
  vertical-align: middle;
  width: 25px;
}

.copyright {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  padding: 24px 0;
}

.copyright p {
  margin: 0;
  font-size: 0.875em;
}

.copyright p > a:hover {
  color: #ffb600;

}

a {
  color: #fff;
  text-decoration: none;
  transition: all 0.35s ease 0s;
}

a :hover {
  color: #0c5460;
}
</style>
