<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png"
             style="position: relative;z-index: -100">
        <span
          style="font-weight: bold;font-size: 28px;position: absolute;margin-top: 50px;margin-left: -1300px;color: #ffffff;mso-border-shadow: yes">
          {{ this.chapter.name }}</span>
      </div>
      <el-row>
        <el-tabs type="border-card">
          <!--    获得课程的基本信息  -->
          <el-tab-pane label="单选题">
          </el-tab-pane>
          <el-tab-pane label="多选题">
          </el-tab-pane>
          <el-tab-pane label="填空题">
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-main>
  </el-container>

</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'

export default {
  name: 'CreateQuestion',
  components: {
    Header
  },
  created () {
    this.chapterId = this.$route.query.chapterId
    this.loadChapterData()
  },
  data () {
    return {
      chapterId: 0,
      chapter: {}
    }
  },
  methods: {
    loadChapterData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/showChapterById',
        params: {
          id: this.chapterId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.chapter = res.data.data
            console.log('单个章节信息为:', this.chapter)
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.row-bg {
  padding: 10px 0;
}
</style>
