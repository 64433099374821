<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-card shadow="always" style="border-radius: 30px;margin-top: 30px">
        <el-row style="margin-left: 30px;margin-top: 30px">
          <i class="el-icon-user-solid" style="font-weight: bold;font-size: 30px">{{ this.student.name }}</i>
        </el-row>
        <el-col :span="8" :offset="4">
          <div id="score" style="width:360px;height:280px;"></div>
        </el-col>
        <el-col :span="8">
          <div id="grade" style="width:360px;height:280px;"></div>
        </el-col>
      </el-card>
      <el-card shadow="always" style="border-radius: 30px;width: 100%;margin-top: 10px">
        <span style="font-weight: bold;font-size: 30px;color: rgba(255,0,0,0.5)">Task Information</span>
        <el-table
          :data="taskData"
          style="width: 100%;margin-left: 200px">
          <el-table-column
            prop="id"
            label="任务编号"
            width="120">
          </el-table-column>
          <el-table-column
            prop="title"
            label="任务名称"
            width="120">
          </el-table-column>
          <el-table-column
            prop="description"
            label="任务详情"
            width="200">
          </el-table-column>
          <el-table-column
            prop="deadline"
            label="截止时间"
            width="180">
          </el-table-column>
          <el-table-column property="score.result" label="状态" width="100">
            <template slot-scope="scope">
              <template v-if="scope.row.status==='completed'">
                <el-tag type="success">已完成</el-tag>
              </template>
              <template v-if="scope.row.status==='mutual'">
                <el-tag type="warning">互评</el-tag>
              </template>
              <template v-if="scope.row.status==='normal'">
                <el-tag type="warning">正常</el-tag>
              </template>
              <template v-if="scope.row.status==='ongoing'">
                <el-tag>正常</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            property="myTaskSubmission"
            label="我的任务互评"
            width="160">
            <template slot-scope="scope">
              <template v-if="scope.row.myTaskSubmission===null">
                <el-tag type="danger">无</el-tag>
              </template>
              <template v-if="scope.row.myTaskSubmission!==null">
                <el-popover trigger="hover" placement="top">
                  <span style="font-size: 20px;font-weight: bold">得分:{{ scope.row.myTaskSubmission.score }}</span>
                  <el-row>
                    <template v-if="scope.row.myTaskSubmission.scoreType==='teacher'">
                      <el-tag type="success">老师评分</el-tag>
                    </template>
                  </el-row>
                  <el-row>
                    <template v-if="scope.row.myTaskSubmission.scoreType==='mutual'">
                      <el-tag type="warning">互评评分</el-tag>
                    </template>
                  </el-row>
                  <div slot="reference" class="name-wrapper">
                    <img :src="scope.row.myTaskSubmission.submissionUrl" style="height: 80px;width: 80px">
                  </div>
                </el-popover>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="small" round @click="viewStudentTask(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'showStudentChapter',
  components: {
    TermHeader
  },
  created () {
    this.termId = this.$route.query.termId
    this.studentId = this.$route.query.studentId
    this.loadStudentData()
    this.loadStudentTask()  /*加载学生完成课程的数据*/
    this.loadTaskGrade()
  },
  data () {
    return {
      /*分页*/
      /*等级*/
      S: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      taskData: [],
      taskNum: [],
      student: {},
      termId: 0,
      studentId: 0
    }
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCourseData()
    },
    /*查看学生的考试成绩*/
    viewStudentTask (id) {
      this.$router.push({
        path: '/taskAverage',
        name: 'TaskAverage',
        query: {
          termId: this.termId,
          taskId: id
        }
      })
    },
    /*加载学生课程信息*/
    loadStudentTask () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTasksWithTaskSubmissionByTermIdAndStudentId',
        params: {
          termId: this.termId,
          studentId: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.taskData = countData
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载学生当前任务的层级*/
    loadTaskGrade () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showScoresByTermIdAndStudentId',
        params: {
          termId: this.termId,
          studentId: this.studentId
        }
      }).then((res) => {
        if (res.data.status) {
          const countData = res.data.data
          for (let i = 0; i < countData.length; i++) {
            this.taskNum.push(countData[i])
            if (countData[i] <= 2 && countData[i] >= 0) {
              this.D += 1
            } else if (countData[i] > 2 && countData[i] <= 4) {
              this.C += 1
            } else if (countData[i] <= 6 && countData[i] > 4) {
              this.B += 1
            } else if (countData[i] <= 8 && countData[i] > 6) {
              this.A += 1
            } else if (countData[i] > 8 && countData[i] <= 10) {
              this.S += 1
            }
          }
          this.loadGrade()
          this.loadScore()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    /*加载考试信息*/
    loadScore () {
      const score = this.$echarts.init(document.getElementById('score'))
      let option = {
        title: {
          text: '学生考试成绩散点图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        xAxis: {},
        yAxis: {},
        series: [
          {
            symbolSize: 20,
            data: this.taskNum,
            type: 'scatter',
            markLine: {
              data: [{
                type: 'average',
                name: 'Avg'
              }]
            }
          }
        ]
      }
      score.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        score.resize()
      })
    },
    loadGrade () {
      // 基于准备好的dom，初始化echarts实例
      const grade = this.$echarts.init(document.getElementById('grade'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生任务完成图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}次'
        },
        legend: {
          bottom: 5,
          left: 'center',
          data: ['一星', '两星', '三星', '四星', '五星']
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.D,
                name: '一星'
              },
              {
                value: this.C,
                name: '两星'
              },
              {
                value: this.B,
                name: '三星'
              },
              {
                value: this.A,
                name: '四星'
              },
              {
                value: this.S,
                name: '五星'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      grade.setOption(option)
      window.addEventListener('resize', function () {
        grade.resize()
      })
    },
    loadStudentData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/student/showStudentById',
        params: {
          id: this.studentId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.student = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

</style>



