import { render, staticRenderFns } from "./ExamAverageStudent.vue?vue&type=template&id=0171aa4c&scoped=true&"
import script from "./ExamAverageStudent.vue?vue&type=script&lang=js&"
export * from "./ExamAverageStudent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0171aa4c",
  null
  
)

export default component.exports