<template>
  <div id="counter" style="width:550px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'chapterCharts',
  props: {},
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点
  },
  mounted () {
    /*展示没完成章节的数据*/
    this.loadChapterAllFinished()
  },
  data () {
    return {
      finishChapterData: [],
      /*已经完成的章节数据*/
      finishChapterNum: [],
      allFinishChapterData: [],
      termId: 0,
    }
  },

  methods: {
    /*展示完成章节的数据*/
    /*展示没完成章节的数据*/
    loadChapterAllFinished () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/showCompletenessForChapterByTermId',
        params: {
          termId: this.termId,
          withChapterName: true,
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.allFinishChapterData = countData
            this.counter()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    /*改变keys的方法*/
    counter () {
      let gayData = this.convertKey(this.allFinishChapterData, ['id', 'name', 'value'])
      //  console.log(kData)
      const counter = this.$echarts.init(document.getElementById('counter'))
      var option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: function (params) {
            //console.log(params)
            let str = ''
            for (let i = 0; i < params.length; i++) { // 添加单位(*^▽^*)
              str += '<br/>' + params[i].data.name + ':' + params[i].value.toFixed(2) * 100 + '%'
              //console.log(params[i].data.name.name)
            }
            return str
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: '',
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: gayData,
            color: '#7FFFAA'
          },
          {
            name: '',
            type: 'line',
            smooth:true,
            data: gayData,
            color: 'rgba(255,0,0,0.8)'
          }
        ]
      }
      counter.on('click', params => {
        this.$router.push({
          path: '/chapterData',
          name: 'ChapterData',
          query: {
            termId: this.termId,
            chapterId: params.data.id
          }
        })
        /// console.log('=====', index, '====')
        console.log(params)
      })
      counter.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        counter.resize()
      })
    },
  }
}
</script>

<style scoped>

</style>
