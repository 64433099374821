<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png">
      </div>
      <el-tabs>
        <el-tab-pane label="考核管理">
          <el-card shadow="always">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="3"><span>随堂练习</span></el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="4" style="display: flex">权重
                <el-input v-model="course_practice"
                          style="width: 50%;margin: 0px 7px"></el-input>
                %
              </el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="8"><span>计算规则：取全部随堂练习的平均正确率</span></el-col>
            </el-row>
          </el-card>
          <el-card shadow="always">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="3"><span>音视频资源</span></el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="4" style="display: flex">权重
                <el-input v-model="video_resources"
                          style="width: 50%;margin: 0px 7px"></el-input>
                %
              </el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="8"><span>计算规则：要求观看完全</span></el-col>
            </el-row>
          </el-card>
          <el-card shadow="always">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="3"><span>文本资源</span></el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="4" style="display: flex">权重
                <el-input v-model="text_resources"
                          style="width: 50%;margin: 0px 7px"></el-input>
                %
              </el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="8"><span style="display: flex">计算规则：要求观看超过<el-input v-model="text_time"
                                                                                 style="width: 25%;margin: 0px 7px"></el-input>分钟</span>
              </el-col>
            </el-row>
          </el-card>
          <el-card shadow="always">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="3"><span>章节测试</span></el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="4" style="display: flex">权重
                <el-input v-model="chapter_test"
                          style="width: 50%;margin: 0px 7px"></el-input>
                %
              </el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="8"><span>计算规则：取所有章节测试的平均分</span></el-col>
            </el-row>
          </el-card>
          <el-card shadow="always">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="3"><span>在线考试</span></el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="4" style="display: flex">权重
                <el-input v-model="online_exam"
                          style="width: 50%;margin: 0px 7px"></el-input>
                %
              </el-col>
              <el-divider direction="vertical"></el-divider>
              <el-col :span="8"><span>计算规则：取在线考试的平均分</span></el-col>
            </el-row>
          </el-card>
          <el-row>
            <el-col :offset="22" :span="2">
              <el-button type="primary">保存</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'CertificateRule',
  components: {
    TermHeader
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
</style>
