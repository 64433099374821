<template>
  <div class="loginContainer">
    <div class="loginForm animated zoomIn">
      <div class="logo">
        <img src="https://mooc-webfront-1303967055.cos.ap-shanghai.myqcloud.com/moocImg/logo.png" alt="">
      </div>
      <el-form ref="loginFormRef" :model="loginform" class="loginInput" :rules="rules">
        <el-form-item prop="username">
          <el-input v-model="loginform.username" placeholder="Phone Or Email"
                    prefix-icon="el-icon-s-custom" auto-complete="on"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-show="!loginByCode">
          <el-input v-model="loginform.password" type="password" placeholder="Password"
                    prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item prop="code" v-show="loginByCode">
          <el-input v-model="loginform.code" placeholder="Verification Code"
                    prefix-icon="el-icon-bell">
            <el-button style="float: right"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-link @click="loginByCode=!loginByCode" style="font-size: .8rem">切换登录方式</el-link>
          <div class="otherBtn">
            <el-button v-show="loginByCode" type="warning" icon="el-icon-bell" round size="small" @click="getLoginCode"
                       :disabled="disableCode" id="btnForCode">
              {{ codeBtnTag }}
            </el-button>
            <el-tooltip class="item" effect="light" content="点击注册新的账号" placement="bottom-end">
              <el-button v-show="!loginByCode" type="warning" icon="el-icon-edit" round size="small"
                         @click="goRegister">Register
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="light" content="重置信息" placement="bottom-start">
              <el-button type="info" icon="el-icon-refresh" circle size="small" @click="resetLoginForm"></el-button>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round icon="el-icon-position" class="loginBtn" @click="login"
                     @keyup.enter="login">Login
          </el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      clock: '',
      nums: 10,
      codeBtnTag: '获得验证码',
      btn: document.getElementById('btnForCode'),
      loginform: {
        username: '',
        password: '',
        code: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          {
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ]
      },
      loginByCode: false,
      disableCode: false
    }
  },
  created () {
    this.checkLogin()
  },
  mounted () {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    keyDown (e) {
      if (e.keyCode === 13) {
        this.login() // 定义的登录方法
      }
    },
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://192.168.43.27:9000/teacher/checkLogin'
      }).then(res => {
        if (res.data.status) {
          this.$message.error('您已经登录!')
          this.$router.push({ path: '/' })
        }
      })
    },
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    getLoginCode () {
      this.disableCode = true
      this.clock = setInterval(this.doLoop, 1000)
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/teacher/getLoginVerificationCode',
        params: {
          loginId: this.loginform.username
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            this.$message.success('验证码已发送,请及时查收')
          } else {
            this.$message.error(res.data.message)
            this.disableCode = false
          }
        } else {
          this.$message.error('验证码发送失败，请检查网络连接并重试!')
        }
      })
    },
    login () {
      this.$refs.loginFormRef.validate(valid => {
        if (!valid) {
          this.$message.error('登录失败!请检查登录信息')
        } else {
          if (this.loginByCode) {
            axios({
              method: 'POST',
              url: 'https://mooc.kirinnorine.top/teacher/loginWithVerificationCode',
              params: {
                loginId: this.loginform.username,
                verificationCode: this.loginform.code
              }
            }).then(res => {
              if (res.data.status) {
                this.$message.success('登录成功')
                this.$router.go(-1)
              } else {
                this.$message.error(res.data.message)
              }
            })
          } else {
            axios({
              method: 'POST',
              url: 'https://mooc.kirinnorine.top/teacher/loginWithPassword',
              params: {
                loginId: this.loginform.username,
                password: this.loginform.password
              }
            }).then((res) => {
              console.log(res)
              if (res.data.status) {
                this.$message.success('登录成功')
                this.$router.go(-1)
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        }
      })
    },
    goRegister () {
      this.$router.push({ path: '/register' })
    },
    doLoop () {
      this.nums--
      if (this.nums > 0) {
        this.codeBtnTag = this.nums + 's'
      } else {
        clearInterval(this.clock) // 清除js定时器
        this.disableCode = false
        this.codeBtnTag = '重新获取验证码'
        this.nums = 10 // 重置时间
      }
    }
  }
}
</script>

<style scoped>

.loginContainer {
  width: 100%;
  height: 100%;
  background: url("https://mooc-webfront-1303967055.cos.ap-shanghai.myqcloud.com/moocImg/index01.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f8f9fa;
}

.loginForm {
  height: 380px;
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

}

.loginBtn {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.loginInput {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 35px;
  box-sizing: border-box;
  margin-top: 20px;
}

.logo {
  text-align: center;
  margin: 15px 0;
}

.logo img {
  width: 120px;
  margin-top: 20px;
}

.otherBtn {
  display: flex;
  float: right;
}
</style>
