<template>
  <div id="average" style="width:400px;height:300px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TaskAverage',
  data () {
    return {
      termId: 0,
      taskAverageData: []
    }
  },
  mounted () {
    this.termId = this.$route.query.termId
    this.loadTaskAverageScore()
  },
  methods: {
    /*改变keys的方法*/
    convertKey (arr, key) {
      let newArr = []
      arr.forEach((item, index) => {
        let newObj = {}
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]]
        }
        newArr.push(newObj)
      })
      return newArr
    },
    loadTaskAverageScore () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top//taskSubmission/showAverageByTermIdGroupByTaskId',
        params: {
          termId: this.termId,
          setTask: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            this.taskAverageData = countData
            this.loadTaskCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*加载任务的表图*/
    loadTaskCharts () {
      let gayData = this.convertKey(this.taskAverageData, ['value', 'name', 'id'])
      //  console.log(kData)
      const average = this.$echarts.init(document.getElementById('average'))
      let option = {
        title: {
          text: '任务详细表'
        },
        dataZoom: [{
          type: 'slider',
          show: true, //flase直接隐藏图形
          xAxisIndex: [0],
          left: '5%', //滚动条靠左侧的百分比
          right: 50,
          bottom: -5,
          start: 35,//滚动条的起始位置
          end: 65 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: function (params) {
            //console.log(params);
            let str = ''
            for (let i = 0; i < params.length; i++) { // 添加单位(*^▽^*)
              str += '<br/>' + params[i].data.name.title + ' ' + params[i].value
              //console.log(params[i].data.name.name)
            }
            return str
          }
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: '',
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: gayData,
            color: '#7FFFAA',
            itemStyle: {
              color: function (params) {
                /*        let colorList = ['rgb(164,205,238)', 'rgb(42,170,227)', 'rgb(25,46,94)', 'rgb(195,229,235)', 'rgb(195,229,235)']
                        return colorList[params.dataIndex]*/
                let c = ''
                if (params.value >= 8.0) {
                  c = 'rgb(164,205,238)'
                } else {
                  c = 'rgb(25,46,94)'
                }
                return c
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            }
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            data: gayData,
            itemStyle: {
              normal: {
                color: '#1F824E',
                lineStyle: {
                  color: '#FFB6C1'
                }
              }
            }
          }
          //鼠标悬停时
        ]
      }
      average.on('click', params => {
        this.$router.push({
          path: '/taskAverage',
          name: 'TaskAverage',
          query: {
            termId: this.termId,
            taskId: params.data.id
          }
        })
        console.log(params)
      })
      average.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        average.resize()
      })
    }
  }
}
</script>

<style scoped>

</style>
