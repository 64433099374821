<template>
  <div class="border-bottom" style="background-color: #ffffff">
    <div class="container">
      <div class="row header">
        <div class="logo_img" @click="goHome()">
          <img src="https://mooc-webfront-1303967055.cos.ap-shanghai.myqcloud.com/moocImg/logo.png" alt=""
               style="cursor: pointer">
        </div>
        <el-menu mode="horizontal"
                 class="headerMenu" default-active="1">
          <!--          <el-menu-item v-for="item in navItems" :index="item.index" :key="item.url">
                      <el-link @click="backurl(item.url)" style="margin-top: 10px">{{ item.tab }}</el-link>
                    </el-menu-item>-->
          <el-menu-item @click="goTerm">我的学期</el-menu-item>
          <el-menu-item @click="goSituation(termId)">学情分析</el-menu-item>
          <el-menu-item @click="goStudent(termId)">学生管理</el-menu-item>
          <el-menu-item @click="goTask(termId)">任务管理</el-menu-item>
          <el-menu-item @click="goDiscussion(termId)">讨论管理</el-menu-item>
          <el-menu-item @click="goReSource(termId)">资源管理</el-menu-item>
          <el-menu-item @click="goCertificate(termId)">证书管理</el-menu-item>
          <el-menu-item @click="goExam(termId)">考试管理</el-menu-item>
          <el-menu-item>
            <div v-if="!(user.username==='')">
              <el-avatar :src="user.avatarUrl" style="margin-right: 5px"></el-avatar>
              <el-link @click="goTeacherPage"> {{ user.username }}</el-link>
              <el-link style="margin-left: 20px" @click="logout">Logout</el-link>
            </div>
            <div v-else>
              <el-link @click="goLogin">登录</el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link @click="goRegister">注册</el-link>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Header',
  mounted () {
    var vm = this
    window.onscroll = function () {
      if (document.documentElement.scrollTop > 100) {
        vm.isActive = true
      } else {
        vm.isActive = false
      }
    }
    vm.termId = vm.$route.query.termId
    console.log('=============', vm.termId)
  },
  data () {
    return {
      termId: 0,
      user: {
        username: '',
        avatarUrl: ''
      },
      searchContent: '',
    }
  },
  methods: {

    //回到我的学期页面
    goTerm () {
      this.$router.push({ path: '/teacherclass' })
    },
    goTeacherPage () {
      this.$router.push({ path: '/teacherinfo' })
    },
    goLogin () {
      this.$router.push({ path: '/login' })
    },
    goRegister () {
      this.$router.push({ path: '/register' })
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    //跳转到学生考试的页面
    goExam (id) {
      this.$router.push({ path: '/examManage?termId=' + id })
    },
    //跳转到查看学生评论的页面
    goSituation (id) {
      this.$router.push({ path: '/situationanalysis?termId=' + id })
    },
    //跳转到查看任务的页面
    goStudent (id) {
      this.$router.push({ path: '/sectionmanage?termId=' + id })
    },
    //跳转到查看学生评论的页面
    goDiscussion (id) {
      this.$router.push({ path: '/commentmanage?termId=' + id })
    },
    //跳转到查看任务的页面
    goTask (id) {
      this.$router.push({ path: '/taskmanage?termId=' + id })
    },
    goReSource (id) {
      this.$router.push({ path: '/addresource?termId=' + id })
    },
    /*跳转到证书管理*/
    goCertificate (id) {
      this.$router.push({ path: '/certificateScore?termId=' + id })
    },
    logout () {
      if (confirm('确定要退出登录吗?')) {
        axios({
          method: 'GET',
          url: 'https://mooc.kirinnorine.top/teacher/logout'
        }).then(res => {
          if (res.status) {
            this.$message.success('登出成功')
            this.user.username = ''
            this.$router.push({ path: '/' })
          }
        })
      }
    }
  },
  created () {
    axios({
      methods: 'GET',
      url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
    }).then(response => {
      // console.log('login:', response.data)
      this.user.username = response.data.data.name
      const email = response.data.data.email
      if (email.substring(email.length - 7) === '@qq.com') {
        const qqId = email.substring(0, email.indexOf('@'))
        this.user.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
      }
    })
  },
  handleSelect (key, keyPath) {
    console.log(key, keyPath)
  }
}
</script>

<style scoped>
.logo_img {
}

.logo_img img {
  width: 150px;
  margin-left: -140px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.headerMenu {
  border: 0 !important;
}

.header {
  justify-content: space-between;
}
</style>

