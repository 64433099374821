<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="../../assets/img/teachercourse.png">
      </div>
      <el-row class="selectedColumn">
        <el-col :span="7" class="search">
          <el-input placeholder="请输入课程名" v-model="courseData.name"></el-input>
          <el-button icon="el-icon-search" type="primary" style="margin-left: 10px" @click="searchCourse"></el-button>
        </el-col>
        <el-col :offset="1">
          <el-button type="primary" round @click="createCourse">新增课程</el-button>
        </el-col>
      </el-row>
      <div class="box-card" v-for="item in courseData" :key="courseData" style="margin-top: 10px">
        <el-row>
          <el-col :span="7">
            <img v-if="item.courseInfo!==null"
                 :src="item.courseInfo.imageUrl"
                 alt="">
            <el-image v-else>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-col>
          <el-col :span="17">
            <div class="planMsg">
              <template v-if="item.status==='private'">
                <el-tag size="mini" type="warning">私有课程</el-tag>
              </template>
              <template v-if="item.status==='public'">
                <el-tag size="mini" type="success">公开课程</el-tag>
              </template>
              <el-col>
                <span style="font-weight: bold;font-size: 18px;margin-left: 50px;">{{ item.name }}</span>
              </el-col>
              <el-row>
                <el-col>
                  <el-button size="medium" icon="el-icon-plus" round @click="goTeacherTerm(item.id)">添加学期</el-button>
                </el-col>
              </el-row>
            </div>
            <div class="timeMsg">
              <span style="font-size: 15px">{{ item.gmtCreate }}</span>
            </div>
            <el-row>
              <el-col :span="8" :offset="16">
                <div>
                  <el-button size="medium" @click="preView(item.id)">预览</el-button>
                  <!--        弹出框          -->
                  <el-button type="primary" size="medium" style="margin-left: 30px" @click="goBasicInfo(item.id)">备课
                  </el-button>
                </div>
              </el-col>
            </el-row>
            <div style="margin-top: 10px;margin-left: 20px">
              <el-button plain round icon="el-icon-document-copy" type="primary" size="small"
                         @click="toCopyCourse(item.id)">复制
              </el-button>
              <template v-if="item.status==='public'">
                <el-button plain round icon="el-icon-error" type="warning" size="small"
                           @click="changeStatusOnPrivate(item.id)">设为私有课程
                </el-button>
              </template>
              <template v-if="item.status==='private'">
                <el-button plain round icon="el-icon-success" type="primary" size="small"
                           @click="changeStatusOnPublic(item.id)">设为公选课程
                </el-button>
              </template>
              <el-button plain round icon="el-icon-delete" type="primary" size="small" @click="toRemove(item.id)">
                删除教案
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <!--    分页  -->
      <el-row style="margin-left: 400px;margin-top: 20px">
        <el-col>
          <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="loadCourseData"
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </el-col>
      </el-row>
      <!--   复制课程的dialog-->
      <template v-if="isCopyOrDelete===0">
        <el-dialog class="add-dialog" title="课程信息" :visible.sync="dialogVisible">
          <el-form
            label-position="right"
            label-width="80px"
            ref="addSectionForm"
            :model="copyCourseData">
            <el-form-item label="课程名称" prop="name">
              <el-input v-model="copyCourseData.name"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click=" dialogVisible= false">取 消</el-button>
                <el-button @click="copyCourse">确 定</el-button>
              </span>
        </el-dialog>
      </template>
      <!--   确认删除课程的dialog   -->
      <template v-if="isCopyOrDelete===1">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span>确认要删除这个课程吗！！！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="removeCourse">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-main>
  </el-container>
</template>
<script>
import Header from '@/components/Header'
import axios from 'axios'

export default {
  name: 'TeacherCourse',
  components: {
    Header
  },
  data () {
    return {
      /*分页*/
      currentPage: 1,
      total: 1,
      pageSize: 4,
      pageSizes: [4, 8, 12, 16],
      courseId: 0,
      isCopyOrDelete: 0,   //默认为0 ，0 表示复制课程的dialog  1 表示删除的dialog
      termData: [],
      dialogVisible: false,  //开启dialog的数据
      copyCourseData: {},   //进行复制的课程数据
      onGoing: true,        //
      isOnThisTerm: true,
      courseData: [
        {
          id: 0,
          name: '',
          teacherId: 0,
          status: '',
          courseInfo: {
            courseId: 0,
            sketch: '',
            imageUrl: '',
            infoUrl: ''
          }
        }
      ],
      term: {}
    }
  },
  created () {
    this.loadCourseData()
  },
  methods: {
    //跳转到课程视频或者测试的详情展示页面
    /* toTermClass (id) {
       this.$router.push({ path: '/courselist?termId='+ termId })
     },*/
    //预览
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadCourseData()
    },
    preView (id) {
      this.$router.push({ path: '/courselist?courseId=' + id })
    },
    //跳转到学期页面，编辑学期信息
    goTeacherTerm (id) {
      this.$router.push({ path: '/teacherclass?courseId=' + id })
    },
    //跳转到复制的对话框，并复制
    toCopyCourse (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCourseById',
        params: {
          id: id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.copyCourseData = res.data.data
            this.copyCourseData.id = res.data.data.id
            this.copyCourseData.name = res.data.data.name
            this.copyCourseData.courseInfo.sketch = res.data.data.courseInfo.sketch
          } else {
            this.$message.error(res.data.message)
          }
        })
      this.dialogVisible = true
      this.isCopyOrDelete = 0
    },
    //复制课程
    copyCourse () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/createCourse',
        params: {
          name: this.copyCourseData.name
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadCourseData()
            this.dialogVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //根据复制复刻该课程的image
    changeCourseImage (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/courseInfo/changeImage',
        params: {
          courseId: id,
          bucket: 'C',
          path: 'teacher/200001/%E8%AF%81%E4%B9%A6.pdf'
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //复制课程的详情
    changeCourseSketch (id, sketch) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/courseInfo/changeSketch',
        params: {
          courseId: id,
          sketch: sketch
        }
      })
        .then((res) => {
          if (res.data.status) {
            //this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadCourseData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showMyCourses',
        params: {
          currentPage: this.currentPage,
          pageSize: 4
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.courseData = res.data.data.content
            this.total = res.data.data.totalCount
            //   console.log('课程的数据为:', this.courseData)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    searchCourse () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCoursesByLikeName',
        params: {
          likeName: this.courseData.name,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.courseData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    toRemove (id) {
      this.dialogVisible = true
      this.courseId = id
      this.isCopyOrDelete = 1
    },
    removeCourse () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/remove',
        params: {
          id: this.courseId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadCourseData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //改变私有还是公选状态
    changeStatusOnPrivate (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/changeStatus',
        params: {
          id: id,
          status: 'private'
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.warning(res.data.message)
            this.loadCourseData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    changeStatusOnPublic (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/changeStatus',
        params: {
          id: id,
          status: 'public'
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadCourseData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    createCourse () {
      this.dialogVisible = true
      this.isCopyOrDelete = 0
    },
    goBasicInfo (courseId) {
      this.$router.push({ path: '/basicinfo?courseId=' + courseId })
    }
  }
}
</script>

<style scoped>
>>> body {
  line-height: 0 !important;
}

.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}

.box-card {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.selectedColumn {
  width: 80%;
  margin-bottom: 15px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.search {
  display: flex;
}

.planMsg {
  display: flex;
  margin-top: 15px;
  margin-left: 20px;
}

.timeMsg {
  display: flex;
  margin-top: 15px;
  margin-left: 20px;
}

.box-card img {
  margin: 0;
  object-fit: cover;
  width: 100%;
}
</style>
