<template>
  <CreateCertificate></CreateCertificate>
</template>

<script>
import axios from 'axios'
import CreateCertificate from '@/components/part/CreateCertificate'

export default {
  name: 'CertificateCard',
  components: {
    CreateCertificate
  },
  created () {
    this.termId = this.$route.query.termId
    this.certificateId = this.$route.query.certificateId
  },
  data () {
    return {
      termId: 0,     //学期Id
      certificateId: 0  //证书Id
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
