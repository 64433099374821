<template>
  <div id="final" style="width:550px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FinalScore',
  components: {},
  created () {
    this.termId = this.$route.query.termId
    this.loadFinalScore() /*加载最后的分数*/
  },
  data () {
    return {
      /*分页*/
      /*等级*/
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0,
      level5: 0,
      level6: 0,
      level7: 0,
      level8: 0,
      level9: 0,
      level10: 0,
      level11: 0,
      level12: 0,
      level13: 0,
      level14: 0,
      level15: 0,
      termId: 0,
      finalScore: []
    }
  },
  methods: {
    /*跳转到cericificate*/
    /*加载学生的最终成绩*/
    loadFinalScore () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/certificate/showScoresAwardedByMeByTermId',
        params: {
          termId: this.termId
        }
      })
        .then((res) => {
            if (res.data.status) {
              const countData = res.data.data
              this.finalScore = countData
              /*选择排序*/
              for (var i = 0; i < this.finalScore.length; i++) {
                for (var j = i + 1; j < this.finalScore.length; j++) {
                  //如果第一个比第二个大，就交换他们两个位置
                  if (this.finalScore[i] > this.finalScore[j]) {
                    let temp = this.finalScore[i]
                    this.finalScore[i] = this.finalScore[j]
                    this.finalScore[j] = temp
                  }
                }
              }
              const cout = this.finalScore
              for (let i = 0; i < cout.length; i++) {
                if (cout[i] >= 0 && cout[i] <= 10) {
                  this.level1 += 1
                } else if (cout[i] > 10 && cout[i] <= 20) {
                  this.level2 += 1
                } else if (cout[i] > 20 && cout[i] <= 30) {
                  this.level3 += 1
                } else if (cout[i] > 30 && cout[i] <= 40) {
                  this.level4 += 1
                } else if (cout[i] > 40 && cout[i] <= 50) {
                  this.level5 += 1
                } else if (cout[i] > 50 && cout[i] <= 60) {
                  this.level6 += 1
                } else if (cout[i] > 60 && cout[i] <= 70) {
                  this.level7 += 1
                } else if (cout[i] > 70 && cout[i] <= 80) {
                  this.level8 += 1
                } else if (cout[i] > 80 && cout[i] <= 90) {
                  this.level9 += 1
                } else if (cout[i] > 90 && cout[i] <= 100) {
                  this.level10 += 1
                }
              }
              this.countData = [this.level1, this.level2, this.level3, this.level4, this.level5, this.level6, this.level7, this.level8, this.level9, this.level10]
              this.countData1 = [this.level1, this.level2, this.level4, this.level6, this.level8, this.level10]
              this.loadScore()
            } else {
              this.$message.error(res.data.message)
            }
          }
        )
    },
    /*加载考试信息*/
    loadScore () {
      const final = this.$echarts.init(document.getElementById('final'))
      let option = {
        title: {
          text: '最终成绩'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              width: '',
              type: 'dashed'
            }
          },
          textStyle: {
            color: 'red'
          },
          formatter: '{a}</br>{b}分 : {c}人'
        },
        legend: {
          data: []
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            offset: 10
          },
          {
            type: 'category',
            position: 'bottom',
            offset: 20,
            data: [0, 20, 40, 60, 80, 100],
            show: false
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: this.countData,
            color: '#7FFFAA',
            xAxisIndex: 0//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          },
          {
            name: '',
            type: 'line',
            smooth: true,
            data: this.countData1,
            color: 'rgba(0,0,0,0.5)',
            xAxisIndex: 1//使用这个Y轴的索引来判断是关联哪个y轴了，x轴理同，将y换成x即可（xAxisIndex），适用于多轴
          }
        ]
      }
      final.on('click', params => {
        this.$router.push({
          path: '/certificateScore',
          name: 'CertificateScore',
          query: {
            termId: this.termId
          }
        })
      })
      final.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        final.resize()
      })
    }
  }
}
</script>

<style scoped>

</style>


