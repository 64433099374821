<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img
          src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/svg/addresource.svg"/>
      </div>
      <el-tabs type="border-card" style="margin-top: 10px">
        <el-tab-pane label="个人资源库">
          <!--  内部资源         -->
          <el-card shadow="always" style="width: 80%;margin: 20px auto;">
            <el-tabs type="border-card">
              <!--    全部         -->
              <el-tab-pane label="全部">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                  <el-col :span="4">
                    <el-button icon="el-icon-upload" type="success" round @click="goAddReSource">
                      上传资源
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="id" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="description" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="type" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="status" label="资源状态" width="100">
                      </el-table-column>
                      <el-table-column prop="gmtCreate" label="上传时间" width="180">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   :href="scope.row.url"
                                   target="_blank"> 查看
                          </el-link>
                          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                          <el-button type="danger"
                                     @click="deleteRow(scope.$index, scope.row)"
                                     size="small">
                            删除
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!--    Linux          -->
              <el-tab-pane label="Linux">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                  <el-col :span="4">
                    <el-button icon="el-icon-upload" type="success" round @click="goAddReSource">
                      上传资源
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-image src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/2.jpeg"/>
                </el-row>
              </el-tab-pane>
              <!--    Vue          -->
              <el-tab-pane label="Vue">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                  <el-col :span="4">
                    <el-button icon="el-icon-upload" type="success" round @click="goAddReSource">
                      上传资源
                    </el-button>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="id" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="description" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="type" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="status" label="资源状态" width="100">
                      </el-table-column>
                      <el-table-column prop="gmtCreate" label="上传时间" width="180">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   :href="scope.row.url"
                                   target="_blank"> 查看
                          </el-link>
                          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                          <el-button type="danger"
                                     @click.native.prevent="deleteRow(scope.$index, sourceData)"
                                     size="small">
                            删除
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!--     其他资源         -->
              <el-tab-pane label="其他资源">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                  <el-col :span="4">
                    <el-button icon="el-icon-upload" type="success" round @click="goAddReSource">
                      上传资源
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-image src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/2.jpeg"/>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-tab-pane>
        <!--   农大资源库     -->
        <el-tab-pane label="农大资源库">
          <!--  内部资源         -->
          <el-card shadow="always" style="width: 80%;margin: 20px auto;">
            <el-tabs type="border-card">
              <!--   农大资源库   全部   -->
              <el-tab-pane label="全部">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="sourceid" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="monster" label="上传人" width="180">
                      </el-table-column>
                      <el-table-column prop="sourcename" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="date" label="上传时间" width="120">
                      </el-table-column>
                      <el-table-column prop="format" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="size" label="资源大小" width="100">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   href="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/C%E8%AF%AD%E8%A8%80%E7%BB%8F%E5%85%B8%E7%AE%97%E6%B3%95100%E4%BE%8B.pdf"
                                   target="_blank"> 查看
                          </el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!--   农大资源库    其他资源 -->
              <el-tab-pane label="其他资源">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                </el-row>
                <el-row>
                  <el-image src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/2.jpeg"/>
                </el-row>
              </el-tab-pane>
              <!--   农大资源库    网页资源 -->
              <el-tab-pane label="网页资源">
                <el-row>
                  <el-col :span="20">
                    <el-input placeholder="输入资源名称" suffix-icon="el-icon-search"
                              style="width: 200px;height: 30px;border-radius: 30px"/>
                  </el-col>
                </el-row>
                <el-row>
                  <el-image src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/2.jpeg"/>
                </el-row>
              </el-tab-pane>
              <!--   农大资源库    音频资源 -->
              <el-tab-pane label="音频资源">
                <el-row>
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="sourceid" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="monster" label="上传人" width="180">
                      </el-table-column>
                      <el-table-column prop="sourcename" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="date" label="上传时间" width="120">
                      </el-table-column>
                      <el-table-column prop="format" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="size" label="资源大小" width="100">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   href="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/%E5%91%A8%E6%9D%B0%E4%BC%A6-%E7%A8%BB%E9%A6%99.mp3"
                                   target="_blank"> 查看
                          </el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!--   农大资源库    视频资源 -->
              <el-tab-pane label="视频资源">
                <el-row>
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="id" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="description" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="type" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="status" label="资源状态" width="100">
                      </el-table-column>
                      <el-table-column prop="gmtCreate" label="上传时间" width="180">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   :href="scope.row.url"
                                   target="_blank"> 查看
                          </el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!--   农大资源库    文本资源 -->
              <el-tab-pane label="文本资源">
                <el-row>
                  <el-col>
                    <el-table
                      :data="sourceData"
                      border
                      style="width: 100%">
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column prop="sourceid" label="资源编号" width="180">
                      </el-table-column>
                      <el-table-column prop="monster" label="上传人" width="180">
                      </el-table-column>
                      <el-table-column prop="sourcename" label="资源名称" width="180">
                      </el-table-column>
                      <el-table-column prop="date" label="上传时间" width="120">
                      </el-table-column>
                      <el-table-column prop="format" label="资源格式" width="100">
                      </el-table-column>
                      <el-table-column prop="size" label="资源大小" width="100">
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <el-link style="margin-right: 10px"
                                   href="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/C%E8%AF%AD%E8%A8%80%E7%BB%8F%E5%85%B8%E7%AE%97%E6%B3%95100%E4%BE%8B.pdf"
                                   target="_blank"> 查看
                          </el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-tab-pane>
      </el-tabs>
      <!--    编辑修改资源的格式  -->
      <template v-if="addOrEdit===0">
        <el-dialog title="编辑资源信息" :visible="dialogVisible" width="50%" :destroy-on-close="true" :show-close="false"
                   style="font-weight: bold">
          <el-form :model="editResourceData">
            <el-form-item label="资源编号" prop="name">
              <el-input v-model="editResourceData.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="资源名称" prop="description">
              <el-input v-model="editResourceData.description"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="changeResource">确 定</el-button>
          </div>
        </el-dialog>
      </template>
      <!--  编辑资源的对话框  -->
      <!--  上传资源的对话框   -->
      <template v-if="addOrEdit===1">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="40%">
          <el-card shadow="never" style="padding: 10px 0">
            <input type="file" class="fileExport" @change="handleFileChange" ref="inputer1">
            <el-row>
              <el-button @click="reload" round style="margin-left: 350px">刷新</el-button>
              <el-button id="submitButton" style="float: right" @click="putObj()" round>提交</el-button>
              <!--          <a id="buttonTag" class="center-to-head submit" @click="putObj()">提交</a>-->
            </el-row>
          </el-card>
          <el-dialog
            width="80%"
            title="上传资源"
            :visible.sync="innerVisible"
            append-to-body>
            <el-form label-width="80px" :model="addResourceData">
              <el-form-item label="学期编号">
                <el-input v-model="addResourceData.termId" disabled></el-input>
              </el-form-item>
              <el-form-item label="资源链接">
                <el-input v-model="addResourceData.bucket" disabled></el-input>
              </el-form-item>
              <el-form-item label="资源路径">
                <el-input v-model="addResourceData.path" disabled></el-input>
              </el-form-item>
              <el-form-item label="资源详情">
                <el-input v-model="addResourceData.description"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisible= false">取 消</el-button>
                <el-button type="primary" @click="addReSource">确 定</el-button>
             </span>
          </el-dialog>
          <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="innerVisible=true">确 定</el-button>
             </span>
        </el-dialog>
      </template>
      <!--  上传资源的对话框   -->
      <!--   编辑资源的对话框   -->
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import cos from '@/main'

export default {
  name: 'AddSource',
  components: {
    TermHeader
  },
  data () {
    const addResourceData = {
      termId: 0,
      description: '',
      bucket: '',
      path: ''
    }
    return {
      editResourceData: {
        id: 0,
        description: ''
      },
      path: '',
      innerVisible: false,   //上传内部资源开启的表单
      location: '',
      addResourceData: JSON.parse(JSON.stringify(addResourceData)),
      resourceUrl: '',
      isComponentAlive: true,
      teacherId: 0,
      addOrEdit: 0,    //0 表示编辑资源数据对话框， 1表示上传资源的对话框
      termId: 0,
      fileList: [],
      file: {},
      dialogVisible: false,   //  true为开启对话框
      formLabelWidth: '120px', //  对话框lable的宽度
      sourceData: []
    }
  },
  created () {
    // 获得当前学期的Id
    this.termId = this.$route.query.termId
    this.addResourceData.termId = this.termId
    this.loadReSource()
    this.loadTempFile()
    this.checkLogin()
  },
  methods: {
    //加载临时数据
    loadTempFile () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/tempFile/showMyTempFiles'
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //添加资源的方法
    addReSource () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/resource/uploadResource',
        params: {
          termId: this.termId,
          description: this.addResourceData.description,
          bucket: this.addResourceData.bucket,
          path: this.addResourceData.path
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadReSource()
            this.dialogVisible = false
            this.innerVisible = false
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    checkLogin () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/teacher/checkLogin'
      })
        .then(response => {
          if (response.data.status) {
            console.log('login:', response.data)
            this.teacherId = response.data.data.id
          } else {
            this.$message.error(response.data.message)
          }
          const email = response.data.data.email
          if (email.substring(email.length - 7) === '@qq.com') {
            const qqId = email.substring(0, email.indexOf('@'))
            this.teacher.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
          }
        })
    },
    // 上传资源到cos
    putObj () {
      const date = new Date()
      const hour = date.getHours()
      let bucket
      if (hour < 8) {
        bucket = 'a'
      } else if (hour < 16) {
        bucket = 'b'
      } else {
        bucket = 'c'
      }
      const fileName = this.file.name
      const keyID = 'teacher/' + this.teacherId + '/' + fileName
      // student/100001/abc.txt
      console.log(keyID)
      //定义变量
      this.addResourceData.path = keyID
      this.addResourceData.bucket = bucket.toUpperCase()
      cos.putObject({
        Bucket: 'mooc-temp-' + bucket + '-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: this.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
          const percentage = document.getElementById('uploadPercentage')
          const submitButton = document.getElementById('submitButton')
          percentage.style.width = progressData.percent * 100 + '%'
          if (progressData.percent === 1) {
            percentage.style.width = '0%'
            alert('临时文件上传成功')
            submitButton.disabled = 'false'
            this.reload()
          } else {
            submitButton.disabled = 'disabled'
          }
        }
      }, function (err, data) {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          console.log('bucket:' + bucket + 'keyID:' + keyID)
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/tempFile/checkUploadedFile',
            params: {
              bucket: bucket.toUpperCase(),
              path: keyID
            }
          }).then(res => {
            if (res.data.status) {
              // this.path = keyID
              //  console.log('离谱的数据:', this.addResourceData.path + this.addResourceData.bucket)
              this.$message.success(res.data.message)
            }
          })
        }
      })
    },
    handleFileChange (e) {
      const inputDOM = this.$refs.inputer1
      this.file = inputDOM.files[0]
      this.formData = new FormData()
      this.formData.append('file', this.file)
    },
    reload () {
      this.isComponentAlive = false
      this.$nextTick(function () {
        this.isComponentAlive = true
      })
    },

    loadReSource () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/resource/showResourcesByTermId',
        params: {
          termId: this.termId,
          currentPage: 1,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            // console.log('资源的数据为:', res.data.data)
            this.sourceData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    goAddReSource () {
      this.dialogVisible = true
      this.addOrEdit = 1
      //this.$router.push({ path: '/resource?termId=' + this.termId })
    },
    submitUpload () {
      this.$refs.upload.submit()
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
      window.open(file.url)
    },
    handleClick (row) {
      console.log(row)
    },
    deleteRow (index, rows) {
      // rows.splice(index, 1)
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/resource/removeResourceById',
        params: {
          id: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadReSource()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    handleEdit (index, rowObject) {
      this.dialogVisible = true
      this.addOrEdit = 0
      //console.log(index, rowObject)
      this.editResourceData.id = rowObject.id
    },
    changeResource () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/resource/changeDescription',
        params: {
          id: this.editResourceData.id,
          description: this.editResourceData.description
        }
      }).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.dialogVisible = false
          this.loadReSource()
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  margin-top: -50px;
  vertical-align: middle;
  margin-bottom: -40px;
}

.el-card {
  margin: 15px 0;
}

.el-tag {
  margin-right: 15px;
}
</style>
