<template>
  <div>
    <el-card :body-style="{ padding: '0px',width:'100%' }" class="box-card" v-if="reloadDrawer">
      <div @click="goTermManage(term.id)">
          <img v-if="term.course.courseInfo!==null"
               :src="term.course.courseInfo.imageUrl"
               alt="">
        <img v-if="term.course.courseInfo===null" src="../assets/img/356.jpg"
             alt="">
      </div>
      <div style="padding: 14px;">
        <template v-if="term.course!==null">
          <h3>{{ term.course.name }}</h3>
        </template>
        <template v-if="term.course===null">
          <h3></h3>
        </template>
        <template v-if="term.limited===false">
          <el-tag size="mini" type="warning">私有学期</el-tag>
        </template>
        <template v-if="term.limited===true">
          <el-tag size="mini" type="success">公开学期</el-tag>
        </template>
        <el-row>
          <span style="margin-right: 10px">{{ term.name }}</span>
          <template v-if="term.status==='ongoing'">
            <el-tag size="mini" type="success">正在进行中</el-tag>
          </template>
          <template v-if="term.status==='unopened'">
            <el-tag size="mini" type="warning">未开课</el-tag>
          </template>
          <template v-if="term.status==='ended'">
            <el-tag size="mini" type="danger">已结课</el-tag>
          </template>
          <template v-if="term.status==='completed'">
            <el-tag size="mini" type="primary">已完成</el-tag>
          </template>
        </el-row>
        <div>
          <time class="time">{{ term.endTime }}</time>
          <!--       跳转讨论页面，跳转任务页面           -->
          <el-popover
            placement="right"
            width="150"
            trigger="hover"
            v-model="visible">
            <el-row>
              <el-col :span="24">
                <el-button style="border: none;width: 100%" @click="editClass(term.id)">编辑</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button style="border: none;width: 100%" @click="toDeleteTerm(term.id)">删除</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button style="border: none;width: 100%" @click="completeTerm(term.id)">完成</el-button>
              </el-col>
            </el-row>
            <el-button slot="reference" @click="visible= !visible"
                       icon="el-icon-setting" class="settings"
                       style="border: none;float: right"></el-button>
          </el-popover>
        </div>
      </div>
      <!--   编辑学期   -->
      <template v-if="addOrUpdate===2&&confirmDelete===0">
        <el-dialog
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="editTermData">
            <el-form-item label="课程编号">
              <el-input v-model="editTermData.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="学期名称">
              <el-input v-model="editTermData.name"></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker
                v-model="editTermData.startTime"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="editTermData.endTime"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否自由选课">
              <el-radio-group v-model="editTermData.limited">
                <el-radio label="true">能</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editTermByName">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--   确认删除课程的dialog   -->
      <template v-if="confirmDelete===1">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span
            style="margin-right: 5px;font-size: 22px;font-weight: bold;color: rgba(255,0,0,0.5)">确认要删除这个学期吗！！！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteClass">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TermBox',
  props: {   //将父组件的值传到子组件中
    term: Object,//object表示对象类型
    index: Number
  },
  data () {
    return {
      reloadDrawer: true,  //组件局部刷新
      termDataId: 0,
      confirmDelete: 1,  //为1时，开启是否确认删除这个学期的dialog,其他的为0
      dialogVisible: false,
      addOrUpdate: 1,//1表示添加学期，2表示更新学期  3 。表示创建任务  4.表示创建讨论
      editTermData: {},// 编辑的学期数据
      visible: false
    }
  },
  methods: {
    //跳转到学期管理页面
    goTermManage (termId) {
      this.$router.push({ path: '/termmanage?termId=' + termId })
    },
    //跳转到删除学期的dialog
    toDeleteTerm (id) {
      this.confirmDelete = 1
      this.dialogVisible = true
      this.termDataId = id
    },
    /*结束当前学期*/
    completeTerm (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/complete',
        params: {
          id: id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.reload()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    editClass (id) {
      this.confirmDelete = 0
      this.addOrUpdate = 2
      this.dialogVisible = true
      this.editTermData.id = id
    },
    deleteClass () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/removeTerm',
        params: {
          id: this.termDataId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadTermData()
            this.reload()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*局部刷新*/
    //编辑学期
    editTermByName () {
      axios({
        //  修改名字
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/changeName',
        params: {
          id: this.editTermData.id,
          name: this.editTermData.name
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.editTermByStartTime()
            this.editTermByEndTime()
            this.editTermByLimited()
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.reload()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    editTermByStartTime () {
      //  修改开课时间
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/changeStartTime',
        params: {
          id: this.editTermData.id,
          startTime: this.editTermData.startTime
        }
      })
        .then((res) => {
          if (res.data.status) {
            // this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    editTermByEndTime () {
      //  修改结课时间
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/changeEndTime',
        params: {
          id: this.editTermData.id,
          endTime: this.editTermData.endTime
        }
      })
        .then((res) => {
          if (res.data.status) {
            //this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    editTermByLimited () {
      //  修改能否选课的权限
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/changeLimited',
        params: {
          id: this.editTermData.id,
          limited: this.editTermData.limited
        }
      })
        .then((res) => {
          if (res.data.status) {
            //this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //局部刷新
    reload () {
      this.reloadDrawer = false
      this.$nextTick(function () {
        this.reloadDrawer = true
      })
    }
  }
}

</script>

<style scoped>

</style>
