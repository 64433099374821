<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/studentmanage.png"/>
      </div>
      <el-row>
        <el-col :span="10">
          <el-card shadow="always" style="border-radius: 30px;margin-top: 20px;">
            <h3>任务互评分数</h3>
            <el-table
              :data="taskMutualData">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column prop="id" label="互评编号" width="100">
              </el-table-column>
              <el-table-column prop="student.name" label="学生姓名" width="120">
              </el-table-column>
              <el-table-column property="score" label="分数" width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.score.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column property="status" label="评分形式" width="100">
                <template slot-scope="scope">
                  <template v-if="scope.row.status==='normal'">
                    <el-tag type="success">正常</el-tag>
                  </template>
                  <template v-if="scope.row.status==='abnormal'">
                    <el-tag type="warning">异常</el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                  <el-button size="small" round @click="viewStudentReliability(scope.$index, scope.row)">View
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always" style="border-radius: 30px;margin-top: 20px;margin-left: 20px">
            <h2>互评统计数据</h2>
            <el-row>
              <span style="font-size: 15px;font-weight: bold">最终提交分数:{{ this.taskData.taskSubmissionScore }}</span>
            </el-row>
            <el-row>
              <template v-if="this.taskData.scoreType==='teacher'">
                <span style="font-size: 15px;font-weight: bold">评分形式:</span>
                <el-tag type="success">老师评分</el-tag>
              </template>
              <template v-if="this.taskData.scoreType==='mutual'">
                <span style="font-size: 15px;font-weight: bold">评分形式:</span>
                <el-tag type="warning">互评评分</el-tag>
              </template>
            </el-row>
            <el-row>
              <span style="font-size: 15px;font-weight: bold">平均分:{{ this.taskData.averageScore }}</span>
            </el-row>
            <el-row>
              <span style="font-size: 15px;font-weight: bold">方差:{{ this.taskData.variance }}</span>
            </el-row>
            <el-row>
              <span style="font-size: 15px;font-weight: bold">标准差:{{ this.taskData.standardDeviance }}</span>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'

export default {
  name: 'TaskMutualDetail',
  components: {
    TermHeader
  },
  data () {
    return {
      termId: 0,
      taskSubmissionId: 0,   //获得当前的任务Id
      taskMutualData: [],     //获得互评的数据
      taskData: []
    }
  },
  created () {
    this.termId = this.$route.query.termId
    this.taskSubmissionId = this.$route.query.taskSubmissionId
    this.loadTaskMutualData()
    this.loadTaskData()
  },
  methods: {
    /*加载学生互评的数据*/
    loadTaskMutualData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showByTaskSubmissionId',
        params: {
          taskSubmissionId: this.taskSubmissionId,
          withStudent: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const counterData = res.data.data
            this.taskMutualData = counterData
            //   this.loadTaskCharts()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    loadTaskData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showMutualRecordByTaskSubmissionId',
        params: {
          taskSubmissionId: this.taskSubmissionId,
        }
      })
        .then((res) => {
          if (res.data.status) {
            const counterData = res.data.data
            this.taskData = counterData
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.img-wrap {
  display: inline-block;
}

.img-wrap img {
  width: 100%;
  vertical-align: middle;
}
</style>
