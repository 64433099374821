<template>
  <div>
    <el-container>
      <el-aside style=" position: fixed;height: 90vh;overflow: paged-y;left: 0;width: 250px;">
        <el-menu class="el-menu-vertical-demo" style="border-right: 0;width: 200px;" default-active="2"
                 @select="handleSelect">
          <div v-for="item in courseList" :key="item.orderNumber">
            <el-submenu
              v-if="item.children!==null && item.children.length>0"
              :index="item.orderNumber+''">
              <template slot="title">
                <i v-if="item.myRecord!==null && item.myRecord.finished===true" class="el-icon-circle-check"
                   style="color: #67C23A"></i>
                <i v-else class="el-icon-document-copy"></i>
                <span @click="changeCourseInfo(item)">{{ item.name }}</span>
              </template>
              <el-menu-item v-for="kid in item.children" :index="item.orderNumber+'-'+kid.orderNumber"
                            :key="kid.orderNumber" @click="changeCourseInfo(kid)">
                <template slot="title">
                  <i v-if="kid.mode!=='checkpoint'" class="el-icon-link"></i>
                  <i v-else-if="kid.myRecord!==null && kid.myRecord.finished===true" class="el-icon-circle-check"
                     style="color: #67C23A"></i>
                  <i v-else class="el-icon-question" style="color: #E6A23C"></i>
                  {{ kid.name }}
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="item.orderNumber+''" @click="changeCourseInfo(item)">
              <i v-if="item.mode!=='checkpoint'" class="el-icon-link"></i>
              <i v-else-if="item.myRecord!==null && item.myRecord.finished===true" class="el-icon-circle-check"
                 style="color: #67C23A"></i>
              <i v-else class="el-icon-question" style="color: #E6A23C"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-aside>
      <el-main>
        <el-button round icon="el-icon-arrow-right" size="medium" @click="toCourse"
                   style="margin-left: 1200px;">返回</el-button>
        <div style="padding: 20px 150px">
          <div class="leadCard" v-show="leadCardShow"><img
            src="https://mooc-webfront-1303967055.cos.ap-shanghai.myqcloud.com/moocImg/undraw_startup_life_re_8ow9.svg"
            alt="" style="width:300px">
            <h3>
              <b-icon icon="arrow-left-square" animation="cylon"></b-icon>
              从左侧列表选择查看
            </h3>
          </div>
          <transition name="el-zoom-in-center">
            <div v-show="!leadCardShow" class="transition-box">
              <CourseStudy :course-info="courseInfo"></CourseStudy>
            </div>
          </transition>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'
import CourseStudy from '@/components/CourseStudy'

export default {
  name: 'CourseList',
  components: {
    CourseStudy
  },
  data () {
    return {
      courseId: 0,
      teacherId: 0,
      courseList: [],
      courseInfo: {},
      leadCardShow: true
    }
  },
  created () {
    /*
    1.通过url获取termId *
    2./term/showTermById 获取完整的term对象 *
    3./student/checkLogin 获取student对象 *
    4./chapter/showCascadedChaptersByCourseId  (term.courseId)
    5./record/showRecordsByStudentIdAndTermId
     */
    this.courseId = this.$route.query.courseId
    this.showVideo(this.courseId)
  },
  methods: {
    //跳转回课程页面
    toCourse(){
      this.$router.push({path:'/teachercourse'})
    },
    changeCourseInfo (key) {
      this.courseInfo = key
      this.leadCardShow = false
    },
    showVideo (id) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/chapter/showCascadedChaptersByCourseId',
        params: {
          courseId: id
        }
      })
        .then((res) => {
          const datas = res.data.data
          if (res.data.status) {
            this.courseList = datas
            console.log('章节名称数据为:', this.data)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.leadCard {
  margin: 100px;
  text-align: center;
}
</style>
