<template>
  <el-container>
    <el-header>
      <TermHeader></TermHeader>
    </el-header>
    <el-main>
      <div class="img-wrap">
        <img src="https://uni-demo01-1306005576.cos.ap-shanghai.myqcloud.com/hunauyuxue/static/img/module.png">
      </div>
      <!--   主要的任务管理   -->
      <el-row style="margin-top: 10px">
        <el-col>
          <el-button round icon="el-icon-plus" @click="toAddTask">创建任务</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table
          :data="taskData"
          border
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column prop="id" label="任务编号" width="120">
          </el-table-column>
          <el-table-column property="title" label="任务名称" width="100">
            <template slot-scope="scope">
              <a style="cursor: pointer" @click="goTaskSubmissionRow(scope.$index, scope.row)">{{ scope.row.title }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="任务详情" width="180">
          </el-table-column>
          <el-table-column prop="status" label="任务状态" width="100">
          </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间" width="180">
          </el-table-column>
          <el-table-column prop="deadline" label="截止时间" width="180">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="small" @click="handleEdit(scope.$index, scope.row)" round>编辑</el-button>
              <el-button type="danger"
                         @click="deleteRow(scope.$index, scope.row)"
                         size="small" round>删除
              </el-button>

              <!--    内置表格的Drawer          -->
              <!--  开启评分            -->
              <template v-if="isAddOrUp===3">
                <el-drawer
                  title="请开始评分吧"
                  :visible.sync="table"
                  direction="rtl"
                  size="50%">
                  <el-table :data="scoreData" v-if="reloadDrawer">
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column property="id" label="任务编号" width="80"></el-table-column>
                    <el-table-column property="student.name" label="学生姓名" width="100"></el-table-column>
                    <el-table-column property="scoreType" label="评分形式" width="90">
                      <template slot-scope="scope">
                        <template v-if="scope.row.scoreType==='teacher'">
                          <el-tag type="success">老师评分</el-tag>
                        </template>
                        <template v-if="scope.row.scoreType==='mutual'">
                          <el-tag type="danger">互评评分</el-tag>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column property="score" label="分数" width="80" sortable>
                      <template slot-scope="scope">
                        <span>{{ scope.row.score.toFixed(2) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column property="gmtCreate" label="创建时间" width="160"></el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button @click="toScore(scope.$index, scope.row)" size="small" round>Score</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-row style="margin-top:20px">
                    <el-col :span="12" :offset="2">
                      <el-pagination
                        @size-change="handlePageSizeChange"
                        @current-change="loadScoreData"
                        :current-page.sync="currentPage"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                      </el-pagination>
                    </el-col>
                  </el-row>
                </el-drawer>
              </template>
              <!--    内置表格的Drawer          -->
              <!--  开启互评评分           -->
              <template v-if="isAddOrUp===4">
                <el-drawer
                  title="请开始判别互评评分吧"
                  :visible.sync="table"
                  direction="rtl"
                  size="50%">
                  <el-table :data="mutualData" v-if="reloadDrawer">
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column property="taskSubmissionId" label="互评编号" width="80"></el-table-column>
                    <!--        拿到学生的名字            -->
                    <el-table-column property="student.name" label="学生名称" width="120"></el-table-column>
                    <el-table-column property="scoreType" label="状态" width="80">
                      <template slot-scope="scope">
                        <template v-if="scope.row.status==='normal'">
                          <el-tag type="success">正常</el-tag>
                        </template>
                        <template v-if="scope.row.status==='mutual'">
                          <el-tag type="danger">互评评分</el-tag>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column property="score" label="分数" width="60">
                      <template slot-scope="scope">
                        <span>{{ scope.row.score.toFixed(2) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column property="gmtCreate" label="创建时间" width="180"></el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button @click="removeMutual(scope.$index, scope.row)" size="small" round type="danger">
                          Delete
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-drawer>
              </template>
              <!--   ==========         -->
              <el-button @click="scoreRow(scope.$index, scope.row)" size="small" round style="margin-left: 10px">评分
              </el-button>
              <el-button @click="goTaskSubmissionRow(scope.$index, scope.row)" size="small" round
                         style="margin-left: 10px">View
              </el-button>
              <!--    弹出框          -->
              <el-popover
                placement="top-start"
                title="操作按钮"
                width="400"
                trigger="hover" style="margin-left: 10px">
                <el-avatar style="vertical-align: middle"></el-avatar>
                <el-button @click="startRow(scope.$index, scope.row)" size="small" round style="margin-left: 10px"
                           type="success">Start
                </el-button>
                <el-button @click="endRow(scope.$index, scope.row)" size="small" round type="warning">End</el-button>
                <el-button @click="completeRow(scope.$index, scope.row)" size="small" round type="primary">Complete
                </el-button>
                <el-button @click="mutual(scope.$index, scope.row)" size="small" round style="margin-left: 10px">
                  Mutual
                </el-button>
                <el-divider></el-divider>
                <el-button slot="reference" round size="small" icon="el-icon-setting"></el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 表格视图     -->
      <el-row>
        <el-card shadow="always" style="width: 80%;margin: 20px auto;border-radius: 30px">
          <el-col :span="12">
            <TaskSubmissionCharts></TaskSubmissionCharts>
          </el-col>
          <el-col :span="12">
            <TaskAverage></TaskAverage>
          </el-col>
        </el-card>
      </el-row>
      <!--   创建任务 -->
      <template v-if="isAddOrUp===0">
        <el-dialog
          title="创建任务"
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="task">
            <el-form-item label="学期编号">
              <el-input v-model="task.termId" disabled></el-input>
            </el-form-item>
            <el-form-item label="任务名称">
              <el-input v-model="task.title"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="createTask">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--   编辑任务   -->
      <template v-if="isAddOrUp===1">
        <el-dialog
          title="编辑任务"
          :visible.sync="dialogVisible"
          width="30%">
          <el-form label-width="80px" :model="editTaskData">
            <el-form-item label="任务编号">
              <el-input v-model="editTaskData.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="任务名称">
              <el-input v-model="editTaskData.title"></el-input>
            </el-form-item>
            <el-form-item label="任务详情">
              <el-input v-model="editTaskData.description"></el-input>
            </el-form-item>
            <el-form-item label="截止日期">
              <el-date-picker
                v-model="editTaskData.deadline"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateTaskData">确 定</el-button>
          </span>
        </el-dialog>
      </template>
      <!--  评分    -->
      <template v-if="isAddOrUp===4">
        <el-drawer
          title="请开始判别互评评分吧"
          :visible.sync="table"
          direction="rtl"
          size="50%">
          <el-table :data="mutualData" v-if="reloadDrawer">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column property="taskSubmissionId" label="互评编号" width="80"></el-table-column>
            <!--        拿到学生的名字            -->
            <el-table-column property="student.name" label="学生名称" width="120"></el-table-column>
            <el-table-column property="scoreType" label="状态" width="80">
              <template slot-scope="scope">
                <template v-if="scope.row.status==='normal'">
                  <el-tag type="success">正常</el-tag>
                </template>
                <template v-if="scope.row.status==='mutual'">
                  <el-tag type="danger">互评评分</el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column property="score" label="分数" width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.score.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="gmtCreate" label="创建时间" width="180"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="removeMutual(scope.$index, scope.row)" size="small" round type="danger">
                  Delete
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top:20px">
            <el-col :span="12" :offset="2">
              <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="loadMutualData"
                :current-page.sync="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </el-col>
          </el-row>
        </el-drawer>
      </template>
      <!--    确认是否删除任务  -->
      <template v-if="isAddOrUp===5">
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%">
          <span style="color: rgba(255,48,48,0.5);font-weight: bold;font-size: 28px">确实要删除当前的任务吗</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteTask">确 定</el-button>
          </span>
        </el-dialog>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import TermHeader from '@/components/TermHeader'
import axios from 'axios'
import TaskSubmissionCharts from '@/components/charts/TaskSubmissionCharts'
import TaskAverage from '@/components/charts/TaskAverage'

export default {
  name: 'TaskManage',
  components: {
    TermHeader,
    TaskSubmissionCharts,
    TaskAverage
  },
  created () {
    this.termId = this.$route.query.termId
    this.task.termId = this.termId
    //加载学期名称
    /*    this.loadTermName()*/
    this.loadTaskData()
  },
  data () {
    return {
      taskId: 0,
      mutualData: [],  //获取当前的互评数据
      reloadDrawer: true,  //组件局部刷新
      editScore: {},       //评分数据
      scoreData: {},        //展示评分的数据
      table: false,   //展示评分的数据
      isAddOrUp: 0,  //0表示添加，1表示更新  2表示编辑评分   3 打开评分的drawer  4  表示打开互评的drawer   5 表示是否确认删除任务
      editTaskData: {},
      dialogVisible: false,
      task: {
        termId: 0,
        title: ''
      },
      term: {
        name: ''
      },
      termId: 0,
      taskData: [],
      drawerId: 0,
      mutualId: 0,
      deleteId: 0, //定义的是否删除Id的数据
      pageSizes: [10, 20, 30, 40, 50],
      currentPage: 1,
      pageSize: 10,
      total: 1,
    }
  },
  methods: {
    loadMutualData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/showByTaskId',
        params: {
          taskId: this.taskId,
          withStudent: true,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.mutualData = res.data.data.content
            this.total = res.data.data.totalCount
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    /*改变页码*/
    handlePageSizeChange (val) {
      this.pageSize = val
      this.loadScoreData()
    },
    //跳转删除任务的dialog
    toAddTask () {
      this.isAddOrUp = 0
      this.dialogVisible = true
    },
    //创建任务
    createTask () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/createTask',
        params: {
          termId: this.task.termId,
          title: this.task.title
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadTaskData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //加载学期名称
    loadTermName () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermById',
        params: {
          id: this.termId,
          currentPage: 1,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.term.name = res.data.data.content.name
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //加载任务数据
    loadTaskData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/showTasksByTermId',
        params: {
          termId: this.termId,
          currentPage: 1,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.taskData = res.data.data.content
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //编辑任务
    handleEdit (index, rowObject) {
      this.dialogVisible = true
      this.isAddOrUp = 1
      this.editTaskData = rowObject
    },
    //编辑修改任务数据
    updateTaskData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/changeTitle',
        params: {
          id: this.editTaskData.id,
          title: this.editTaskData.title
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.changeDescription()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //改变任务详情
    changeDescription () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/changeDescription',
        params: {
          id: this.editTaskData.id,
          description: this.editTaskData.description
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.changeDeadline()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //改变截止日期
    changeDeadline () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/changeDeadline',
        params: {
          id: this.editTaskData.id,
          deadline: this.editTaskData.deadline
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadTaskData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到确认删除当前的dialog
    deleteRow (index, rows) {
      this.dialogVisible = true
      this.isAddOrUp = 5
      this.deleteId = rows.id

    },
    //删除当前任务
    deleteTask () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/removeTaskById',
        params: {
          id: this.deleteId
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            this.loadTaskData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    mutual (index, rows) {
      this.table = true
      this.isAddOrUp = 4
      this.taskId = rows.id
      this.loadMutualData()
    },
    //完成任务
    completeRow (index, rows) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/complete',
        params: {
          id: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadTaskData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //开启互评
    startRow (index, rows) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/start',
        params: {
          taskId: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //结束互评
    endRow (index, rows) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/end',
        params: {
          taskId: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //开启评分的状态
    scoreRow (index, rows) {
      this.table = true
      this.drawerId = rows.id
      this.isAddOrUp = 3
      this.loadScoreData()
    },
    goTaskSubmissionRow (index, rows) {
      this.$router.push({
        path: '/taskSubmissionDetail',
        name: 'TaskSubmissionDetail',
        query: {
          termId: this.termId,
          taskId: rows.id
        }
      })
    },
    //开启互评评分
    mutualRow (index, rows) {
      /* this.table = true
       this.isAddOrUp = 4
       this.mutualId = rows.id
       this.loadMutualData()*/
      this.$router.push({
        path: '/taskAverage',
        name: 'TaskAverage',
        query: {
          termId: this.termId,
          taskId: rows.id
        }
      })
    },
    //删除Mutual
    removeMutual (index, rows) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/removeByTeacher',
        params: {
          id: rows.id
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.loadMutualData()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },

    //加载评分数据
    loadScoreData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showTaskSubmissionsByTaskId',
        params: {
          taskId: this.drawerId,
          withStudent: true,
          currentPage: this.currentPage,
          pageSize: 10
        }
      })
        .then((res) => {
          if (res.data.status) {
            this.scoreData = res.data.data.content
            this.total = res.data.data.totalCount
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    //跳转到评分弹窗
    toScore (index, rows) {
      this.$router.push({
        path: '/taskScore',
        name: 'TaskScore',
        query: {
          termId: this.termId,
          taskSubmissionId: rows.id,
          studentId: rows.studentId
        }
      })
    },
    //局部刷新
    reload () {
      this.reloadDrawer = false
      this.$nextTick(function () {
        this.reloadDrawer = true
      })
    }
  }
}
</script>

<style scoped>

</style>
