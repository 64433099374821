<template>
  <div id="student" style="width:600px;height:350px;"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'studentCharts',
  data () {
    return {
      termId: 0,
      /*等级*/
      S: 0.0,
      A: 0.0,
      B: 0.0,
      C: 0.0,
      D: 0.0,
      E: 0.0
    }
  },
  created () {
    this.termId = this.$route.query.termId
    //加载学生学习的记录点
  },
  mounted () {
    /*展示学生已经完成的数据*/
    this.loadStudentCountData()
  },
  methods: {
    /*加载学生已经完成的数据*/
    loadStudentCountData () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/record/showCompletenessForStudentByTermId',
        params: {
          termId: this.termId,
          withStudentName: true
        }
      })
        .then((res) => {
          if (res.data.status) {
            const countData = res.data.data
            for (let i = 0; i < countData.length; i++) {
              if (countData[i].completeness === 0.0) {
                this.E += 1
              } else if (countData[i].completeness <= 0.25 && countData[i].completeness > 0) {
                this.D += 1
              } else if (countData[i].completeness > 0.25 && countData[i].completeness <= 0.5) {
                this.C += 1
              } else if (countData[i].completeness <= 0.75 && countData[i].completeness > 0.5) {
                this.B += 1
              } else if (countData[i].completeness <1.00 && countData[i].completeness > 0.75) {
                this.A += 1
              } else if (countData[i].completeness >=1.00) {
                this.S += 1
              }
            }
            // console.log(this.A)
            this.student()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    student () {
      // 基于准备好的dom，初始化echarts实例
      const student = this.$echarts.init(document.getElementById('student'))
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '学生完成率',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ['0%', '1%~25%', '25%~50%', '50%~75%', '75%~99%', '100%'],
          selected: {
            '0%': true,
            '1%~25%': true,
            '25%~50%': true,
            '50%~75%': true,
            '75%~99%': true,
            '100%': true
          }
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              {
                value: this.E,
                name: '0%'
              },
              {
                value: this.D,
                name: '1%~25%'
              },
              {
                value: this.C,
                name: '25%~50%'
              },
              {
                value: this.B,
                name: '50%~75%'
              },
              {
                value: this.A,
                name: '75%~99%'
              },
              {
                value: this.S,
                name: '100%'
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      student.setOption(option)
      window.addEventListener('resize', function () {
        student.resize()
      })
    },
  }
}
</script>

<style scoped>

</style>
